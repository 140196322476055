import React from 'react'
import styled from 'styled-components'
import IconWarning from '@material-ui/icons/WarningRounded'
import { useModal } from '@pods-finance/contexts'
import { modals } from '../../../constants'

const WrapperPartial = styled.div`
  position: relative;
`

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: auto;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.border};
  color: ${props => props.theme.colors.contentMedium};
  cursor: pointer;

  & > svg {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13pt;
  }

  &:hover,
  &:active {
    color: ${props => props.theme.colors.dark};
  }
`

const Wrapper = styled(WrapperPartial)`
  ${props => props.theme.medias.medium} {
    pointer-events: none;
    position: relative;
    ${Box} {
      background-color: ${props => props.theme.colors.middle};
      position: absolute;
      z-index: 100;
      top: -40px;
      right: -70px;
      transform: scale(0.7);

      & > svg {
        color: ${props => props.theme.colors.white};
      }
      &:hover,
      &:active {
        background-color: ${props => props.theme.colors.middle};
      }
    }
  }
`

function NoticeTerms () {
  const { setOpen } = useModal(modals.risk)

  return (
    <Wrapper>
      <Box onClick={() => setOpen(true)}>
        <IconWarning />
      </Box>
    </Wrapper>
  )
}

export default NoticeTerms
