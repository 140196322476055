import React from 'react'
import styled from 'styled-components'
import { useClassification } from '../../../../hooks'
import Section from '../../../common/Section'
import RestrictedNetwork from '../RestrictedNetwork'

const Content = styled.div`
  width: 100%;
`

function Invest () {
  const { title } = useClassification()
  return (
    <Section
      title={`Sell ${title.long}`}
      description='Sell European, fully collateralized options. Please note that (for certain options series) it may be possible to use aTokens to increase your earnings.'
    >
      <Content>
        <RestrictedNetwork />
      </Content>
    </Section>
  )
}

export default Invest
