import _ from 'lodash'
import BigNumber from 'bignumber.js'
import {
  guards,
  toSignificantInput,
  isBalanceInsignificant
} from '@pods-finance/utils'
import { analytics } from '../../../../../../vendors'

function onInitialize ({
  elements,
  dispatch,
  collateral,
  strike,
  balanceCollateral
}) {
  dispatch([], 'RESET', [elements.allowance])
  dispatch([
    elements.collateral,
    {
      token: _.get(collateral, 'symbol'),
      max: !isBalanceInsignificant(balanceCollateral)
        ? balanceCollateral.toString()
        : null
    }
  ])

  dispatch([
    elements.strike,
    {
      token: _.get(strike, 'symbol')
    }
  ])
}

function onChangeAmount ({
  amount,
  dispatch,
  elements,
  abPrice,
  balanceStrike,
  balanceCollateral
}) {
  dispatch([
    elements.collateral,
    {
      value: String(amount).trim(),
      warning: !_.isNilOrEmptyString(amount)
        ? guards.isAmountValid({
          value: amount,
          max: balanceCollateral
        })
        : null
    }
  ])

  const strike = new BigNumber(amount || 0).times(abPrice)

  dispatch([
    elements.strike,
    {
      value: toSignificantInput(strike || 0, BigNumber.ROUND_UP),
      isLoading: false,
      warning: !_.isNilOrEmptyString(strike)
        ? guards.isAmountValid({
          value: strike,
          max: balanceStrike
        })
        : null
    }
  ])
}

function onTransact ({ machine, state, option, setup, signer }) {
  analytics.track(e => e.transactionAddLiquidityTrigger)
  machine.send(machine.events.save, {
    payload: {
      state,
      option,
      setup,
      signer
    }
  })
}

export default {
  onInitialize,
  onChangeAmount,
  onTransact
}
