import _ from 'lodash'
import React, { useMemo } from 'react'
import numeral from 'numeral'
import dayjs from 'dayjs'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Content = styled.div`
  margin: 0;
  font-size: 11pt;
  color: ${props => props.theme.colors.contentMedium};
  font-weight: 600;
  line-height: 1.8;
  white-space: nowrap;

  b {
    font-weight: 700;
    color: ${props => props.theme.colors.dark};
  }

  span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    min-width: 24px;
    border-radius: 2px;
    background-color: ${props => props.theme.colors.platform};
    font-size: 10pt;
    font-weight: 600;
    color: ${props => props.theme.colors.dark};
  }
`

function Tokens ({ list: unsanitized }) {
  const list = _.toArray(unsanitized)

  if (list.length === 0) return null
  if (list.length === 1) return <b>{_.get(list, '[0]')}</b>
  return (
    <>
      <b>{_.get(list, '[0]')}</b>{' '}
      <span title={list.join(', ')}>+{list.length - 1}</span>
    </>
  )
}

function QueryLabel ({ state }) {
  const label = useMemo(() => {
    if (_.get(state, 'placeholder.isLocked')) {
      return _.get(state, 'placeholder.value')
    }

    let underlying = null
    if (
      !_.isNil(_.get(state, 'underlying.value')) &&
      _.toArray(_.get(state, 'underlying.value')).length
    ) { underlying = <Tokens list={_.toArray(_.get(state, 'underlying.value'))} /> }

    let collateral = null
    if (
      !_.isNil(_.get(state, 'collateral.value')) &&
      _.toArray(_.get(state, 'collateral.value')).length
    ) { collateral = <Tokens list={_.toArray(_.get(state, 'collateral.value'))} /> }

    const strike = _.get(state, 'strike.value')
    const expiration = _.get(state, 'expiration.milliseconds')

    if (
      _.isNil(underlying) &&
      _.isNil(collateral) &&
      (_.isNil(strike) || _.isEmpty(_.toString(strike))) &&
      (_.isNil(expiration) || _.isEmpty(_.toString(expiration)))
    ) {
      return <>{_.get(state, 'placeholder.value')}</>
    }

    const parts = [null, null, null, null, null]
    /** Prefix */
    parts[0] = <>{_.get(state, 'context.value.prefix')}</>
    /** Expiration */

    parts[4] = !_.isNil(expiration) ? (
      <b>{dayjs(expiration).format('MMM DD, YYYY')}</b>
    ) : null
    /** Strike */
    parts[3] = !_.isNilOrEmptyString(strike) ? (
      <b>
        {_.toString(strike).split('').length < 16
          ? numeral(strike).format('0,0.[0000]')
          : strike}
      </b>
    ) : null
    /** Underlying */

    if (!_.isNil(underlying)) parts[1] = <>{underlying}</>
    else if (!_.isNil(collateral)) parts[1] = <b>Any</b>
    else if (!_.isNil(parts[3]) || !_.isNil(parts[4])) parts[1] = <b>Any</b>

    /** Collateral */

    if (!_.isNil(collateral)) parts[2] = collateral
    else if (!_.isNil(underlying)) parts[2] = <b>Any</b>
    else if (!_.isNil(parts[3]) || !_.isNil(parts[4])) parts[2] = <b>Any</b>

    return (
      <>
        {parts[0]}{' '}
        {!_.isNil(parts[1]) && (
          <>
            {parts[1]} <b>:</b>{' '}
          </>
        )}
        {!_.isNil(parts[2]) && parts[2]}
        {!_.isNil(parts[3]) && <> - {parts[3]}</>}
        {!_.isNil(parts[4]) && <> - {parts[4]}</>}
      </>
    )
  }, [state])

  return (
    <Wrapper data-component='label'>
      <Content>{label}</Content>
    </Wrapper>
  )
}

QueryLabel.propTypes = {
  state: PropTypes.shape({})
}

QueryLabel.defaultProps = {
  state: null
}

export default QueryLabel
