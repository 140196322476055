import _ from 'lodash'
import { isBalanceInsignificant } from '@pods-finance/utils'
import { analytics } from '../../../../../../vendors'

function onInitialize ({
  elements,
  dispatch,
  option,
  withdrawBalanceUnderlying,
  withdrawBalanceStrike
}) {
  dispatch([], 'RESET', [elements.allowance])
  dispatch([
    elements.strike,
    {
      token: _.get(option, 'strike.symbol'),
      value: !isBalanceInsignificant(withdrawBalanceStrike)
        ? withdrawBalanceStrike.toString()
        : 0
    }
  ])

  dispatch([
    elements.underlying,
    {
      token: _.get(option, 'underlying.symbol'),
      value: !isBalanceInsignificant(withdrawBalanceUnderlying)
        ? withdrawBalanceUnderlying.toString()
        : 0
    }
  ])
}

function onTransact ({ machine, state, option, setup, signer }) {
  analytics.track(e => e.transactionWithdrawTrigger)
  machine.send(machine.events.save, {
    payload: {
      state,
      option,
      setup,
      signer
    }
  })
}

export default {
  onInitialize,
  onTransact
}
