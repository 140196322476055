import _ from 'lodash'
import { useMemo, useCallback } from 'react'
import { useRouteId } from '@pods-finance/hooks'
import { useDataDynamicContext } from '../contexts/DataDynamic'
import { useWeb3Utilities } from './web3'
import { Action } from '../models'

export function useUserActivity () {
  const { provider, signer } = useWeb3Utilities()
  const { activity } = useDataDynamicContext()

  const { user, trackUserActivity: track } = useMemo(() => activity, [activity])

  const { isLoading, isFinished } = useMemo(() => {
    const { manager } = user
    return manager
  }, [user])

  const actions = useMemo(() => {
    const { store } = user
    if (_.isNil(store) || _.isNil(store.value)) return []
    return store.value.map(item => new Action(item))
  }, [user])

  const count = useMemo(() => _.get(actions, 'length'), [actions])
  const last = useMemo(() => _.get(actions, actions.length - 1), [actions])
  const more = useCallback(() => {
    if (isFinished) return
    const timestamp = _.get(last, 'timestamp')
    track({
      provider,
      signer,
      timestamp
    })
  }, [provider, signer, last, isFinished, track])

  return useMemo(
    () => ({
      isLoading,
      isFinished,
      actions,
      count,
      more
    }),
    [isLoading, isFinished, actions, count, more]
  )
}

export function useOptionActivity (force) {
  const address = useRouteId(force)
  const { activity } = useDataDynamicContext()

  const { options } = useMemo(() => activity, [activity])

  const option = useMemo(() => {
    const item = _.get(options, address)
    if (_.isNil(item) || _.isNilOrEmptyString(address)) return null
    return item
  }, [options, address])

  const isLoading = useMemo(
    () => (option ? _.get(option, 'element.isLoading') : true),
    [option]
  )

  const actions = useMemo(() => {
    if (_.isNil(option)) return []
    const list = _.get(option, 'element.value')
    if (_.isNil(list) || !_.isArray(list)) return []
    return list.map(item => new Action(item))
  }, [option])

  return useMemo(
    () => ({
      actions,
      count: actions.length,
      isLoading
    }),
    [actions, isLoading]
  )
}
