import _ from 'lodash'
import React, { useMemo, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import {
  Helper,
  Step,
  Label,
  Input,
  Container as FormPartial
} from '@pods-finance/components'
import {
  ContentBox,
  ContentSection,
  ContentSummary,
  SupportExtra
} from '../../../Elements'

import { SpecialButton, Pill } from '../../../../../common'

import reducers from '../../../../../../reducers'
import machines from '../../../../../../machines'
import { tabs } from '../../../../../../constants'
import { toNumeralPrice } from '@pods-finance/utils'

import {
  useOwnBalance,
  useOptionInfo,
  useOptionDynamics,
  useOptionBuyingPrice,
  useOptionBuyingPriceEstimate,
  useTransactionSetup,
  useFormValidator,
  useFormAssetAllowance,
  useVersion,
  useNetworkId
} from '../../../../../../hooks'

import logic from './logic'

const Wrapper = styled.div`
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 3 / 2);
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Form = styled(FormPartial)``

function Buy () {
  /**
   * --------------------------------------
   * Required data and utilities
   * --------------------------------------
   */

  const machine = machines.buy.useMachine()
  const setup = useTransactionSetup()
  const dynamics = useOptionDynamics()
  const networkId = useNetworkId()
  const { version } = useVersion()
  const { elements, state, dispatch } = reducers.buy.useReducer()
  const {
    option,
    helper,
    underlying,
    strike,
    durations,
    isLoading: isOptionLoading
  } = useOptionInfo()

  const {
    value: balanceStrike,
    isLoading: isBalanceStrikeLoading
  } = useOwnBalance(strike)

  const [liquidityOptions] = useMemo(
    () => [_.get(dynamics, 'totalBalances.0.humanized')],
    [dynamics]
  )

  const { check: safeComputePremium } = useOptionBuyingPrice()
  const { check: safeComputeUnderlying } = useOptionBuyingPriceEstimate()

  const isLoading = useMemo(
    () => isOptionLoading || isBalanceStrikeLoading || dynamics.isLoading,
    [isOptionLoading, isBalanceStrikeLoading, dynamics.isLoading]
  )

  const isUnderlyingPrimary = useMemo(() => {
    return _.get(state, 'underlying.isPrimary') === true
  }, [state])

  const { isValid: isFormValid, isAllowed: isFormAllowed } = useFormValidator({
    state,
    machine
  })

  /**
   * --------------------------------------
   *  Methods and state updaters
   * --------------------------------------
   */

  const doAllowanceUpdate = useCallback(
    ({ isAllowed, isLoading }) =>
      dispatch([elements.allowance, { strike: isAllowed, isLoading }]),
    [elements, dispatch]
  )

  const onChangeAmount = useCallback(
    (underlying = null, premium = null) =>
      logic.onChangeAmount({
        amounts: {
          underlying,
          premium
        },
        dispatch,
        elements,
        option,
        balanceStrike,
        liquidityOptions,
        safeComputePremium,
        safeComputeUnderlying
      }),
    [
      dispatch,
      elements,
      option,
      balanceStrike,
      liquidityOptions,
      safeComputePremium,
      safeComputeUnderlying
    ]
  )

  const onPrimaryRefocus = useCallback(
    isUnderlyingPrimary =>
      logic.onPrimaryRefocus({
        isUnderlyingPrimary,
        dispatch,
        elements,
        state
      }),
    [dispatch, elements, state]
  )

  const onTransact = useCallback(
    () => logic.onTransact({ machine, state, option, setup }),
    [machine, state, option, setup]
  )

  const {
    doApprove: doAllowanceApprove,
    doRefresh: doAllowanceRefresh
  } = useFormAssetAllowance({
    amount: _.get(state, 'premium.value'),
    token: strike,
    spender: _.get(helper, 'address'),
    onUpdate: doAllowanceUpdate
  })

  useEffect(() => {
    if (!isLoading) {
      logic.onInitialize({
        elements,
        dispatch,
        underlying,
        strike,
        balanceStrike
      })

      doAllowanceRefresh()
    }
  }, [
    elements,
    dispatch,
    isLoading,
    version,
    underlying,
    strike,
    balanceStrike,
    doAllowanceRefresh
  ])

  const action = useMemo(() => {
    if (isLoading) return '...'
    if (option.isPut()) return 'hedge'
    return 'buy on exercise'
  }, [option, isLoading])

  const isOverpayingInFees = useMemo(() => {
    try {
      const _fees = _.get(state, 'fees.value')
      const _premium = _.get(state, 'premium.value')
      if (_.isNilOrEmptyString(_fees) || _.isNaN(_fees)) return false
      if (_.isNilOrEmptyString(_premium) || _.isNaN(_premium)) return false

      const fees = new BigNumber(_fees)
      const premium = new BigNumber(_premium)

      if (fees.isZero() || premium.isZero()) return false

      if (fees.times(2).isGreaterThanOrEqualTo(premium)) return true
    } catch (e) {}
    return false
  }, [state])

  return (
    <ContentBox hash={tabs.hedge.buy} isLoading={isLoading}>
      <Wrapper>
        <ContentSection
          title='Buy options'
          isContained
          isDisabled={[
            machine.states.validate,
            machine.states.process
          ].includes(machine.current.value)}
        >
          <Form>
            <Step>
              <Label>
                Step 1. Amount to {action}
                {!isUnderlyingPrimary && ' (expected)'}
              </Label>
              <Input.Amount
                {...state.underlying}
                placeholder='Enter amount'
                networkId={networkId}
                onChange={e => onChangeAmount(_.get(e, 'target.value'), null)}
                onClick={() => onPrimaryRefocus(true)}
              />
            </Step>
            <Step>
              <Label>
                Step 2. Pay with {isUnderlyingPrimary && ' (expected)'}
              </Label>
              <Input.Amount
                {...state.premium}
                placeholder='0'
                networkId={networkId}
                onChange={e => onChangeAmount(null, _.get(e, 'target.value'))}
                onClick={() => onPrimaryRefocus(false)}
              />
              <SupportExtra>
                <Helper value='The fees will be paid to the liquidity providers, based on the size and price impact of your trade. This amount is included in the premium value (shown in step 2).'>
                  <Pill
                    label='Included LP fees: '
                    content={
                      !_.isNil(_.get(state, 'fees.value')) &&
                      !_.isNaN(_.get(state, 'fees.value'))
                        ? toNumeralPrice(_.get(state, 'fees.value'))
                        : null
                    }
                  />
                </Helper>
                {isOverpayingInFees && (
                  <Helper value='You may lower the required fees by spliting your trade into multiple smaller ones.'>
                    <Pill
                      isWarning
                      label='Warning: over 50% in fees'
                      content=''
                    />
                  </Helper>
                )}
              </SupportExtra>
            </Step>

            <ContentSummary
              index={3}
              context={tabs.hedge.buy}
              data={{
                state,
                option,
                durations
              }}
              allow={
                <SpecialButton.AllowToken
                  amount={_.get(state, 'premium.value')}
                  title={`Allow ${_.get(strike, 'alias')}`}
                  isAllowed={_.get(state, 'allowance.strike')}
                  isLoading={_.get(state, 'allowance.isLoading')}
                  onApprove={doAllowanceApprove}
                />
              }
              transact={
                <SpecialButton.Transact
                  title='Buy Options'
                  isDisabled={!isFormValid}
                  isAllowed={isFormAllowed}
                  isLoading={[
                    machine.states.validate,
                    machine.states.process
                  ].includes(machine.current.value)}
                  onClick={onTransact}
                />
              }
            />
          </Form>
        </ContentSection>
      </Wrapper>
    </ContentBox>
  )
}

export default Buy
