import _ from 'lodash'
import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
  useMemo
} from 'react'

import { useLocation } from 'react-router-dom'
import { useLocalStorage } from '@pods-finance/hooks'
import { findPageByRoute } from '@pods-finance/utils'

import { prefs, pages } from '../../constants'

export const UIContext = createContext(null)

export default function Provider ({ children }) {
  const controls = useControls()
  const preferences = usePreferences()
  const versioning = useVersioning()

  return (
    <UIContext.Provider value={{ controls, preferences, versioning }}>
      {children}
    </UIContext.Provider>
  )
}

export function useUIContext () {
  return useContext(UIContext)
}

function useControls () {
  const location = useLocation()

  const [drawer, setDrawer] = useState(false)
  const [entry, setEntry] = useState(undefined)
  const openDrawer = useCallback(() => setDrawer(true), [setDrawer])
  const closeDrawer = useCallback(() => setDrawer(false), [setDrawer])
  const toggleDrawer = useCallback(prev => setDrawer(!prev), [setDrawer])

  useEffect(() => {
    setEntry(prev => {
      const cleanPrev = _.get(prev, 'pathname')
        ? _.toString(_.get(prev, 'pathname')).split('#')[0]
        : null
      const cleanCurrent = _.get(location, 'pathname')
        ? _.toString(_.get(location, 'pathname')).split('#')[0]
        : null

      /** If you run into "tabs" don't push the same page again */
      if (cleanPrev === cleanCurrent) return prev

      /** If current page is not depth-0, don't track it as a back solution */
      if (!findPageByRoute(cleanCurrent, pages)) return prev

      return location
    })
  }, [setEntry, location])

  return {
    drawer,
    openDrawer,
    closeDrawer,
    toggleDrawer,

    entry
  }
}

const initial = {
  [prefs.isTermsOfServiceAccepted]: false,
  [prefs.isDarkTheme]: false,
  [prefs.isAnalyticsEnabled]: false,
  [prefs.isAdvancedModeEnabled]: false,
  [prefs.isClassificationCall]: false
}

function usePreferences () {
  const [storedPreferences, setStoredPreferences] = useLocalStorage(
    'preferences',
    initial
  )

  const updatePreferences = useCallback(
    (key, value = null) => {
      const result = _.cloneDeep(storedPreferences)

      if (_.isString(key) && _.has(result, key)) {
        result[key] = value
      }

      if (_.isObject(key)) {
        Object.keys(key).forEach(innerKey => {
          const innerValue = _.get(key, innerKey)
          if (_.has(result, innerKey)) result[innerKey] = innerValue
        })
      }

      setStoredPreferences(result)
    },
    [storedPreferences, setStoredPreferences]
  )

  return {
    value: storedPreferences,
    update: updatePreferences
  }
}

function useVersioning () {
  const [version, setVersion] = useState(0)
  const update = useCallback(() => setVersion(prev => prev + 1), [])

  return useMemo(
    () => ({
      version,
      update
    }),
    [version, update]
  )
}
