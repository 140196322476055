import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import globals from '@pods-finance/globals'
import {
  Modal as Base,
  Button,
  ExternalLink,
  Switch
} from '@pods-finance/components'
import { useToasts } from 'react-toast-notifications'
import { useModal } from '@pods-finance/contexts'

import { modals, prefs } from '../../../../constants'
import { usePreferences } from '../../../../hooks'
import * as vendors from '../../../../vendors'
import { ReactComponent as AssetLogo } from '../../../../assets/logo/logo_gradient.svg'

const Modal = styled(Base)`
  & > div[data-component='card'] {
    max-width: 500px;
    border-radius: ${props => props.theme.sizes.edge};
  }
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: calc(${props => props.theme.sizes.edge} * 1);
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1);
  padding-top: calc(${props => props.theme.sizes.edge} * 2);
  padding-bottom: calc(${props => props.theme.sizes.edge} * 1);

  border-top: 1px solid ${props => props.theme.colors.grayBlueLight};
  border-bottom: 1px solid ${props => props.theme.colors.grayBlueLight};
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1);
  &:last-child {
    margin-bottom: 0;
  }
`

const Title = styled.div`
  width: 100%;
  text-align: left;
  & > p {
    color: ${props => props.theme.colors.dark};
    font-size: 13pt;
    font-weight: 600;
    line-height: 1.5;
    margin: 0 0 10px 0;
  }
`

const Description = styled.div`
  width: 100%;
`

const Paragraph = styled.p`
  color: ${props => props.theme.colors.dark};
  font-size: 11pt;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  text-align: left;

  a {
    color: ${props => props.theme.colors.middle};
    font-weight: 600;
  }
`

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  & > svg {
    height: 48px;
    width: auto;
  }
`

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1);
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 1 / 2);
  padding-bottom: calc(${props => props.theme.sizes.edge} * 1);

  ${props => props.theme.medias.medium} {
    padding-bottom: calc(${props => props.theme.sizes.edge} * 5);
  }
`

function TermsOfService () {
  const id = modals.termsOfService
  const { setOpen } = useModal(id)
  const { addToast, removeAllToasts } = useToasts()
  const [analyticsAllowance, setAnalyticsAllowance] = useState(true)
  const [jurisdictionAllowance, setJurisdictionAllowance] = useState(false)

  const { update: updatePreferences } = usePreferences()

  const accept = useCallback(() => {
    try {
      removeAllToasts()
      if (!jurisdictionAllowance) {
        addToast(
          'Please formally agree to not being a US resident before joining the platform.',
          {
            appearance: 'warning',
            autoDismiss: true,
            autoDismissTimeout: 6000
          }
        )
        document.getElementById('jurisdictionAllowance').focus()
        return
      }

      updatePreferences({
        [prefs.isTermsOfServiceAccepted]: true,
        [prefs.isAnalyticsEnabled]: analyticsAllowance
      })
      setOpen(false)
      vendors.initialize()
    } catch (e) {
      console.error(e)
    }
  }, [
    analyticsAllowance,
    updatePreferences,
    jurisdictionAllowance,
    addToast,
    removeAllToasts,
    setOpen
  ])

  return (
    <Modal id={id} title='' isCloseHidden isBackdropDisabled>
      <Content>
        <Logo>
          <AssetLogo />
        </Logo>
        <Info>
          <Section>
            <Title>
              <p>Welcome to our demo release</p>
            </Title>
            <Description>
              <Paragraph>
                Thank you for trying out Pods. Here you'll be able to trade
                options and provide liquidity up to a publicly announced TVL
                cap. We've chosen to cap this initial launch in order to provide
                better and safer measurements for our next option series.{' '}
                <b>
                  Please be advised that the current live version of the
                  contracts isn't fully audited.
                </b>
              </Paragraph>
            </Description>
          </Section>
          <Section>
            <Title>
              <p>Before you join</p>
            </Title>
            <Description>
              <Paragraph>
                By moving forward with your on-boarding, you accept our{' '}
                <ExternalLink href={globals.links.terms.primary.link}>
                  Terms of Service
                </ExternalLink>{' '}
                as well as confirm that you understand the possible{' '}
                <ExternalLink href={globals.links.terms.risks.link}>
                  risks
                </ExternalLink>{' '}
                You hereby agree to comply with the local laws and regulations
                of your jurisdiction. You acknowledge that you are fully
                responsible for the safety of your capital.
                <br />
              </Paragraph>
            </Description>
          </Section>
          <Section>
            <SwitchWrapper>
              <Switch.Box>
                <Switch.Input
                  checked={analyticsAllowance}
                  value={analyticsAllowance}
                  onChange={() => {
                    setAnalyticsAllowance(!analyticsAllowance)
                  }}
                  type='checkbox'
                  name='analyticsAllowance'
                  id='analyticsAllowance'
                />
                <Switch.Label htmlFor='analyticsAllowance'>
                  (Optional) You agree to share anonymous{' '}
                  <ExternalLink href={globals.links.terms.analytics.link}>
                    analytics
                  </ExternalLink>{' '}
                  and crashalytics
                </Switch.Label>
              </Switch.Box>
            </SwitchWrapper>
            <SwitchWrapper>
              <Switch.Box>
                <Switch.Input
                  checked={jurisdictionAllowance}
                  value={jurisdictionAllowance}
                  onChange={() => {
                    setJurisdictionAllowance(prev => !prev)
                  }}
                  type='checkbox'
                  name='jurisdictionAllowance'
                  id='jurisdictionAllowance'
                />
                <Switch.Label htmlFor='jurisdictionAllowance'>
                  (Mandatory) You are not a person or company who is a resident
                  of, or is located, incorporated or has a registered agent in,
                  the United States.
                </Switch.Label>
              </Switch.Box>
            </SwitchWrapper>
          </Section>
        </Info>
        <Actions>
          <Button
            title='Accept &amp; Join'
            appearance={a => a.gradient}
            accent={a => a.primary}
            type={t => t.button}
            onClick={accept}
            isFullWidth
          />
        </Actions>
      </Content>
    </Modal>
  )
}

export default TermsOfService
