import _ from 'lodash'
import { useCallback } from 'react'
import BigNumber from 'bignumber.js'
import { useToasts } from 'react-toast-notifications'
import { useAtomicMachine } from '@pods-finance/hooks'
import { guards, toQuantity } from '@pods-finance/utils'

import { useModal } from '@pods-finance/contexts'
import { modals, words } from '../../constants'

const hasZeroValues = state => {
  const t = new BigNumber(_.get(state, 'strike.value'))
  const o = new BigNumber(_.get(state, 'collateral.value'))

  return t.isZero() || t.isNaN() || o.isZero() || o.isNaN()
}

export function useMachine () {
  const { addToast, removeAllToasts } = useToasts()
  const { setOpen, updateData } = useModal(modals.transaction)

  const onPrepare = useCallback(async ({ context }) => {
    const state = _.get(context, 'payload.state')

    const form = await guards.booleanize(() =>
      guards.isFormValid({ value: state, soft: true })
    )

    if (!form) return false

    if (hasZeroValues(state)) return false

    return true
  }, [])

  const onValidate = useCallback(
    async ({ context }) => {
      const state = _.get(context, 'payload.state')

      const form = await guards.interpret(
        () =>
          guards.isFormValid({
            value: state
          }),
        addToast
      )

      if (form[0] === false) throw new Error(form[1])

      const strike = await guards.interpret(
        () =>
          guards.isAmountValid({
            value: _.get(state, 'strike.value'),
            max: _.get(state, 'strike.max'),
            min: null
          }),
        addToast
      )

      if (strike[0] === false) throw new Error(strike[1])

      const collateral = await guards.interpret(
        () =>
          guards.isAmountValid({
            value: _.get(state, 'collateral.value'),
            max: _.get(state, 'collateral.max'),
            min: null
          }),
        addToast
      )

      if (collateral[0] === false) throw new Error(collateral[1])
    },
    [addToast]
  )

  const onProcess = useCallback(
    async ({ context }) => {
      const payload = _.get(context, 'payload') || {}
      const { option, setup, state } = payload

      /** For calls, the collateral amount matches the number of generated options */
      const amountTokenA = new BigNumber(_.get(state, 'collateral.value'))
      const amountTokenB = new BigNumber(_.get(state, 'strike.value'))

      const optionLabel = toQuantity(amountTokenA.toString(), 'option')

      const modalLabel = `${amountTokenA} ${_.get(
        option,
        'underlying.symbol'
      )}  for ${optionLabel} and ${amountTokenB} ${_.get(
        option,
        'strike.symbol'
      )}`

      try {
        setOpen(true, {
          state: 'loading',
          tx: null,
          info: `Preparing to add liquidity with ${modalLabel}. Checking allowances...`
        })

        const allowanceTokenA = await setup.getTokenAllowance({
          ...setup,
          token: option.collateral,
          amount: _.get(state, 'collateral.value')
        })

        const allowanceTokenB = await setup.getTokenAllowance({
          ...setup,
          token: option.strike,
          amount: _.get(state, 'strike.value')
        })

        if (
          _.isNil(allowanceTokenA) ||
          _.get(allowanceTokenA, 'isAllowed') === false ||
          _.isNil(allowanceTokenB) ||
          _.get(allowanceTokenB, 'isAllowed') === false
        ) {
          updateData({
            state: 'warning',
            info: words.warningTransactionAllowance()
          })
          return
        }

        updateData({
          info: `Adding liquidity with ${modalLabel}.`
        })

        let rejected = null
        const { helper } = setup

        await helper.doMintAndAddLiquidityWithCollateralAndStrike({
          option: option.fromSDK(),
          tokenAAmount: amountTokenA,
          tokenBAmount: amountTokenB,
          overrides: {
            gasLimit: true
          },
          callback: (error, transactionHash) => {
            if (error) rejected = error
            updateData({
              tx: transactionHash
            })
          }
        })

        if (!_.isNil(rejected)) throw rejected

        removeAllToasts()

        const success = `Liquidity (${modalLabel}) successfully added!`

        updateData({
          state: 'success',
          info: success
        })

        addToast(success, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
      } catch (e) {
        console.error(e)
        removeAllToasts()

        const reason =
          _.get(e, 'code') === 4001
            ? words.reasonTransactionRejected()
            : words.reasonTransactionFailed()

        updateData({
          title: reason,
          state: 'error',
          info: words.errorTransactionFailed(
            `Attempted to add liquidity with ${modalLabel}.`
          )
        })

        addToast(reason, {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
        setup.update()
        throw e
      }
      setup.update()
    },
    [addToast, removeAllToasts, setOpen, updateData]
  )

  const machine = useAtomicMachine({
    id: 'addCall',
    onPrepare,
    onValidate,
    onProcess
  })

  return machine
}

export default {
  useMachine
}
