import _ from 'lodash'
import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { meta } from '@pods-finance/globals'
import { tabs } from '../../../constants'
import { capitalize, toNumeralPrice } from '@pods-finance/utils'

function Dashboard () {
  return (
    <Helmet>
      <title>{`${meta.general.title} - ${meta.general.motto}`}</title>
    </Helmet>
  )
}

function Invest () {
  return (
    <Helmet>
      <title>{`${meta.general.title} - Sell Options - ${meta.general.motto}`}</title>
    </Helmet>
  )
}

function Hedge () {
  return (
    <Helmet>
      <title>{`${meta.general.title} - Buy Options - ${meta.general.motto}`}</title>
    </Helmet>
  )
}

function Activity () {
  return (
    <Helmet>
      <title>{`${meta.general.title} - Activity - ${meta.general.motto}`}</title>
    </Helmet>
  )
}

function Pool () {
  return (
    <Helmet>
      <title>{`${meta.general.title} - Pools - ${meta.general.motto}`}</title>
    </Helmet>
  )
}

function Utils () {
  return (
    <Helmet>
      <title>{`${meta.general.title} - Be a Testnet 🐳 Whale - ${meta.general.motto}`}</title>
    </Helmet>
  )
}
function Restricted () {
  return (
    <Helmet>
      <title>{`${meta.general.title} - Terms of Service restrictions`}</title>
    </Helmet>
  )
}

function Transaction ({ data, tabs, fallback = 'Sell Options' }) {
  const location = useLocation()

  const title = useMemo(() => {
    const { option, durations } = data

    if (_.isNil(option) || option.isLoading || option.warning) return fallback

    const { underlying, strike } = option

    if (!_.isNil(underlying) && !_.isNil(strike)) {
      const area =
        _.get(
          tabs.find(tab => location.hash === `#${tab.hash}`),
          'title'
        ) || fallback
      const pair = `${_.get(underlying, 'alias')}:${_.get(strike, 'alias')}`
      const special = durations.isExercisableSoon
        ? '⏱'
        : durations.isExercising
          ? '📅'
          : ''
      const price = toNumeralPrice(option.strikePrice.humanized)

      return [area, pair, special, price, durations.expirationFormatted]
        .filter(element => !_.isNilOrEmptyString(element))
        .join(' - ')
    }

    return `${fallback} - ${meta.general.title}`
  }, [data, location, fallback, tabs])

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  )
}

function TransactionInvest ({ data }) {
  return (
    <Transaction
      data={data}
      tabs={Object.values(tabs.invest).map(hash => ({
        hash,
        title: capitalize(hash)
      }))}
      fallback='Sell Options'
    />
  )
}

function TransactionHedge ({ data }) {
  return (
    <Transaction
      data={data}
      tabs={Object.values(tabs.hedge).map(hash => ({
        hash,
        title: capitalize(hash)
      }))}
      fallback='Buy Options'
    />
  )
}

function TransactionPool ({ data }) {
  return (
    <Transaction
      data={data}
      tabs={Object.values(tabs.pool).map(hash => ({
        hash,
        title: `${capitalize(hash)}${
          [tabs.pool.add, tabs.pool.remove].includes(hash) ? ' Liquidity' : ''
        }`
      }))}
      fallback='Manage Liquidity'
    />
  )
}

export default {
  Dashboard,
  Invest,
  Hedge,
  Activity,
  Pool,
  Utils,
  Restricted,

  TransactionInvest,
  TransactionHedge,
  TransactionPool
}
