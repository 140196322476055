import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import IconMenu from '@material-ui/icons/MenuRounded'
import { BoxAction } from '@pods-finance/components'

import Search from '../../Search'
import Account from '../../Account'
import Classification from '../../Classification'
import NoticeTerms from '../../NoticeTerms'
import NoticeLag from '../../NoticeLag'
import { useUI } from '../../../../hooks'

import AssetLogo from '../../../../assets/logo/logo_gradient.svg'

const WrapperPartial = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: calc(${props => props.theme.sizes.layoutEdge});
  position: relative;
`

const Content = styled.div`
  margin-left: auto;
`

const Logo = styled.img`
  height: 40px;
  width: auto;
  object-fit: contain;
`

const DrawerTools = styled.div`
  display: none;
  align-items: center;
  justify-content: flex-start;
`

const Wrapper = styled(WrapperPartial)`
  ${props => props.theme.medias.medium} {
    width: 100%;
    padding: 0 calc(${props => props.theme.sizes.layoutEdgeMedium});
    height: ${props => props.theme.sizes.navHeight};
    background-color: ${props => props.theme.colors.white};
    border-bottom: 1px solid ${props => props.theme.colors.border};

    & > ${Content} {
      display: none;
    }
    & > ${DrawerTools} {
      display: flex;
      margin-right: auto;
    }
  }

  ${props => props.theme.medias.minMedium} {
    & > * {
      margin-right: calc(${props => props.theme.sizes.edge} * 1 / 2);
      &:last-child {
        margin-right: 0;
      }
    }
  }
`

function ToolbarBase ({ className, children }) {
  const { openDrawer } = useUI()

  return (
    <Wrapper className={className}>
      <DrawerTools>
        <BoxAction Icon={IconMenu} onClick={openDrawer} />
        <Logo src={AssetLogo} />
      </DrawerTools>
      <Search />
      <Content>{children}</Content>
      <NoticeLag />
      <NoticeTerms />
      <Classification />
      <Account />
    </Wrapper>
  )
}

ToolbarBase.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onDrawerOpen: PropTypes.func
}

ToolbarBase.defaultProps = {
  className: null,
  children: null,
  onDrawerOpen: () => {}
}

export default ToolbarBase
