import _ from 'lodash'
import BigNumber from 'bignumber.js'
import {
  guards,
  toSignificantInput,
  isBalanceInsignificant
} from '@pods-finance/utils'
import { analytics } from '../../../../../../vendors'

function onInitialize ({
  elements,
  dispatch,

  underlying,
  strike,
  balanceStrike
}) {
  dispatch([], 'RESET', [elements.allowance])
  dispatch([elements.underlying, { token: _.get(underlying, 'symbol') }])
  dispatch([elements.exact, { value: 'tokenA' }])
  dispatch([
    elements.premium,
    {
      token: _.get(strike, 'symbol'),
      max: !isBalanceInsignificant(balanceStrike)
        ? balanceStrike.toString()
        : null
    }
  ])
}

function onPrimaryRefocus ({ isUnderlyingPrimary, dispatch, elements, state }) {
  if (
    isUnderlyingPrimary === _.get(state, `${elements.underlying}.isPrimary`)
  ) {
    return
  }

  dispatch([
    elements.underlying,
    {
      isPrimary: isUnderlyingPrimary
    }
  ])
  dispatch([
    elements.premium,
    {
      isPrimary: !isUnderlyingPrimary
    }
  ])
}

const onPremiumRecompute = _.debounce(
  ({
    anchor,
    elements,
    dispatch,
    option,
    balanceStrike,
    safeComputePremium
  }) => {
    ;(async () => {
      dispatch([elements.premium, { value: null, isLoading: true }])
      if (anchor === null) return

      const current = anchor // Forcing checks for requests filled out-of-order

      const computed = await safeComputePremium({
        option,
        amount: new BigNumber(anchor),
        external: true
      })

      const { fees, value: _value } = computed || {}
      const value = _value ? _value.toString() : null

      if (current !== anchor) return

      dispatch([
        elements.premium,
        {
          value: toSignificantInput(value || 0, BigNumber.ROUND_UP),
          isLoading: false,
          warning: !_.isNilOrEmptyString(value)
            ? guards.isPremiumValid({
              value,
              max: balanceStrike,
              context: 'liquidity'
            })
            : null
        }
      ])
      dispatch([elements.exact, { value: 'tokenA' }])
      dispatch([elements.fees, { value: fees }])
    })()
  },
  300
)

const onUnderlyingRecompute = _.debounce(
  ({
    anchor,
    elements,
    dispatch,
    option,
    liquidityOptions,
    safeComputeUnderlying
  }) => {
    ;(async () => {
      dispatch([elements.underlying, { value: null, isLoading: true }])
      if (anchor === null) return
      const current = anchor // Forcing checks for requests filled out-of-order

      const computed = await safeComputeUnderlying({
        option,
        amount: new BigNumber(anchor),
        external: true
      })

      const { fees, value: _value } = computed || {}
      const value = _value ? _value.toString() : null

      if (current !== anchor) return

      dispatch([
        elements.underlying,
        {
          value: toSignificantInput(value || 0, BigNumber.ROUND_DOWN),
          isLoading: false,
          warning: !_.isNilOrEmptyString(value)
            ? guards.isAmountValid({
              value,
              max: liquidityOptions,
              context: 'liquidity'
            })
            : null
        }
      ])
      dispatch([elements.exact, { value: 'tokenB' }])
      dispatch([elements.fees, { value: fees }])
    })()
  },
  300
)

function onChangeAmount ({
  amounts,
  dispatch,
  elements,
  option,
  balanceStrike,
  liquidityOptions,
  safeComputePremium,
  safeComputeUnderlying
}) {
  const { underlying, premium } = amounts

  if (!_.isNil(underlying)) {
    dispatch([
      elements.underlying,
      {
        value: String(underlying).trim(),
        warning: !_.isNilOrEmptyString(underlying)
          ? guards.isAmountValid({
            value: underlying,
            max: liquidityOptions,
            context: 'liquidity'
          })
          : null
      }
    ])

    onPremiumRecompute({
      anchor: underlying,
      elements,
      dispatch,
      option,
      balanceStrike,
      safeComputePremium
    })
  } else if (!_.isNil(premium)) {
    dispatch([
      elements.premium,
      {
        value: String(premium).trim(),
        warning: !_.isNilOrEmptyString(premium)
          ? guards.isAmountValid({
            value: premium,
            max: balanceStrike
          })
          : null
      }
    ])
    onUnderlyingRecompute({
      anchor: premium,
      elements,
      dispatch,
      option,
      liquidityOptions,
      safeComputeUnderlying
    })
  }
}

function onTransact ({ machine, state, option, setup, signer }) {
  analytics.track(e => e.transactionBuyTrigger)
  machine.send(machine.events.save, {
    payload: {
      state,
      option,
      setup,
      signer
    }
  })
}

export default {
  onInitialize,
  onPrimaryRefocus,
  onChangeAmount,
  onTransact
}
