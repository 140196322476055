import _ from 'lodash'
import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { useClipboard } from 'use-clipboard-copy'
import { rgba } from 'polished'
import { ExternalLink } from '@pods-finance/components'
import globals from '@pods-finance/globals'
import { useModal } from '@pods-finance/contexts'
import { useToasts } from 'react-toast-notifications'
import IconArrow from '@material-ui/icons/ArrowForwardRounded'
import IconClipboard from '@material-ui/icons/AttachmentRounded'
import IconWarn from '@material-ui/icons/ErrorOutlineRounded'
import IconSuccess from '@material-ui/icons/CheckRounded'
import IconRefresh from '@material-ui/icons/RefreshRounded'
import IconDarkOn from '@material-ui/icons/NightsStayRounded'
import IconDarkOff from '@material-ui/icons/NightsStayOutlined'
import {
  useWalletModal,
  useAccount,
  useDarkMode,
  useClassification
} from '../../../../hooks'
import { beautifyAddress } from '@pods-finance/utils'
import { analytics } from '../../../../vendors'
import { features, networks, modals } from '../../../../constants'

const Wrapper = styled.div`
  top: 64px;
  right: 0;
  position: absolute;
  z-index: 1100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 100%;
  background: ${props => props.theme.colors.white};
  box-shadow: 0 12px 48px -15px rgba(0, 0, 0, 0.3);
  pointer-events: none;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translateY(-20px);
  transition: opacity 100ms, transform 100ms;
  border-radius: 6px;
  border: ${props =>
    props.theme.isDark ? `1px solid ${props.theme.colors.border}` : 'none'};

  &[data-active='true'] {
    pointer-events: all;
    opacity: 1;
    transform: translateY(0);
    transition: opacity 100ms, transform 100ms;
  }

  ${props =>
    props.theme.isDark &&
    css`
      box-shadow: 0 10px 25px -10px rgba(255, 255, 255, 0.15);
    `}
`

const ItemPartial = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: ${props => props.theme.sizes.edge};
  border-bottom: 1px solid ${props => props.theme.colors.border};
`

const ItemContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
  & > p {
    font-size: ${props => props.theme.sizes.text};
    font-weight: 600;
    color: ${props => props.theme.colors.dark};
    margin: 0;
  }
  & > span {
    margin-top: 4px;
    font-size: 10pt;
    font-weight: 600;
    color: ${props => props.theme.colors.contentMedium};
    white-space: nowrap;
  }
`

const ItemCustomNetworks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${props => props.theme.sizes.edge};
  & > * {
    margin-right: 6px;
    &:last-child {
      margin: 0;
    }
  }
`

const ItemCustomNetwork = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid ${props => rgba(props.theme.colors.middle, 0.1)};
  background-color: ${props => rgba(props.theme.colors.middle, 0.1)};
  opacity: 0.5;

  & > p {
    margin: 0;
    font-size: 10pt;
    font-weight: 600;
    color: ${props => rgba(props.theme.colors.middle, 0.9)};
  }
  &[data-active='true'] {
    opacity: 1;
    border: 1px solid ${props => rgba(props.theme.colors.middle, 0.9)};
  }
`

const ItemAction = styled(ExternalLink)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 4px 8px 8px;
  background-color: ${props => props.theme.colors.platform};
  margin-left: calc(${props => props.theme.sizes.edge} * 2);
  border-radius: 4px;
  cursor: pointer !important;
  & > p {
    margin: 0;
    font-size: 10pt;
    font-weight: 600;
    color: ${props => props.theme.colors.dark};
  }
  & > svg {
    margin-left: 4px;
    font-size: 12pt;
    color: ${props => props.theme.colors.dark};
  }
  &:hover,
  &:active {
    background-color: ${props => props.theme.colors.border};
  }
`

const Item = styled(ItemPartial)`
  &[data-type='warn'] {
    ${ItemAction} {
      & > p,
      & > svg {
        color: ${props => rgba(props.theme.colors.middle, 0.9)};
      }
    }
  }
`

function Dropdown ({ isActive }) {
  const clipboard = useClipboard({ copiedTimeout: 1000 })
  const dark = useDarkMode()
  const classification = useClassification()
  const { addToast, removeAllToasts } = useToasts()
  const { disconnect } = useWalletModal()
  const { isConnected, address, ens, networkId } = useAccount()
  const { setOpen: setModalRiskOpen } = useModal(modals.risk)

  const name = useMemo(() => _.get(ens, 'value'), [ens])
  const welcome = useMemo(() => (name ? `Hi, ${name}` : 'Welcome!'), [name])

  const onThemeChange = useCallback(() => {
    removeAllToasts()
    addToast(
      dark.isDark
        ? 'Going to the light side ... ☀️'
        : 'Going to the dark side ... 🌒',
      {
        appearance: 'warning',
        autoDismiss: true,
        autoDismissTimeout: 3000
      }
    )
    ;(async () => {
      setTimeout(() => dark.toggle(!dark.isDark), 1000)
    })()
  }, [addToast, removeAllToasts, dark])

  return (
    <Wrapper data-active={isActive} data-component='dropdown'>
      <Item data-type='warn'>
        <ItemContent>
          <p>Risk acknowledgement</p>
          <span>About the current version</span>
        </ItemContent>
        <ItemAction as='div' onClick={() => setModalRiskOpen(true)}>
          <p>Read</p>
          <IconWarn />
        </ItemAction>
      </Item>
      {isConnected && (
        <Item>
          <ItemContent>
            <p>{welcome}</p>
            <span>{beautifyAddress(address, 8, 4)}</span>
          </ItemContent>
          {clipboard.copied ? (
            <ItemAction as='div' onClick={() => {}}>
              <p>Copied</p>
              <IconSuccess />
            </ItemAction>
          ) : (
            <ItemAction
              as='div'
              onClick={() => {
                analytics.track(e => e.accountCopyAddress)
                clipboard.copy(address)
              }}
            >
              <p>Address</p>
              <IconClipboard />
            </ItemAction>
          )}
        </Item>
      )}

      <Item>
        <ItemContent>
          <p>UI Theme</p>
          <span>Manage dark mode</span>
        </ItemContent>
        <ItemAction as='div' onClick={onThemeChange}>
          <p>{dark.isDark ? 'Disable' : 'Enable'}</p>
          {dark.isDark ? <IconDarkOn /> : <IconDarkOff />}
        </ItemAction>
      </Item>

      {features.calls && (
        <Item>
          <ItemContent>
            <p>Seeing {classification.title.short}s</p>
            <span>Toggle the default type</span>
          </ItemContent>
          <ItemAction
            as='div'
            onClick={() => classification.toggle(!classification.isCall)}
          >
            <p>{classification.isPut ? 'Try Calls' : 'Try Puts'}</p>
            <IconRefresh />
          </ItemAction>
        </Item>
      )}

      <Item>
        <ItemContent>
          <p>Learn about Pods</p>
          <span>Find out more at pods.finance</span>
        </ItemContent>
        <ItemAction
          to={globals.host.landing.auto()}
          onClick={() => analytics.track(e => e.accountLearnMore)}
        >
          <p>Visit</p>
          <IconArrow />
        </ItemAction>
      </Item>
      {isConnected && (
        <Item>
          <ItemContent>
            <p>Log Out</p>
            <span>Disconnect your wallet</span>
          </ItemContent>
          <ItemAction
            as='div'
            onClick={() => {
              analytics.track(e => e.accountLogOut)
              disconnect()
            }}
          >
            <p>Log Out</p>
            <IconArrow />
          </ItemAction>
        </Item>
      )}
      {isConnected && (
        <Item>
          <ItemContent>
            <p>
              {[networks.kovan].includes(networkId) ? 'Using' : 'Use'} on a
              testnet
            </p>
            <span>Test drive the app</span>
          </ItemContent>
          <ItemCustomNetworks>
            <ItemCustomNetwork
              title='Switch from your wallet'
              data-active={networkId === networks.kovan}
            >
              <p>Kovan</p>
            </ItemCustomNetwork>
          </ItemCustomNetworks>
        </Item>
      )}
    </Wrapper>
  )
}

Dropdown.propTypes = {
  isActive: PropTypes.bool
}

Dropdown.defaultProps = {
  isActive: false
}

export default Dropdown
