import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Helper } from '@pods-finance/components'
import IconHelp from '@material-ui/icons/HelpOutlineRounded'

const WrapperPartial = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
`

const Value = styled.div`
  margin-bottom: 4px;
  & > p {
    font-size: 30pt;
    font-weight: 700;
    color: ${props => props.theme.colors.dark};
    margin: 0;
    text-align: right;
  }
`

const Explainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & > p {
    font-size: 10pt;
    font-weight: 600;
    color: ${props => props.theme.colors.dark};
    margin: 0;
    text-align: right;
  }

  & > svg {
    margin-left: 4px;
    font-size: 12pt;
    color: ${props => props.theme.colors.dark};
  }
`

const Wrapper = styled(WrapperPartial)`
  &[data-simulated="true"]{
      ${Value} > p, ${Explainer} > p,  ${Explainer} > svg{
        color: ${props => props.theme.colors.content};
      }
  }

  *[data-purpose='helper-wrapper'] {
    display: flex;
    align-items: center;
  }
  *[data-purpose='helper-box'] {
    width: 120px !important;
    top: 20px;
    left: auto;
    right: 0;
  }


  ${props => props.theme.medias.medium} {
    ${Value}{
      & > p{
        font-size: 14pt;
      }
    }
  }

`
const Loader = styled.div`
  height: 66px;
  width: 100%;
`

function Total ({ label, value, help, isSimulated }) {
  return (
    <Wrapper data-simulated={isSimulated}>
      <Value>
        <p>{value}</p>
      </Value>
      <Helper value={help}>
        <Explainer>
          <p>{label}</p>
          <IconHelp />
        </Explainer>
      </Helper>
    </Wrapper>
  )
}

function TotalLoader () {
  return (
    <Wrapper>
      <Loader />
    </Wrapper>
  )
}

Total.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  help: PropTypes.string,
  isSimulated: PropTypes.bool
}

Total.defaultProps = {
  label: '',
  value: '',
  help: '',
  isSimulated: false
}

export { TotalLoader }
export default Total
