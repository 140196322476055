import React, { useEffect } from 'react'
import Helmet from '../../components/specific/Helmet'
import { useModal } from '@pods-finance/contexts'
import { Structure } from '../../components/common'
import { modals } from '../../constants'

import * as Modals from '../../components/specific/Modals'

function Restricted () {
  const { setOpen } = useModal(modals.restrictedService)

  useEffect(() => {
    setOpen(true)
  }, [setOpen])

  return (
    <Structure>
      <Helmet.Restricted />
      <Modals.RestrictedService />
    </Structure>
  )
}

export default Restricted
