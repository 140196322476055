import React from 'react'
import styled from 'styled-components'
import Helmet from '../../components/specific/Helmet'
import Restricted from '../../components/specific/Restricted'
import Sections from '../../components/specific/Pool'
import { Structure, ToolbarBase } from '../../components/common'
import { useSupportedNetwork } from '../../hooks'

const Canvas = styled.div`
  width: 100%;
  padding-bottom: calc(${props => props.theme.sizes.edge} * 2);
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 4);
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${props => props.theme.medias.medium} {
    & > * {
      margin-bottom: calc(${props => props.theme.sizes.edge} * 2);
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

function Pool () {
  const supported = useSupportedNetwork()
  return (
    <Structure>
      <Helmet.Pool />
      <ToolbarBase />
      <Canvas>
        {supported ? (
          <>
            <Sections.Overview />
            <Sections.Pools />
          </>
        ) : (
          <Restricted.Pool />
        )}
      </Canvas>
    </Structure>
  )
}

export default Pool
