import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import IconClose from '@material-ui/icons/CloseRounded'
import * as Items from './Items'

import { useOnClickOutside } from '@pods-finance/hooks'

const WrapperPartial = styled.div`
  top: 64px;
  left: 0;
  position: absolute;
  z-index: 300;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 780px;
  max-height: 410px;
  background: ${props => props.theme.colors.white};
  box-shadow: 0 12px 56px -15px rgba(0, 0, 0, 0.3);
  pointer-events: none;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 100ms, transform 100ms;
  border-radius: ${props => props.theme.sizes.edge};

  &[data-active='true'] {
    pointer-events: all;
    opacity: 1;
    transform: translateY(0);
    transition: opacity 100ms, transform 100ms;
  }
`

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: ${props => props.theme.sizes.edge};
  grid-gap: ${props => props.theme.sizes.edge};
`

const Header = styled.div`
  display: none;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-shrink: 0;
  height: ${props => props.theme.sizes.navHeight};
  padding-left: calc(${props => props.theme.sizes.edge} * 3 / 2);
  padding-right: calc(${props => props.theme.sizes.edge} * 1 / 2);
  margin-bottom: calc(${props => props.theme.sizes.edge} * 0);
  border-bottom: 1px solid ${props => props.theme.colors.border};
`

const Title = styled.p`
  margin: 0 auto 0 0;
  font-size: 20pt;
  font-weight: 700;
  color: ${props => props.theme.colors.dark};
`

const Close = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 calc(${props => props.theme.sizes.edge} * 1);
  height: 100%;
  cursor: pointer;
  & > svg {
    font-size: 14pt;
    font-weight: 600;
    color: ${props => props.theme.colors.contentMedium};
  }
`

const Wrapper = styled(WrapperPartial)`
  ${props => props.theme.medias.custom(1060)} {
    width: 520px;
    overflow-y: auto;
    ${Grid} {
      grid-template-columns: 1fr 1fr;
    }
  }

  ${props => props.theme.medias.medium} {
    top: 0;
    left: 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    ${Grid} {
      grid-template-columns: 1fr;
    }
    ${Header} {
      display: flex;
    }
  }
`

function Dropdown ({ isActive, reducer, actions }) {
  const [reference] = useOnClickOutside(() => {
    if (isActive) actions.onRefresh()
  })

  return (
    <Wrapper data-active={isActive} data-component='dropdown' ref={reference}>
      <Header>
        <Title>Search</Title>
        <Close onClick={() => actions.onRefresh()}>
          <IconClose />
        </Close>
      </Header>
      <Grid>
        <Items.Context reducer={reducer} />
        <Items.Underlying reducer={reducer} />
        <Items.Collateral reducer={reducer} />
        <Items.Strike reducer={reducer} />
        <Items.Expiration reducer={reducer} />
        <Items.Actions
          reducer={reducer}
          handleOnRefresh={actions.onRefresh}
          handleOnSave={actions.onSave}
        />
      </Grid>
    </Wrapper>
  )
}

Dropdown.propTypes = {
  isActive: PropTypes.bool,
  reducer: PropTypes.shape({}),
  actions: PropTypes.shape({
    onRefresh: PropTypes.func,
    onSave: PropTypes.func
  })
}

Dropdown.defaultProps = {
  isActive: false,
  reducer: null,
  actions: {
    onRefresh: () => {},
    onSave: () => {}
  }
}

export default Dropdown
