import _ from 'lodash'
import { useMemo, useCallback } from 'react'
import BigNumber from 'bignumber.js'
import { useHistory } from 'react-router-dom'
import { useModal } from '@pods-finance/contexts'
import { useDataDynamicContext } from '../contexts/DataDynamic'
import { useAddresses, useToken } from './utility'
import { useMarketPrices } from './price'
import { useOptions, useOptionDynamics, useOptionsDynamics } from './data'
import { useAccount } from './web3'
import { toNumeralPrice } from '@pods-finance/utils'
import { modals, macros } from '../constants'
import Position, { PositionHelper } from '../models/Position'

/**
 *
 * HELPER METHODS
 *
 */

function _getPositionCells ({ position, tokens, market }) {
  const durations = _.attempt(() => _.get(position, 'option').getDurations())
  const option = _.get(position, 'option')
  const isExpired = _.get(durations, 'isExpired')

  return [
    {
      label: `${option.isPut() ? 'Put' : 'Call'} Option${
        isExpired ? ' (X)' : ''
      }`,
      value: `${_.get(tokens, '0.alias')}:${_.get(tokens, '1.alias')}`,
      tokens: tokens.slice(0, 2),
      isDim: isExpired
    },
    {
      label: 'Stable Token',
      value: _.get(tokens, '2.alias'),
      tokens: [_.get(tokens, '2')],
      isDim: isExpired
    },
    {
      label: 'Strike Price',
      value: toNumeralPrice(_.get(option, 'strikePrice.humanized'))
    },
    {
      label: 'Market Price',
      value: market ? toNumeralPrice(market) : '-'
    },
    {
      label: 'Exercise',
      value: _.get(durations, 'exerciseStartFormattedWithTime')
    },
    {
      label: 'Exercise Day',
      value: isExpired
        ? 'Expired'
        : _.get(durations, 'exerciseStartToTodayFormatted')
    },
    {
      label: 'Type',
      value: option.isPut() ? 'Put' : 'Call'
    }
  ]
}

function _getPositionBoxes ({
  position,
  dynamics,
  market,
  tokens,
  values,
  setOpen,
  doVisit
}) {
  const result = []

  const balance = _.get(dynamics, 'userOptionBalance.humanized')
  const durations = _.attempt(() => _.get(position, 'option').getDurations())

  const buyConditions = [
    _.get(position, 'optionsBought'),
    _.get(position, 'premiumPaid')
  ]

  if (_.get(durations, 'isExpired')) {
    if (buyConditions.some(item => !_.isNil(item) && !item.isZero())) {
      result.push(position.getBoxBuyExpired({ tokens, setOpen, doVisit }))
    } else {
      result.push(position.getBoxBuyExpiredFallback({ doVisit }))
    }
  } else {
    if (buyConditions.some(item => !_.isNil(item) && !item.isZero())) {
      result.push(
        position.getBoxBuyOngoing({ market, balance, setOpen, doVisit })
      )
    } else {
      result.push(position.getBoxBuyOngoingFallback({ doVisit }))
    }
  }

  const sellConditions = [
    _.get(position, 'optionsSold'),
    _.get(position, 'optionsResold'),
    _.get(position, 'underlyingWithdrawn'),
    _.get(position, 'strikeWithdrawn'),
    _.get(position, 'initialOptionsProvided'),
    _.get(dynamics, 'userOptionWithdrawAmounts.0.humanized'),
    _.get(dynamics, 'userOptionWithdrawAmounts.1.humanized')
  ]

  if (_.get(durations, 'isExpired')) {
    if (sellConditions.some(item => !_.isNil(item) && !item.isZero())) {
      result.push(
        position.getBoxSellExpired({
          tokens,
          setOpen,
          doVisit,
          durations,
          dynamics
        })
      )
    } else {
      result.push(position.getBoxSellExpiredFallback({ doVisit }))
    }
  } else {
    if (sellConditions.some(item => !_.isNil(item) && !item.isZero())) {
      result.push(
        position.getBoxSellOngoing({
          tokens,
          setOpen,
          doVisit,
          dynamics,
          durations
        })
      )
    } else {
      result.push(position.getBoxSellOngoingFallback({ doVisit }))
    }
  }

  const liquidityConditions = [
    _.get(position, 'initialOptionsProvided'),
    _.get(position, 'initialTokensProvided'),
    _.get(values, 'poolPositions.0.humanized')
  ]
  if (_.get(durations, 'isExpired')) {
    if (liquidityConditions.some(item => !_.isNil(item) && !item.isZero())) {
      result.push(position.getBoxLiquidityExpired({ tokens, setOpen, doVisit }))
    } else {
      result.push(position.getBoxLiquidityExpiredFallback({ doVisit }))
    }
  } else {
    if (liquidityConditions.some(item => !_.isNil(item) && !item.isZero())) {
      result.push(
        position.getBoxLiquidityOngoing({ tokens, values, setOpen, doVisit })
      )
    } else {
      result.push(position.getBoxLiquidityOngoingFallback({ doVisit }))
    }
  }

  return result
}

function _getPositionTotals ({ position, values }) {
  const { pnl, poolPositionsValue } = values
  const option = _.get(position, 'option')
  const durations = _.attempt(() => option.getDurations())
  const result = []

  if (
    !(
      _.get(position, 'premiumPaid').isZero() &&
      _.get(position, 'premiumReceived').isZero()
    )
  ) {
    result.push({
      label: 'Trading P&L',
      value: `${pnl.isLessThan(new BigNumber(0)) ? '' : '+'}${toNumeralPrice(
        pnl
      )}`,
      help:
        'The trading P&L represents the difference between premiums receieved for selling and those paid for buying.'
    })
  }

  if (
    poolPositionsValue &&
    poolPositionsValue.isGreaterThanOrEqualTo(
      new BigNumber(macros.MINIMUM_BALANCE_AMOUNT)
    )
  ) {
    if (_.get(durations, 'isExpired')) {
      result.push({
        label: 'Locked in Pool',
        value: `${toNumeralPrice(poolPositionsValue)}`,
        isSimulated: true,
        help:
          'You have stablecoins still locked in the pool. The collateral used to mint options is available in the Withdraw page.'
      })
    } else {
      result.push({
        label: 'Estimated LP Position',
        value: `${toNumeralPrice(poolPositionsValue)}`,
        isSimulated: true,
        help:
          'Your current liquidity position represents the value of the assets you have in this pool. Read the breakdown for more details.'
      })
    }
  }

  if (
    _.get(durations, 'isExpired') &&
    values &&
    _.get(values, 'flags.withdrawRequired')
  ) {
    result.push({
      label: 'Withdrawable',
      value: 'Assets',
      isSimulated: true,
      help:
        'You still have funds you can withdraw. You will get unlocked collateral and assets from being exercised. Check the Sell tab.'
    })
  }

  return result
}

function _getPositionReminders ({ position }) {
  const exercise = []
  const expiration = []

  const pair = `${_.get(position, 'option.underlying.alias')}:${_.get(
    position,
    'option.strike.alias'
  )} $${_.get(position, 'option.strikePrice.humanized')}`

  const durations = _.attempt(() => _.get(position, 'option').getDurations())

  if (
    _.get(position, 'premiumPaid') &&
    !_.get(position, 'premiumPaid').isZero()
  ) {
    exercise.push({
      type: 'exercise',
      pair,
      date: _.get(durations, 'exerciseStartFormattedWithHour'),
      start: _.get(durations, 'exerciseStart'),
      size: _.get(durations, 'exerciseWindowSize')
    })
  }

  if (
    _.get(position, 'premiumReceived') &&
    !_.get(position, 'premiumReceived').isZero()
  ) {
    expiration.push({
      type: 'invest',
      pair,
      date: _.get(durations, 'expirationFormattedWithHour'),
      start: _.get(durations, 'expiration'),
      size: _.get(durations, 'exerciseWindowSize')
    })
  }

  if (
    (_.get(position, 'initialOptionsProvided') &&
      !_.get(position, 'initialOptionsProvided').isZero()) ||
    (_.get(position, 'initialTokensProvided') &&
      !_.get(position, 'initialTokensProvided').isZero())
  ) {
    exercise.push({
      type: 'pool',
      pair,
      date: _.get(durations, 'expirationFormattedWithHour'),
      start: _.get(durations, 'expiration'),
      size: _.get(durations, 'exerciseWindowSize')
    })
  }

  return [exercise, expiration]
}

/**
 *
 * HOOKS
 *
 */

export function usePositions (isCurated = true) {
  const { options: source } = useAddresses()
  const { positions } = useDataDynamicContext()
  const { active } = useMemo(() => positions, [positions])
  const { isConnected } = useAccount()

  const { isLoading: _isLoading, warning, ...raw } = useMemo(
    () => active || {},
    [active]
  )

  const isExpected = useMemo(
    () =>
      source.length !== 0 &&
      Object.values(raw).length === 0 &&
      isConnected &&
      _.isNilOrEmptyString(warning),
    [source, raw, warning, isConnected]
  )

  const isLoading = useMemo(() => {
    return _.get(_isLoading, 'value') || isExpected
  }, [isExpected, _isLoading])

  const count = useMemo(() => Object.values(raw).length, [raw])

  const list = useMemo(() => {
    if (!_.isNilOrEmptyString(_.get(warning, 'value'))) return []
    if (_.get(isLoading, 'value')) return []
    return Object.keys(raw)
      .filter(key => key !== '_')
      .map(key => raw[key])
      .map(item => new Position(_.get(item, 'element')))
      .filter(item => item.isValid)
      .filter(item =>
        isCurated
          ? source.some(s => s.toLowerCase() === _.get(item, 'option.address'))
          : true
      )
      .sort(
        (a, b) => _.get(a, 'option.expiration') < _.get(b, 'option.expiration')
      )
  }, [raw, source, isCurated, isLoading, warning])

  return useMemo(
    () => ({
      positions: list,
      count,
      isLoading,
      warning: _.get(warning, 'value')
    }),
    [list, count, isLoading, warning]
  )
}

export function useHistoricalPositions () {
  const { positions } = useDataDynamicContext()
  const { historical } = useMemo(() => positions, [positions])

  const { warning, isLoading, ...raw } = useMemo(() => historical || {}, [
    historical
  ])

  const count = useMemo(() => Object.values(raw).length, [raw])

  const list = useMemo(() => {
    if (!_.isNilOrEmptyString(_.get(warning, 'value'))) return []
    if (_.get(isLoading, 'value')) return []

    return Object.keys(raw)
      .map(key => raw[key])
      .map(item => new Position(_.get(item, 'element')))
      .filter(item => item.isValid)
      .sort(
        (a, b) => _.get(a, 'option.expiration') < _.get(b, 'option.expiration')
      )
  }, [raw, isLoading, warning])

  return useMemo(
    () => ({
      positions: list,
      count,
      isLoading: _.get(isLoading, 'value'),
      warning: _.get(warning, 'value')
    }),
    [list, count, isLoading, warning]
  )
}

export function usePositionsReminders () {
  const { positions, isLoading: isLoadingPositions } = usePositions()
  const { isLoading: isLoadingOptions } = useOptions()

  return useMemo(() => {
    const reminders = []
    if (isLoadingPositions || isLoadingOptions) return []
    try {
      if (positions) {
        positions.forEach(position => {
          const [exercise, expiration] = _getPositionReminders({ position })
          exercise.forEach(i => reminders.push(i))
          expiration.forEach(i => reminders.push(i))
        })
      }
    } catch (error) {
      console.error(error)
    }
    return reminders.sort((a, b) => a.start > b.start)
  }, [positions, isLoadingPositions, isLoadingOptions])
}

export function usePositionsValues () {
  const dynamics = useOptionsDynamics()
  const { spot } = useMarketPrices()
  const { positions, isLoading: isLoadingPositions } = usePositions()
  const { isLoading: isLoadingOptions } = useOptions()

  return useMemo(() => {
    const values = {}
    const aggregates = {
      pnl: new BigNumber(0),
      poolPositionsValue: new BigNumber(0),
      simulated: new BigNumber(0)
    }
    try {
      if (positions) {
        positions.forEach(position => {
          const address = _.get(position, 'option.address')
          const dynamic = _.get(dynamics, address)

          const market = new BigNumber(
            _.get(
              spot,
              String(_.get(position, 'option.underlying.symbol')).toUpperCase()
            ) || 0
          )

          if (!_.isNil(dynamic) && !dynamics.isLoading) {
            const item = position.getValues({ dynamics: dynamic, market })
            values[address] = item

            aggregates.pnl = aggregates.pnl.plus(item.pnl)
            aggregates.poolPositionsValue = aggregates.poolPositionsValue.plus(
              item.poolPositionsValue
            )
          }
        })
      }
    } catch (error) {
      console.error(error)
    }

    const breakdown = {
      pnl: PositionHelper.getBreakdownPNL(aggregates).sections,
      poolPositionsValue: PositionHelper.getBreakdownPoolPositionsValue(
        aggregates
      ).sections
    }

    return {
      aggregates,
      breakdown,
      positions,
      values,
      isLoading: isLoadingOptions || dynamics.isLoading || isLoadingPositions
    }
  }, [positions, dynamics, spot, isLoadingOptions, isLoadingPositions])
}

export function usePositionCard (position) {
  const { setOpen } = useModal(modals.fullBreakdown)
  const address = useMemo(() => _.get(position, 'option.address'), [position])
  const _tokens = useMemo(
    () => [
      _.get(position, 'option.underlying'),
      _.get(position, 'option.strike'),
      _.get(position, 'option.pool.tokenB'),
      _.get(position, 'option.collateral'),
      _.get(position, 'option.anticollateral')
    ],
    [position]
  )
  const history = useHistory()
  const dynamics = useOptionDynamics(address)
  const { spot } = useMarketPrices()
  const { value: tokens } = useToken(_tokens)

  const doVisit = useCallback(page => history.push(page), [history])

  const market = useMemo(() => {
    const value = _.get(spot, String(_.get(tokens, '0.symbol')).toUpperCase())
    if (_.isNilOrEmptyString(value)) return value
    return new BigNumber(value)
  }, [spot, tokens])

  const values = useMemo(() => position.getValues({ dynamics, market }), [
    position,
    dynamics,
    market
  ])

  const cells = useMemo(() => _getPositionCells({ position, tokens, market }), [
    position,
    tokens,
    market
  ])

  const boxes = useMemo(
    () =>
      _getPositionBoxes({
        position,
        dynamics,
        market,
        tokens,
        values,
        setOpen,
        doVisit
      }),
    [position, dynamics, market, tokens, values, setOpen, doVisit]
  )

  const totals = useMemo(() => _getPositionTotals({ position, values }), [
    position,
    values
  ])

  return useMemo(() => ({ cells, boxes, totals }), [cells, boxes, totals])
}
