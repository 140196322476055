import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-directon: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  position: relative;
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1 / 2);

  *[data-purpose='helper-wrapper'] {
    display: flex;
    align-items: center;
  }
  & > * {
    margin-right: 4px;
    &:last-child {
      margin: 0;
    }
  }
`
