import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'
import { modals } from '../../../../constants'
import { Modal as Base } from '@pods-finance/components'
import { useModal } from '@pods-finance/contexts'

const Modal = styled(Base)`
  & > div[data-component='card'] {
    max-width: 800px;
    border-radius: ${props => props.theme.sizes.edge};
  }
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  padding-bottom: 32px;
`

const Parasection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 32px;
  &:last-child {
    margin-bottom: 0;
  }
`

const Paratitle = styled.p`
  color: ${props => props.theme.colors.dark};
  font-size: 11pt;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
  margin-bottom: 12px;
  text-align: left;

  a {
    color: ${props => props.theme.colors.middle};
    font-weight: 600;
  }
`

const Paragraph = styled.div`
  color: ${props => props.theme.colors.dark};
  font-size: 11pt;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  text-align: left;

  a {
    color: ${props => props.theme.colors.middle};
    text-decoration: underline;
    font-weight: 600;
  }
  code {
    background: ${props =>
      props.theme.isDark
        ? lighten(0.05, props.theme.colors.platform)
        : props.theme.colors.platform};
  }
  li {
    padding-bottom: 20px;
  }
`

function FullBreakdown () {
  const id = modals.fullBreakdown
  const { data } = useModal(id)
  const sections = useMemo(() => _.get(data, 'sections'), [data])
  const title = useMemo(() => _.get(data, 'title'), [data])

  return (
    <Modal id={id} title={title || 'Full Breakdown'}>
      <Content>
        {_.isArray(sections) &&
          sections.map(section => (
            <Parasection key={_.get(section, 'title')}>
              <Paratitle>{_.get(section, 'title')}</Paratitle>
              <Paragraph>{_.get(section, 'description')}</Paragraph>
            </Parasection>
          ))}
      </Content>
    </Modal>
  )
}

export default FullBreakdown
