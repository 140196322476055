import _ from 'lodash'
import { useMemo, useCallback } from 'react'
import { prefs } from '../constants'
import { useUIContext } from '../contexts/UI'

export function useVersion () {
  const { versioning } = useUIContext()
  return versioning
}
export function usePreferences () {
  const { preferences } = useUIContext()
  const { value, update } = useMemo(() => preferences, [preferences])
  return {
    value,
    update
  }
}

export function useUI () {
  const { controls } = useUIContext()
  return controls
}

export function useIsUIAdvanced () {
  const { value: preferences } = usePreferences()
  const isEnabled = useMemo(
    () =>
      !_.isNil(preferences) &&
      [true, 'true'].includes(_.get(preferences, prefs.isAdvancedModeEnabled)),
    [preferences]
  )
  return isEnabled
}

export function useDarkMode () {
  const { value: preferences, update: updatePreferences } = usePreferences()
  const isDark = useMemo(
    () =>
      !_.isNil(preferences) &&
      [true, 'true'].includes(_.get(preferences, prefs.isDarkTheme)),
    [preferences]
  )

  const toggle = useCallback(
    value => {
      updatePreferences({
        [prefs.isDarkTheme]: !!value
      })
    },
    [updatePreferences]
  )

  return { isDark, toggle }
}

export function useClassification () {
  const { value: preferences, update: updatePreferences } = usePreferences()
  const isCall = useMemo(
    () =>
      !_.isNil(preferences) &&
      [true, 'true'].includes(_.get(preferences, prefs.isClassificationCall)),
    [preferences]
  )

  const isPut = useMemo(() => !isCall, [isCall])

  const toggle = useCallback(
    isCall => {
      updatePreferences({
        [prefs.isClassificationCall]: isCall
      })
    },
    [updatePreferences]
  )

  const title = useMemo(() => {
    return {
      short: isCall ? 'Call' : 'Put',
      long: isCall ? 'Call Options' : 'Put Options'
    }
  }, [isCall])

  return useMemo(() => ({ isCall, isPut, title, toggle }), [
    isCall,
    isPut,
    title,
    toggle
  ])
}
