import _ from 'lodash'
import mixpanel from 'mixpanel-browser'
import { events, prefs } from '../constants'

import { localStorageAccessSync } from '@pods-finance/utils'

export default {
  init: () => {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)
  },
  track: (label, parameters) => {
    try {
      const preferences = localStorageAccessSync('preferences', null)

      if (
        _.isNil(preferences) ||
        ![true, 'true'].includes(_.get(preferences, prefs.isAnalyticsEnabled))
      ) {
        return
      }

      let tag = label
      if (_.isFunction(label)) {
        tag = (() => {
          const event = label(events) // allow for the track(events => events.A) form
          if (Object.values(events).includes(event)) return event
          else return 'Pods Event'
        })()
      }

      if (parameters) mixpanel.track(tag, parameters)
      else mixpanel.track(tag)
    } catch (e) {
      console.error('Analytics', e)
    }
  }
}
