import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { lighten } from 'polished'
import { Spinner } from '@pods-finance/components'
import IconBreakdown from '@material-ui/icons/WrapTextRounded'
import IconVisit from '@material-ui/icons/ArrowForwardRounded'
import { ReactComponent as Decorator } from '../../../../assets/decorators/oval_group_small.svg'

const Inner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.border};
  background-color: ${props =>
    props.theme.isDark
      ? props.theme.colors.platform
      : props.theme.colors.white};
  padding: calc(${props => props.theme.sizes.edge} * 3 / 2);
  min-height: 100%;

  &[data-loader='true'] {
    min-height: 74px;
    align-items: center;
    justify-content: center;
  }
  ${props => props.theme.medias.medium} {
    padding: calc(${props => props.theme.sizes.edge} * 1);
  }
`

const Wrapper = styled.div`
  grid-column: span 1;

  &[data-placeholder='true'] {
    ${Inner} {
      justify-content: flex-start;
      align-items: flex-start;
      position: relative;
      min-height: auto;
    }
  }
`

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Title = styled.div`
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1);
  & > p {
    font-size: 14pt;
    font-weight: 700;
    color: ${props => props.theme.colors.dark};
    margin: 0;
  }
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & > * {
    margin-right: calc(${props => props.theme.sizes.edge} * 1 / 3);
    &:last-child {
      margin: 0;
    }
  }

  ${props => props.theme.medias.medium} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: calc(${props => props.theme.sizes.edge} * 1 / 3);
    & > * {
      margin: 0;
    }
  }
`

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  padding: 0 10px;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.colors.white};
  cursor: pointer;

  & > svg {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11pt;
    color: ${props => props.theme.colors.dark};
    margin-left: 6px;
  }
  & > p {
    font-size: 10pt;
    font-weight: 600;
    color: ${props => props.theme.colors.dark};
    margin: 0;
  }
  &:hover,
  &:active {
    background-color: ${props => props.theme.colors.platform};
  }
`

const Divider = styled.div`
  margin: calc(${props => props.theme.sizes.edge} * 1.5) 0;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${props => props.theme.colors.border};
  &[data-dashed='true'] {
    border-bottom: 1px dashed ${props => props.theme.colors.borderMedium};
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  &[data-expand='true'] {
    flex: 1;
  }
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 1);
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Element = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`

const ElementLabel = styled.div`
  flex-shrink: 0;
  & > p {
    font-size: 11pt;
    font-weight: 600;
    color: ${props => props.theme.colors.dark};
    margin: 0;
    padding-right: 10px;
    text-align: left;
  }
`

const ElementValue = styled.div`
  flex: 1;
  & > p {
    font-size: 11pt;
    font-weight: 600;
    color: ${props => props.theme.colors.dark};
    margin: 0;
    padding-right: 10px;
    text-align: right;
  }

  &[data-simulated='true'] {
    & > p {
      color: ${props => props.theme.colors.contentMedium};
    }
  }

  &[data-warned='true'] {
    & > p {
      color: ${props => props.theme.colors.red} !important;
    }
  }
`

const Underlay = styled.div`
  position: absolute;
  z-index: 100;
  right: 16px;

  & > svg {
    height: 100px;
    width: 100px;
    margin-top: -10px;
    rect {
      stroke: ${props =>
        props.theme.isDark
          ? lighten(0.05, props.theme.colors.platform)
          : props.theme.colors.platformMedium};
      stroke-width: 5;
    }
  }
`

function Placeholder ({ title, action, onActionClick }) {
  return (
    <Wrapper data-placeholder='true'>
      <Inner>
        <Header>
          <Title>
            <p>{title}</p>
          </Title>
          <Buttons>
            <Button onClick={onActionClick || _.noop}>
              <p>{action}</p>
              <IconVisit />
            </Button>
          </Buttons>
        </Header>
        <Underlay>
          <Decorator />
        </Underlay>
      </Inner>
    </Wrapper>
  )
}

function BoxLoader ({ spinner }) {
  return (
    <Wrapper>
      <Inner data-loader>
        {spinner && <Spinner color={c => c.content} size={20} />}
      </Inner>
    </Wrapper>
  )
}

function Box ({
  title,
  activity,
  total,
  action,
  support,
  onActionClick,
  onSupportClick,
  onBreakdownClick
}) {
  if (_.isNil(activity) || !activity.length) {
    return (
      <Placeholder
        title={title}
        action={action}
        onActionClick={onActionClick}
      />
    )
  }

  return (
    <Wrapper>
      <Inner>
        <Header>
          <Title>
            <p>{title}</p>
          </Title>
          <Buttons>
            <Button onClick={onBreakdownClick || _.noop}>
              <p>Breakdown</p>
              <IconBreakdown />
            </Button>

            <Button onClick={onActionClick || _.noop}>
              <p>{action}</p>
              <IconVisit />
            </Button>
            {support && (
              <Button onClick={onSupportClick || _.noop}>
                <p>{support}</p>
              </Button>
            )}
          </Buttons>
        </Header>
        <Divider />

        <Section data-expand>
          {activity.map(element => (
            <Element key={_.get(element, 'label')}>
              <ElementLabel>
                <p>{_.get(element, 'label')}</p>
              </ElementLabel>
              <ElementValue
                data-simulated={_.get(element, 'isSimulated')}
                data-warned={_.get(element, 'isWarned')}
              >
                <p>{_.get(element, 'value')}</p>
              </ElementValue>
            </Element>
          ))}
        </Section>

        {total && total.length ? <Divider data-dashed /> : null}

        <Section>
          {total.map(element => (
            <Element key={_.get(element, 'label')}>
              <ElementLabel>
                <p>{_.get(element, 'label')}</p>
              </ElementLabel>
              <ElementValue
                data-simulated={_.get(element, 'isSimulated')}
                data-warned={_.get(element, 'isWarned')}
              >
                <p>{_.get(element, 'value')}</p>
              </ElementValue>
            </Element>
          ))}
        </Section>
      </Inner>
    </Wrapper>
  )
}

Box.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  support: PropTypes.string,
  activity: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      isSimulated: PropTypes.bool,
      isWarned: PropTypes.bool
    })
  ),
  total: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      isSimulated: PropTypes.bool,
      isWarned: PropTypes.bool
    })
  ),
  onActionClick: PropTypes.func,
  onBreakdownClick: PropTypes.func
}

Box.defaultProps = {
  activity: [],
  total: [],
  title: 'Section',
  action: 'Manage',
  support: null,
  onActionClick: null,
  onSupportClick: null,
  onBreakdownClick: null
}

export { BoxLoader }
export default Box
