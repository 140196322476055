import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { lighten } from 'polished'
import { Link } from 'react-router-dom'
import { Table as TablePartial } from '@pods-finance/components'

import { pages } from '../../../constants'
import {
  useAccount,
  useActivityTable,
  useSupportedNetwork
} from '../../../hooks'

import Helmet from '../../../components/specific/Helmet'
import Restricted from '../../../components/specific/Restricted'
import { Structure, ToolbarBase } from '../../../components/common'
import {
  Header as SectionHeader,
  Title as SectionTitlePartial,
  Description as SectionDescription,
  Wrapper as SectionWrapper
} from '../../../components/common/Section'

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0;

  ${props => props.theme.styles.horizontalScrollContainer};
`

const TableWrapper = styled.div`
  width: 100%;
`

const Table = styled(TablePartial)`
  min-width: 900px;
  padding: 0 ${props => props.theme.sizes.layoutEdge};

  div[data-component='body'] {
    min-height: 86px;
  }

  div[data-component='row'] {
    cursor: default;
    ${props =>
      props.theme.isDark &&
      css`
        &:hover,
        &:active {
          background-color: ${props =>
            lighten(0.05, props.theme.colors.platform)};
        }
      `}
  }

  ${props => props.theme.medias.medium} {
    padding: 0 calc(${props => props.theme.sizes.layoutEdgeMedium});
  }
`

const SectionTitle = styled(SectionTitlePartial)`
  & > * {
    margin-right: 8px !important;
    &:last-child {
      margin: 0;
    }
  }
  & > a {
    position: relative;
    & > p {
      color: ${props => props.theme.colors.content};
    }
    &:hover,
    &:active {
      &:after {
        position: absolute;
        content: '';
        bottom: -4px;
        left: 0;
        height: 3px;
        width: 100%;
        background-color: ${props => props.theme.colors.content};
      }
    }
  }
`

function Activity () {
  const supported = useSupportedNetwork()
  const { isExpected, isConnected } = useAccount()
  const { data } = useActivityTable()

  const isEnabled = useMemo(() => supported && (isConnected || isExpected), [
    isExpected,
    isConnected,
    supported
  ])

  return (
    <Structure>
      <Helmet.Activity />
      <ToolbarBase />
      {isEnabled ? (
        <>
          <SectionWrapper>
            <SectionHeader>
              <SectionTitle>
                <p>Transactions /</p>
                <Link to={pages.activityPositions.route}>
                  <p> Positions</p>
                </Link>
              </SectionTitle>
              <SectionDescription>
                The latest activity linked to your wallet’s activity on Pods.
                Click on positions to view all your past positions.
              </SectionDescription>
            </SectionHeader>
            <Content>
              <TableWrapper>
                <Table id='activity' data={data} />
              </TableWrapper>
            </Content>
          </SectionWrapper>
        </>
      ) : (
        <Restricted.Activity isConnected={isConnected} />
      )}
    </Structure>
  )
}

export default Activity
