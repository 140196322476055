import _ from 'lodash'
import React from 'react'
import { Card, Title, Description, Content } from '../atoms'

import { Input } from '@pods-finance/components'
import { useNetworkId } from '../../../../../../hooks'

function Strike ({ reducer }) {
  const networkId = useNetworkId()
  return (
    <Card>
      <Title>Strike Price</Title>
      <Description>
        Input the strike price in USD. We'll show you options that match it.
      </Description>
      <Content>
        <Input.Amount
          {...reducer.state.strike}
          placeholder='Any | Type price ...'
          networkId={networkId}
          onChange={e => {
            reducer.dispatch([
              reducer.elements.strike,
              {
                value: _.get(e, 'target.value')
              }
            ])
          }}
        />
      </Content>
    </Card>
  )
}

export default Strike
