import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import numeral from 'numeral'
import { useModal } from '@pods-finance/contexts'
import { toNumeralPrice } from '@pods-finance/utils'

import { Section } from '../../../../common'
import { pages, modals } from '../../../../../constants'
import {
  useFeesVolume,
  useDynamicsTVL,
  usePositionsValues,
  useAccount,
  useWalletModal
} from '../../../../../hooks'

import IconTVL from '@material-ui/icons/AccountBalanceRounded'
import IconFees from '@material-ui/icons/DonutLargeRounded'
import IconWallet from '@material-ui/icons/AccountBalanceWalletRounded'
import IconHistory from '@material-ui/icons/HistoryRounded'

import Card from './Card'

const Wrapper = styled.div``

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 ${props => props.theme.sizes.layoutEdge};
  ${props => props.theme.medias.medium} {
    padding: 0 ${props => props.theme.sizes.layoutEdgeMedium};
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: calc(${props => props.theme.sizes.edge} * 1 / 2);
  width: 100%;

  ${props => props.theme.medias.large} {
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
  }

  ${props => props.theme.medias.medium} {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  }
`

const Value = styled.p``

function FeeCard () {
  const { fees, breakdown, isLoading } = useFeesVolume()
  const { setOpen } = useModal(modals.fullBreakdown)

  return (
    <Card
      Icon={IconFees}
      title='Accumulated fees'
      titleShort='Fees'
      isAccented
      isLoading={isLoading}
      onClick={() => {
        setOpen(true, {
          title: 'Full Breakdown: Fees',
          sections: breakdown
        })
      }}
    >
      <Value> {numeral(fees).format('$0.000a')}</Value>
    </Card>
  )
}

function TVLCard () {
  const positions = usePositionsValues()
  const tvl = useDynamicsTVL()
  const { setOpen } = useModal(modals.fullBreakdown)

  return (
    <Card
      Icon={IconTVL}
      title='Total value locked'
      titleShort='TVL'
      onClick={() => {
        setOpen(true, {
          title: 'Full Breakdown: Total value locked',
          sections: _.get(tvl, 'sections')
        })
      }}
      isAccented
      isLoading={positions.isLoading}
    >
      <Value>
        {numeral(toNumeralPrice(_.get(tvl, 'value'))).format('$0.000a')}
      </Value>
    </Card>
  )
}

export default function Overview () {
  const positions = usePositionsValues()
  const { connect } = useWalletModal()
  const { isConnected, isExpected } = useAccount()
  const { setOpen } = useModal(modals.fullBreakdown)

  return (
    <Wrapper>
      <Section title='Overview'>
        <Content>
          <Grid>
            {!isConnected && !isExpected ? (
              <>
                <Card
                  Icon={IconWallet}
                  title='Connect your wallet'
                  titleShort='Connect'
                  onClick={() => connect()}
                  isLoading={false}
                  isAccented
                >
                  <Value>Connect</Value>
                </Card>
                <TVLCard />
              </>
            ) : (
              <>
                <TVLCard />
                <FeeCard />
                <Card
                  Icon={pages.pool.Icon}
                  title='Your liquidity in pools'
                  titleShort='Liquidity'
                  onClick={() => {
                    setOpen(true, {
                      title: 'Full Breakdown: Your liquidity in pools',
                      sections: _.get(positions, 'breakdown.poolPositionsValue')
                    })
                  }}
                  isLoading={positions.isLoading}
                >
                  <Value>
                    {toNumeralPrice(
                      _.get(positions, 'aggregates.poolPositionsValue')
                    )}
                  </Value>
                </Card>
                <Card
                  Icon={IconHistory}
                  title='Missing other series?'
                  titleShort='Archived'
                  to={pages.activityPositions.builder()}
                  isLoading={positions.isLoading}
                >
                  <Value>Old Positions</Value>
                </Card>
              </>
            )}
          </Grid>
        </Content>
      </Section>
    </Wrapper>
  )
}
