import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  grid-column: span 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &[data-dim='true'] {
    img {
      filter: grayscale(95%);
    }
  }
  ${props => props.theme.medias.medium} {
    align-items: flex-start;
  }
`

const Icons = styled.div`
  margin-right: 8px;
  display: flex;

  & > img {
    height: 32px;
    width: 32px;
    background-color: ${props => props.theme.colors.platform};
    border-radius: 50%;
    flex-shrink: 0;
    &[src=''],
    &:not([src]) {
      opacity: 0;
    }

    margin-left: -14px;
    &:first-child {
      margin-left: 0;
      z-index: 10;
    }
    &:nth-child(2) {
      z-index: 9;
    }
  }
  ${props => props.theme.medias.medium} {
    order: 100;
    margin-left: 8px;
    margin-right: 0;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  ${props => props.theme.medias.medium} {
    flex: 1;
  }
`

const Label = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 10pt;
  font-weight: 700;
  color: ${props => props.theme.colors.contentMedium};
  text-align: left;
  margin-bottom: 4px;

  & > p {
    margin: 0;
  }
  svg {
    color: ${props => props.theme.colors.contentMedium};
    font-size: 13pt;
    margin-right: 4px;
  }
`

const Value = styled.div`
  & > p {
    ${props => props.theme.styles.tableParagraph};
  }
  ${props => props.theme.medias.medium} {
    & > p {
      word-break: break-word;
      line-height: 1.4;
    }
  }
`

function Cell ({ tokens, label, value, isDim }) {
  const icons = useMemo(
    () => _.toArray(tokens).map(token => _.get(token, 'icon')),
    [tokens]
  )

  return (
    <Wrapper data-dim={isDim}>
      {icons.length ? (
        <Icons>
          {icons.map((icon, index) => (
            <img key={index} src={icon} alt='' />
          ))}
        </Icons>
      ) : null}
      <Content>
        <Label>
          <p>{label}</p>
        </Label>
        <Value>
          <p>{value}</p>
        </Value>
      </Content>
    </Wrapper>
  )
}

Cell.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  tokens: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  isDim: PropTypes.bool
}

Cell.defaultProps = {
  tokens: [],
  isDim: false
}

export default Cell
