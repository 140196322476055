import React from 'react'
import styled from 'styled-components'
import { useClassification } from '../../../../hooks'
import Section from '../../../common/Section'
import RestrictedNetwork from '../RestrictedNetwork'

const Content = styled.div`
  width: 100%;
`

function Hedge () {
  const { title, isPut } = useClassification()
  return (
    <Section
      title={`Buy ${title.long}`}
      description={`Buy European, fully collateralized options and ${
        isPut ? 'hedge' : 'invest'
      } your portfolio.`}
    >
      <Content>
        <RestrictedNetwork />
      </Content>
    </Section>
  )
}

export default Hedge
