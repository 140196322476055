/**
 * Takes an object and returns a Google Calendar Event URL
 * @param {string} params.description
 * @param {string} params.start
 * @param {string} params.end
 * @param {string} params.location
 * @param {string} params.title
 * @returns {string} Google Calendar Event URL
 */

export const getGoogleCalendarEventURL = ({
  description,
  start,
  end,
  location,
  timezone = 'UTC',
  title
}) =>
  new URL(
    `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${start}/${end}${
      timezone ? `&ctz=${timezone}` : ''
    }&location=${location}&text=${title}&details=${description}`
  )
