import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useToasts } from 'react-toast-notifications'

import IconResell from '@material-ui/icons/LowPriorityRounded'
import IconWithdraw from '@material-ui/icons/GetAppRounded'
import Helmet from '../../../Helmet'
import * as Tabs from '../../Tabs/Invest'

import { tabs, pages } from '../../../../../constants'
import {
  TabBar,
  TabItem,
  SupportLoader,
  SupportMissing,
  SupportConnect
} from '../../Elements'

import { useAccount, useWalletModal, useOptionInfo } from '../../../../../hooks'

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-radius: ${props => props.theme.sizes.edge};
  border: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.colors.white};
  padding-bottom: ${props => props.theme.sizes.edge};
  padding-top: calc(${props => props.theme.sizes.edge} * 1 / 2);

  ${props => props.theme.medias.medium} {
    padding-bottom: 0;
    padding-top: 2px;
    border-radius: 0;
  }
`

const Content = styled.div`
  width: 100%;
  min-height: 200px;
`

function Invest () {
  const { addToast, removeAllToasts } = useToasts()
  const { durations, option, isRestricted, isLoading } = useOptionInfo()
  const { isExpired, isExercising } = durations || {}

  const { isConnected } = useAccount()
  const { connect } = useWalletModal()

  const isReady = useMemo(() => {
    if (isLoading) return false
    if (_.isNil(option)) return false
    if (_.isNil(durations)) return false
    const { isExpired, isExercising } = durations
    if (_.isNil(isExpired) || _.isNil(isExercising)) return false

    return true
  }, [durations, option, isLoading])

  const [locked, fallback] = useMemo(() => {
    if (isExpired) {
      return [[tabs.invest.write, tabs.invest.resell], tabs.invest.withdraw]
    }
    if (isExercising) {
      return [[tabs.invest.write, tabs.invest.resell], tabs.invest.withdraw]
    }
    return [[tabs.invest.withdraw], tabs.invest.write]
  }, [isExercising, isExpired])

  return (
    <Wrapper>
      <Helmet.TransactionInvest data={{ option, durations }} />
      <TabBar
        fallback={fallback}
        supported={Object.values(tabs.invest)}
        locked={locked}
        isLoading={!isReady}
      >
        <TabItem
          Icon={pages.invest.Icon}
          title='Write'
          hash={tabs.invest.write}
          isLocked={locked.includes(tabs.invest.write)}
          onLockedClick={() => {
            removeAllToasts()
            addToast('Selling as allowed only before the expiration date.', {
              appearance: 'warning',
              autoDismiss: true,
              autoDismissTimeout: 5000
            })
          }}
        />
        <TabItem
          Icon={IconResell}
          title='Resell'
          hash={tabs.invest.resell}
          isLocked={locked.includes(tabs.invest.resell)}
          onLockedClick={() => {
            removeAllToasts()
            addToast('Reselling is available only before the exercise date.', {
              appearance: 'warning',
              autoDismiss: true,
              autoDismissTimeout: 5000
            })
          }}
        />

        <TabItem
          Icon={IconWithdraw}
          title='Withdraw'
          hash={tabs.invest.withdraw}
          onLockedClick={() => {
            removeAllToasts()
            addToast(
              'Withdrawing your collateral is available only after the exercise window.',
              {
                appearance: 'warning',
                autoDismiss: true,
                autoDismissTimeout: 5000
              }
            )
          }}
          isLocked={locked.includes(tabs.invest.withdraw)}
        />
      </TabBar>
      <Content>
        {isRestricted ? (
          <SupportMissing />
        ) : (
          <>
            {!locked.includes(tabs.invest.write) && <Tabs.Write />}
            {!locked.includes(tabs.invest.resell) && <Tabs.Resell />}
            {!locked.includes(tabs.invest.withdraw) && <Tabs.Withdraw />}
          </>
        )}
      </Content>
      <SupportLoader isActive={!isReady && !isRestricted} />
      <SupportConnect isActive={isConnected} onClick={connect} />
    </Wrapper>
  )
}

export default Invest
