import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { Position } from '../../../common'
import { PositionLoader, PositionEmpty } from '../../../common/Position'
import { useHistoricalPositions } from '../../../../hooks'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
  padding: 0 ${props => props.theme.sizes.layoutEdge};
  & > * {
    margin-bottom: ${props => props.theme.sizes.edge};
    &:last-child {
      margin-bottom: 0;
    }
  }
  ${props => props.theme.medias.medium} {
    padding: 0 ${props => props.theme.sizes.layoutEdgeMedium};
  }
`

function Positions () {
  const { positions: list, isLoading } = useHistoricalPositions()

  return (
    <Wrapper>
      {isLoading ? <PositionLoader /> : null}
      {!isLoading && !list.length ? <PositionEmpty /> : null}
      {!isLoading && list.length
        ? list.map(item => <Position key={_.get(item, 'id')} item={item} />)
        : null}
    </Wrapper>
  )
}

export default Positions
