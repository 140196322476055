import React from 'react'
import ReactDOM from 'react-dom'
import App from './pages/App'
import * as vendors from './vendors'
import { _config } from '@pods-finance/utils'

_config()
vendors.initialize()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
