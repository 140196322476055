import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { Emoji, Spinner } from '@pods-finance/components'
import { lighten } from 'polished'
import Box from './Box'
import Series from './Series'
import Total from './Total'

import { usePositionCard } from '../../../hooks'
import { ReactComponent as Decorator } from '../../../assets/decorators/oval_group_small.svg'

const WrapperPartial = styled.div`
  width: 100%;
  border-radius: calc(${props => props.theme.sizes.edge} * 1);
  border: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.sizes.edge};
`

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: ${props => props.theme.sizes.edge};
  width: 100%;
`

const Top = styled.div`
  grid-column: span 1;
  width: 100%;
  padding: ${props => props.theme.sizes.edge};
  border-radius: calc(${props => props.theme.sizes.edge} * 1 / 2);
  border: 1px solid ${props => props.theme.colors.border};
  background-color: ${props =>
    props.theme.isDark
      ? lighten(0.05, props.theme.colors.platform)
      : props.theme.colors.platform};
  min-height: 40px;
`

const Bottom = styled.div`
  grid-column: span 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: calc(${props => props.theme.sizes.edge});
  & > * {
    margin-left: calc(${props => props.theme.sizes.edge} * 3);
    &:first-child {
      margin-left: 0;
    }
  }
`

const Middle = styled.div`
  grid-column: span 1;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${props => props.theme.sizes.edge};

  ${props => props.theme.medias.custom(1250)} {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Underlay = styled.div`
  position: absolute;
  z-index: 100;
  right: 48px;

  & > svg {
    height: 120px;
    width: 120px;
    rect {
      stroke: ${props => props.theme.colors.platformMedium};
      stroke-width: 5;
    }
  }
`

const Wrapper = styled(WrapperPartial)`
  &[data-loader='true'] {
    background: none;
    border: none;
  }

  &[data-empty='true'] {
    & > ${Content} {
      min-height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        text-align: center;
        margin: 0;
        color: ${props => props.theme.colors.contentMedium};
        line-height: 1.5;
        font-size: 11pt;
        font-weight: 600;
      }
    }
    ${props => props.theme.animations.fadeInCss};
    animation-delay: 500ms;
  }

  ${props => props.theme.medias.medium} {
    overflow: hidden;
    border-radius: calc(${props => props.theme.sizes.edge} * 1 / 2);
    padding: calc(${props => props.theme.sizes.edge} * 1 / 2);
    ${Middle} {
      grid-template-columns: 1fr;
      grid-gap: calc(${props => props.theme.sizes.edge} * 1 / 2);
    }
    ${Top} {
      border-radius: 4px;
    }
    ${Content} {
      grid-gap: calc(${props => props.theme.sizes.edge} * 1 / 2);
    }
    ${Bottom} {
      width: 100%;
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: calc(${props => props.theme.sizes.edge} * 1 / 2);
      padding: calc(${props => props.theme.sizes.edge} * 1)
        calc(${props => props.theme.sizes.edge} * 1 / 2);
      & > * {
        grid-column: span 1;
      }
    }
  }
`

function Position ({ item }) {
  const { cells, boxes, totals } = usePositionCard(item)

  return (
    <Wrapper>
      <Content>
        <Top>
          <Series cells={cells} />
        </Top>
        <Middle>
          {boxes.map(b => (
            <Box key={_.get(b, 'title')} {...b} />
          ))}
        </Middle>
        <Bottom>
          {totals.map(t => (
            <Total key={_.get(t, 'label')} {...t} />
          ))}
        </Bottom>
      </Content>
    </Wrapper>
  )
}

function PositionEmpty () {
  return (
    <Wrapper data-empty='true'>
      <Content>
        <Emoji label='Oops' symbol='✌️' />
        <p>Hi! You have no open positions.</p>
        <Underlay>
          <Decorator />
        </Underlay>
      </Content>
    </Wrapper>
  )
}

function PositionLoader () {
  return (
    <Wrapper data-loader='true'>
      <Spinner color={(c, d) => (d ? c.whiteAbsolute : c.dark)} size={30} />
    </Wrapper>
  )
}

export { PositionLoader, PositionEmpty }

export default Position
