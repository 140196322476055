import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Emoji } from '@pods-finance/components'
import ContentBox from '../ContentBox'
import ContentSection from '../ContentSection'
import { pages } from '../../../../../constants'

const Wrapper = styled.div`
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 3 / 2);
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Info = styled.p`
  font-size: ${props => props.theme.sizes.text};
  color: ${props => props.theme.colors.dark};
  margin: 0;
  line-height: 1.5;
  font-weight: 500;

  span {
    font-size: 16pt;
  }
`

const CallToAction = styled.div`
  & > p {
    font-size: ${props => props.theme.sizes.text};
    color: ${props => props.theme.colors.dark};
    margin: 0;
    line-height: 1.5;
    font-weight: 500;

    b {
      font-weight: 600;
      text-decoration: underline;
    }
  }
`

function Missing () {
  return (
    <ContentBox force isLoading={false}>
      <Wrapper>
        <ContentSection title='Option Missing'>
          <Info>
            We cannot detect this option in our list. It looks like it's gone
            missing <Emoji symbol='🛸' label='UFO' />
          </Info>
        </ContentSection>
        <ContentSection title='Where to go next?' isContained>
          <CallToAction>
            <p>
              Check out some of our other options and engage in{' '}
              <Link to={pages.invest.route}>
                <b>selling</b>
              </Link>
              ,{' '}
              <Link to={pages.hedge.route}>
                <b>buying</b>
              </Link>{' '}
              or{' '}
              <Link to={pages.pool.route}>
                <b>providing liquidity</b>
              </Link>
              .
            </p>
          </CallToAction>
        </ContentSection>
      </Wrapper>
    </ContentBox>
  )
}

export default Missing
