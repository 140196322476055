import { useAtomicReducer } from '@pods-finance/hooks'

const initial = {
  collateral: {
    value: null,
    max: null,
    token: null,
    warning: null,
    isPrimary: true
  },
  strike: {
    value: null,
    max: null,
    token: null,
    warning: null,
    isPrimary: false
  },
  allowance: {
    collateral: false,
    strike: false,
    isLoadingCollateral: false,
    isLoadingStrike: false
  }
}

export default {
  useReducer: () => useAtomicReducer(initial)
}
