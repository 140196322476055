import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Helmet from '../../../components/specific/Helmet'
import Restricted from '../../../components/specific/Restricted'
import { Structure, ToolbarBase } from '../../../components/common'
import {
  Header as SectionHeader,
  Title as SectionTitlePartial,
  Description as SectionDescription,
  Wrapper as SectionWrapper
} from '../../../components/common/Section'

import Positions from '../../../components/specific/Activity/Positions'
import { useAccount, useSupportedNetwork } from '../../../hooks'
import { pages } from '../../../constants'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0;
  overflow: hidden;
`

const SectionTitle = styled(SectionTitlePartial)`
  & > * {
    margin-right: 8px !important;
    &:last-child {
      margin: 0;
    }
  }
  & > a {
    position: relative;
    & > p {
      color: ${props => props.theme.colors.content};
    }
    &:hover,
    &:active {
      &:after {
        position: absolute;
        content: '';
        bottom: -4px;
        left: 0;
        height: 3px;
        width: 100%;
        background-color: ${props => props.theme.colors.content};
      }
    }
  }
`

function Activity () {
  const supported = useSupportedNetwork()
  const { isExpected, isConnected } = useAccount()

  const isEnabled = useMemo(() => supported && (isConnected || isExpected), [
    isExpected,
    isConnected,
    supported
  ])

  return (
    <Structure>
      <Helmet.Activity />
      <ToolbarBase />
      {isEnabled ? (
        <>
          <SectionWrapper>
            <SectionHeader>
              <SectionTitle>
                <Link to={pages.activityTransactions.route}>
                  <p>Transactions</p>
                </Link>
                <p>/ Positions</p>
              </SectionTitle>
              <SectionDescription>
                Historical positions for expired options you interacted with.
              </SectionDescription>
            </SectionHeader>
            <Content>
              <Positions />
            </Content>
          </SectionWrapper>
        </>
      ) : (
        <Restricted.Activity isConnected={isConnected} />
      )}
    </Structure>
  )
}

export default Activity
