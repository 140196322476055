import React from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { useClassification, useOptionInfo } from '../../../hooks'
import { features } from '../../../constants'
import IconLock from '@material-ui/icons/LockOutlined'
const WrapperPartial = styled.div`
  position: relative;
`

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: auto;
  height: 40px;
  border-radius: 20px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.border};
  transition: box-shadow 100ms;
  cursor: pointer;
  overflow: hidden;

  & > svg {
    font-size: 18pt;
    color: ${props => props.theme.colors.contentMedium};
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${props => props.theme.colors.white};
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: 150ms;
  }
`

const Main = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
`
const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 0 28px;
  height: 100%;
  flex: 1;
  border-radius: 28px;
`

const Title = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    color: ${props => props.theme.colors.contentMedium};
    margin-right: 4px;
    font-size: 11pt;
  }

  & > p {
    margin: 0;
    font-size: 10pt;
    color: ${props => props.theme.colors.contentMedium};
    font-weight: 600;
  }
`

const Wrapper = styled(WrapperPartial)`
  &:hover,
  &:active {
    ${Box} {
      /* box-shadow: ${props => props.theme.styles.boxShadowHover};
      transition: box-shadow 250ms; */
    }
  }

  &[data-discovering='true'] {
    ${Main} {
      opacity: 0;
    }
  }

  ${Item} {
    &[data-active='true'] {
      background-color: ${props => rgba(props.theme.colors.middle, 0.1)};
      border: 1px solid ${props => rgba(props.theme.colors.middle, 0.9)};
      cursor: default;

      & > ${Title} > p, & > ${Title} > svg {
        color: ${props => rgba(props.theme.colors.middle, 0.9)};
      }
      ${props =>
        props.theme.isDark &&
        css`
          border: 1px solid transparent;
          background: ${props => props.theme.gradients.primary};
          & > ${Title} > p,
          & > ${Title} > svg {
            color: ${props => props.theme.colors.whiteAbsolute};
          }
        `}
    }
    &:not([data-active='true']) {
      :hover,
      &:active {
        & > ${Title}> p {
          color: ${props => props.theme.colors.dark};
        }
      }
    }
  }

  ${props => props.theme.medias.medium} {
    ${Box} {
      padding: 0;
      border: 0;
      min-width: 0;
      height: auto;

      ${Main} {
        display: none;
      }
    }
  }

  &[data-item="true"]{
    ${Item}{
      padding: 0 20px;
    }
  }
`

function Classificaton () {
  const { isPut, toggle } = useClassification()

  if (!features.calls) return null

  return (
    <Wrapper data-discovering={false}>
      <Box>
        <Main>
          <Item
            data-active={isPut}
            onClick={() => {
              if (!isPut) toggle(false)
            }}
          >
            <Title>
              <p>Puts</p>
            </Title>
          </Item>
          <Item
            data-active={!isPut}
            onClick={() => {
              if (isPut) toggle(true)
            }}
          >
            <Title>
              <p>Calls</p>
            </Title>
          </Item>
        </Main>
      </Box>
    </Wrapper>
  )
}

function ClassificationItem () {
  const { option, isLoading } = useOptionInfo()

  return (
    <Wrapper data-discovering={isLoading} data-item>
      <Box>
        <Main>
          <Item data-active>
            <Title>
              <IconLock />
              <p>{option && option.isCall() ? 'Call' : 'Put'}</p>
            </Title>
          </Item>
        </Main>
      </Box>
    </Wrapper>
  )
}

Classificaton.propTypes = {}

Classificaton.defaultProps = {}

export { ClassificationItem }
export default Classificaton
