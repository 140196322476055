import _ from 'lodash'
import React from 'react'
import BigNumber from 'bignumber.js'
import { pages } from '../constants'

import { toNumeralPrice, toQuantity } from '@pods-finance/utils'
import { Position as PartialPosition } from '@pods-finance/models'

const h = source => {
  const value = _.get(source, 'humanized')
  if (_.isNil(value)) return new BigNumber(0)
  return value
}

/**
 * @typedef IValue
 * @property {BigNumber} raw
 * @property {BigNumber} humanized
 */
export default class Position extends PartialPosition {
  constructor ({ value }) {
    super({ value })
  }

  /**
   * ---------------------------------------------
   * Buy Box - Ongoing, before expiration
   * ---------------------------------------------
   */

  getBoxBuyOngoing ({ market, balance, setOpen, doVisit }) {
    return PositionHelper.getBoxBuyOngoing({
      position: this,
      market,
      balance,
      setOpen,
      doVisit
    })
  }

  getBoxBuyOngoingFallback ({ doVisit }) {
    return PositionHelper.getBoxBuyOngoingFallback({
      position: this,
      doVisit
    })
  }

  /**
   * ---------------------------------------------
   * Sell Box - Ongoing, before expiration
   * ---------------------------------------------
   */

  getBoxSellOngoing ({ tokens, setOpen, doVisit, dynamics, durations }) {
    return PositionHelper.getBoxSellOngoing({
      position: this,
      tokens,
      setOpen,
      doVisit,
      dynamics,
      durations
    })
  }

  getBoxSellOngoingFallback ({ doVisit }) {
    return PositionHelper.getBoxSellOngoingFallback({
      position: this,
      doVisit
    })
  }

  /**
   * ---------------------------------------------
   * Liquidity Box - Ongoing, before expiration
   * ---------------------------------------------
   */

  getBoxLiquidityOngoing ({ tokens, values, setOpen, doVisit }) {
    return PositionHelper.getBoxLiquidityOngoing({
      position: this,
      tokens,
      values,
      setOpen,
      doVisit
    })
  }

  getBoxLiquidityOngoingFallback ({ doVisit }) {
    return PositionHelper.getBoxLiquidityOngoingFallback({
      position: this,
      doVisit
    })
  }

  /**
   * ---------------------------------------------
   * Buy Box - Ended, after expiration
   * ---------------------------------------------
   */

  getBoxBuyExpired ({ tokens, setOpen, doVisit }) {
    return PositionHelper.getBoxBuyExpired({
      position: this,
      tokens,
      setOpen,
      doVisit
    })
  }

  getBoxBuyExpiredFallback ({ doVisit }) {
    return PositionHelper.getBoxBuyExpiredFallback({
      doVisit
    })
  }

  /**
   * ---------------------------------------------
   * Sell Box - Ended, after expiration
   * ---------------------------------------------
   */

  getBoxSellExpired ({ tokens, setOpen, doVisit, durations, dynamics }) {
    return PositionHelper.getBoxSellExpired({
      position: this,
      tokens,
      setOpen,
      doVisit,
      durations,
      dynamics
    })
  }

  getBoxSellExpiredFallback ({ doVisit }) {
    return PositionHelper.getBoxSellExpiredFallback({
      doVisit
    })
  }

  /**
   * ---------------------------------------------
   * Liquidity Box - Ended, after expiration
   * ---------------------------------------------
   */

  getBoxLiquidityExpired ({ tokens, values, setOpen, doVisit }) {
    return PositionHelper.getBoxLiquidityExpired({
      position: this,
      tokens,
      setOpen,
      doVisit
    })
  }

  getBoxLiquidityExpiredFallback ({ doVisit }) {
    return PositionHelper.getBoxLiquidityExpiredFallback({
      doVisit
    })
  }
}

export class PositionHelper {
  /**
   * ---------------------------------------------
   * Buy Box - Ongoing, before expiration
   * ---------------------------------------------
   */

  static getBoxBuyOngoingFallback ({ position, doVisit }) {
    const address = _.get(position, 'option.address')
    return {
      title: 'Options bought',
      action: 'Buy options',
      activity: null,
      onActionClick: () => {
        doVisit(pages.transactionHedge.builder(address))
      }
    }
  }

  static getBoxBuyOngoing ({ position, market, balance, setOpen, doVisit }) {
    const address = _.get(position, 'option.address')
    const strike = h(_.get(position, 'option.strikePrice'))
    const simulated = position.getSimulatedExercisePNL({
      strike,
      market,
      balance
    })

    return {
      title: 'Options bought',
      action: 'Buy more',
      activity: [
        {
          label: 'Lifetime buys',
          value: toQuantity(
            toNumeralPrice(_.get(position, 'optionsBought'), false),
            'option'
          )
        },
        {
          label: 'Available options',
          value: toQuantity(toNumeralPrice(balance, false), 'option')
        }
      ],
      total: [
        {
          label: 'Simulated profits',
          value: simulated
            ? simulated.isNegative()
              ? 'Out-of-money'
              : toNumeralPrice(simulated)
            : '-',
          isSimulated: true
        },
        {
          label: 'Total premium paid',
          value: `-${toNumeralPrice(_.get(position, 'premiumPaid'))}`
        }
      ],
      onActionClick: () => {
        doVisit(pages.transactionHedge.builder(address))
      },
      onBreakdownClick: () => {
        setOpen(
          true,
          PositionHelper.getBreakdownBuyOngoing({
            position,
            market,
            strike,
            balance,
            simulated
          })
        )
      }
    }
  }

  static getBreakdownBuyOngoing ({
    position,
    market,
    strike,
    balance,
    simulated
  }) {
    return {
      title: 'Options bought',
      sections: [
        {
          title: 'Lifetime buys',
          description: (
            <>
              Your lifetime buys show how many of these options you have
              acquired. This amount was calculated at{' '}
              <b>
                {toQuantity(
                  toNumeralPrice(_.get(position, 'optionsBought'), false),
                  'option'
                )}
              </b>
              .<br /> As this is a "volume" metric, if you were to do a cycle of
              buy - resell - buy again, the values would add everytime on top of
              this "lifetime" amount. In other words, we do not reset your
              position even if you do not hold these previously bought options
              anymore.
            </>
          )
        },
        {
          title: 'Available options',
          description: (
            <>
              The available options represent the amount of option tokens you
              currently have <b>in your wallet</b>. This amount was calculated
              at <b>{toQuantity(toNumeralPrice(balance, false), 'option')}</b>.
              If this series ends in the money, you'll be able to exercise these
              options during the 24h window.
            </>
          )
        },
        {
          title: 'Simulated profits',
          description: (
            <>
              This metric estimates the returns of your position <b>if</b> the
              option was in the expiration window right now. Since your balance
              is <b>{toQuantity(toNumeralPrice(balance, false), 'option')}</b>{' '}
              and the option is{' '}
              {!simulated || simulated.isNegative() ? (
                <>
                  out of the money (OTM) you wouldn't make a profit because the
                  strike <b>{toNumeralPrice(strike)}</b> is lower than the
                  market price.
                </>
              ) : (
                <>
                  in the money (ITM) you would make a profit of{' '}
                  <b>{toNumeralPrice(simulated)}</b> based on the difference
                  between the strike <b>{toNumeralPrice(strike)}</b> and the
                  higher market price.
                </>
              )}
              . This value does not take into account the paid premium (trading
              P&amp;L).
            </>
          )
        },
        {
          title: 'Premium paid',
          description: (
            <>
              For each buy transaction you will have to pay a premium (cost of
              the options).
            </>
          )
        }
      ]
    }
  }

  /**
   * ---------------------------------------------
   * Sell Box - Ongoing, before expiration
   * ---------------------------------------------
   */

  static getBoxSellOngoingFallback ({ position, doVisit }) {
    const address = _.get(position, 'option.address')
    return {
      title: 'Options sold',
      action: 'Sell options',
      activity: null,
      onActionClick: () => {
        doVisit(pages.transactionInvest.builder(address))
      }
    }
  }

  static getBoxSellOngoing ({
    position,
    tokens,
    setOpen,
    doVisit,
    dynamics,
    durations
  }) {
    const address = _.get(position, 'option.address')
    const strike = h(_.get(position, 'option.strikePrice'))
    const withdrawable = _.get(dynamics, 'userOptionWithdrawAmounts')

    return {
      title: 'Options sold',
      action: 'Sell more',
      activity: [
        {
          label: 'Resold options',
          value: toQuantity(
            toNumeralPrice(_.get(position, 'optionsResold'), false),
            'option'
          )
        },
        {
          label: 'Written options',
          value: toQuantity(
            toNumeralPrice(_.get(position, 'optionsSold'), false),
            'option'
          )
        }
      ],
      total: [
        _.get(durations, 'isExercising')
          ? {
            label: _.get(durations, 'isExpired')
              ? 'Withdrawable'
              : `Exit ${_.get(durations, 'exerciseStartToTodayFormatted')}`,
            value: `${toNumeralPrice(
                _.get(withdrawable, '0.humanized'),
                false
              )} ${_.get(tokens, '0.alias')} & ${toNumeralPrice(
                _.get(withdrawable, '1.humanized'),
                false
              )} ${_.get(tokens, '1.alias')}`,
            isSimulated: !_.get(durations, 'isExpired')
          }
          : {
            label: 'Withdrawable',
            value: 'View on exercise day',
            isSimulated: true
          },
        {
          label: 'Total premium received',
          value: `+${toNumeralPrice(_.get(position, 'premiumReceived'))}`
        }
      ].filter(i => i !== null),
      onActionClick: () => {
        doVisit(pages.transactionInvest.builder(address))
      },
      onBreakdownClick: () => {
        setOpen(
          true,
          PositionHelper.getBreakdownSellOngoing({
            position,
            strike,
            tokens
          })
        )
      }
    }
  }

  static getBreakdownSellOngoing ({ position, strike, tokens }) {
    const locked = _.get(position, 'optionsSold').times(strike)

    return {
      title: 'Options sold',
      sections: [
        {
          title: 'Resold options',
          description: (
            <>
              This metric represents all the options you initially bought or got
              from the pool and resold until now.
            </>
          )
        },
        {
          title: 'Written options',
          description: (
            <>
              To become an option writer, you need to lock collateral and mint
              option tokens. These will be sold to the AMM for a premium. Over
              the lifetime of this options, your volume of written options was{' '}
              <b>
                {toQuantity(
                  toNumeralPrice(_.get(position, 'optionsSold'), false),
                  'option'
                )}
              </b>{' '}
              would lock up to{' '}
              <b>
                {toNumeralPrice(locked, false)} ${_.get(tokens, '2.alias')}
              </b>
              . If you engage in a sell - buy cycle, this <b>volume</b> will
              keep increasing, even though your portfolio situation may be
              different.
            </>
          )
        },
        {
          title: 'Withdrawable/Exit in x time',
          description: (
            <>
              The withdrawable value will become available starting with the
              expiration date. This will represent the amounts of{' '}
              <b>{_.get(tokens, '0.alias')}</b> and{' '}
              <b>{_.get(tokens, '1.alias')}</b> you are entitled to based on how
              many options will be exercised by the buyers.
            </>
          )
        },
        {
          title: 'Premium received',
          description: (
            <>
              For each sell (either as a writer or as a reseller) you will
              receive a premium.
            </>
          )
        }
      ]
    }
  }

  /**
   * ---------------------------------------------
   * Liquidity Box - Ongoing, before expiration
   * ---------------------------------------------
   */

  static getBoxLiquidityOngoingFallback ({ position, doVisit }) {
    const address = _.get(position, 'option.address')
    return {
      title: 'Liquidity provided',
      action: 'Manage',
      activity: null,
      onActionClick: () => {
        doVisit(pages.transactionPool.builder(address))
      }
    }
  }

  static getBoxLiquidityOngoing ({
    position,
    values,
    tokens,
    setOpen,
    doVisit
  }) {
    const address = _.get(position, 'option.address')

    return {
      title: 'Liquidity provided',
      action: 'Manage',
      activity: [
        {
          label: 'Your deposits',
          value: `${toNumeralPrice(
            _.get(position, 'initialOptionsProvided'),
            false
          )} op. & ${toNumeralPrice(
            _.get(position, 'initialTokensProvided'),
            false
          )} ${_.get(tokens, '2.alias')}`
        },
        {
          label: 'Your removals',
          value: `${toNumeralPrice(
            _.get(position, 'finalOptionsRemoved'),
            false
          )} op. & ${toNumeralPrice(
            _.get(position, 'finalTokensRemoved'),
            false
          )} ${_.get(tokens, '2.alias')}`
        }
      ],
      total: [
        {
          label: 'Position',
          value: `${toNumeralPrice(
            _.get(values, 'poolPositions.0.humanized'),
            false
          )} op. & ${toNumeralPrice(
            _.get(values, 'poolPositions.1.humanized'),
            false
          )} ${_.get(tokens, '2.alias')}`
        },
        {
          label: 'Position value',
          value: `${toNumeralPrice(values.poolPositionsValue, false)} ${_.get(
            tokens,
            '2.alias'
          )}`,
          isSimulated: true
        }
      ],
      onActionClick: () => {
        doVisit(pages.transactionPool.builder(address))
      },
      onBreakdownClick: () => {
        setOpen(
          true,
          PositionHelper.getBreakdownLiquidityOngoing({
            tokens
          })
        )
      }
    }
  }

  static getBreakdownLiquidityOngoing ({ tokens }) {
    return {
      title: 'Liquidity provided',
      sections: [
        {
          title: 'Your deposits (volume)',
          description: (
            <>
              Each time you deposit into the pools, this volume will increase.
              If after the initial deposits, you notice a difference between
              that amount and the actual pool position, you may have experienced
              impermanent loss or gain.
            </>
          )
        },
        {
          title: 'Your removals (volume)',
          description: (
            <>
              Every time you remove liquidity, you will get both option tokens
              and stablecoins. If you remove before expiration, it is your duty
              to <b>unmint</b> option tokens to unlock collateral from them.
              <br />
              <ul>
                <li>
                  {' '}
                  If the window hasn't started yet, you can resell any option
                  surplus or{' '}
                  <b style={{ textDecoration: 'underline' }}>
                    repurchase missing options to fully exit a position
                  </b>
                  .
                </li>
                <li>
                  If you find yourself during the exercise window with any
                  surplus that you might have received from the AMM, you can
                  exercise it{' '}
                  <i>(not recommended if the option is out-of-the-money)</i>.
                </li>
                <li>
                  If you remove after expiration, the stablecoin side will be
                  made available through the <b>remove liquidity</b>{' '}
                  transaction, while the collateral locked on the option tokens
                  side will be available in the <b>withdraw</b> tab (sell page).
                </li>
              </ul>
            </>
          )
        },
        {
          title: 'Position',
          description: (
            <>
              The actual amounts you hold in each side of the pool. To convert
              it all back to <b>{_.get(tokens, '2.alias')}</b> you will need to
              remove liquidity, unmint the options to unlock collateral and sell
              any surplus of either options or stables to the AMM.
              <br />
              <br />
              Why surplus? After trades happen in the AMM, your position will be
              rebalanced accordingly. This may lead to you being able to remove
              more options than were initially allocated (when providing
              liquidity) or more stablecoins. You can resell this options
              surplus to the AMM, before expiration or in the case of
              stablecoins, use the surplus to repurchase missing options.
            </>
          )
        },
        {
          title: 'Position value',
          description: (
            <>
              The current value of your position in the pool, denominated in{' '}
              <b>{_.get(tokens, '2.alias')}</b>. To calculate it, we use an
              n-Dimensional differential transformation method... lol jk, but it
              is still quite complex so bear with us. <br />
              <br />
              Compared to your initial position, you may see an imbalance in the
              amounts of options and stables you can remove. This is due to the
              AMM managing liquidity to fill trades. To calculate your position
              in USD we sum the balance of <b>+ stables</b> with the value
              locked in the <b>+ unmintable option tokens</b> you automatically
              created when depositing in the pool.
              <br />
              <b>Case 1, surplus of options:</b> if there's any <b>+ surplus</b>{' '}
              left (extra options) we check for how much you could resell them.
              The amount you will earn through resell should be matching more or
              less the amount you're missing from the stable side (compared to
              your initial liquidity deposits). Recap:
              <br />
              <br />
              <code style={{ backgroundColor: '#e5e5e5', color: 'red' }}>
                position = stables + unmintable_options * strike +
                resell_price(surplus_options)
              </code>
              <br />
              <br />
              <b>Case 2, shortage of options:</b> if there's a shortage of
              options, we check how much you should <b>- pay</b> to buy them
              back, so you can rebalance your position. This amount should match
              more or less the extra value you will observe in the stable side
              (compared to your initial liquidity deposits, a surplus of
              stables). Once you buy those back to fix the <b>+ shortage</b>,
              you will unmint everything and extract the locked collateral.
              Recap:
              <br />
              <br />
              <code style={{ backgroundColor: '#e5e5e5', color: 'red' }}>
                position = stables + removed_options * strike -
                rebuy_price(shortage_options) + shortage_options * strike
              </code>
              <br />
              <br />
              Note: if we don't find a price for your surplus or shortage of
              options (due to the pool having low liquidity for trades of that
              size) we don't include this rebalance in the calculations.
              <br />
              Note: these calculations are for options pre-exercise.
              <br />
              Note: for <b>calls</b>, instead of multiplying with the strike, we
              use the market price of the collateral asset.
              <br />
              <b>Note: If you read all this you're definitely gmi.</b>
            </>
          )
        }
      ]
    }
  }

  /**
   * ---------------------------------------------
   * Buy Box - Ended, after expiration
   * ---------------------------------------------
   */

  static getBoxBuyExpiredFallback ({ doVisit }) {
    return {
      title: 'Options bought',
      action: 'See more',
      activity: null,
      onActionClick: () => {
        doVisit(pages.hedge.builder())
      }
    }
  }

  static getBoxBuyExpired ({ position, tokens, setOpen, doVisit }) {
    const address = _.get(position, 'option.address')
    const strike = h(_.get(position, 'option.strikePrice'))

    return {
      title: 'Options bought',
      action: 'Buy page',
      activity: [
        {
          label: 'Lifetime buys',
          value: toQuantity(
            toNumeralPrice(_.get(position, 'optionsBought'), false),
            'option'
          )
        },
        {
          label: 'Exercised options',
          value: toQuantity(
            toNumeralPrice(_.get(position, 'optionsExercised'), false),
            'option'
          )
        }
      ],
      total: [
        {
          label: 'Collateral received (post ex.)',
          value: `${toNumeralPrice(
            _.get(position, 'optionsExercised').times(strike),
            false
          )} ${_.get(tokens, '1.alias')}`
        },
        {
          label: 'Total premium paid',
          value: `-${toNumeralPrice(_.get(position, 'premiumPaid'))}`
        }
      ],
      onActionClick: () => {
        doVisit(pages.transactionHedge.builder(address))
      },
      onBreakdownClick: () => {
        setOpen(
          true,
          PositionHelper.getBreakdownBuyExpired({
            position,
            strike,
            tokens
          })
        )
      }
    }
  }

  static getBreakdownBuyExpired ({ position, strike, tokens }) {
    const durations = _.attempt(() => _.get(position, 'option').getDurations())

    return {
      title: 'Options bought',
      sections: [
        {
          title: 'Lifetime buys',
          description: (
            <>
              Your lifetime buys show how many of these options you have
              acquired. This amount was calculated at{' '}
              <b>
                {toQuantity(
                  toNumeralPrice(_.get(position, 'optionsBought'), false),
                  'option'
                )}
              </b>
              .<br /> As this is a "volume" metric, if you were to do a cycle of
              buy - resell - buy again, the values would add everytime on top of
              this "lifetime" amount.
            </>
          )
        },
        {
          title: 'Exercised options',
          description: (
            <>
              During the exercise window on{' '}
              {_.get(durations, 'exerciseStartFormattedWithHour')} you've
              exercised{' '}
              <b>
                {toQuantity(
                  toNumeralPrice(_.get(position, 'optionsExercised'), false),
                  'option'
                )}
              </b>
              . This means that you've swapped{' '}
              <b>
                {toNumeralPrice(_.get(position, 'optionsExercised'), false)}{' '}
                {_.get(tokens, '0.alias')}
              </b>{' '}
              for{' '}
              <b>
                {toNumeralPrice(
                  _.get(position, 'optionsExercised').times(strike),
                  false
                )}{' '}
                {_.get(tokens, '1.alias')}
              </b>{' '}
              at a strike price of <b>{toNumeralPrice(strike)}</b>.
            </>
          )
        },
        {
          title: 'Collateral received (post exercise)',
          description: (
            <>
              To exercise options, you will send option tokens and an amount of
              underlying asset (<b>{_.get(tokens, '0.alias')}</b>) to receive
              the collateral (<b>{_.get(tokens, '1.alias')}</b>) in return.
            </>
          )
        },
        {
          title: 'Premium paid',
          description: (
            <>
              For each buy transaction you will have to pay a premium (cost of
              the options).
            </>
          )
        }
      ]
    }
  }

  /**
   * ---------------------------------------------
   * Sell Box - Ended, after expiration
   * ---------------------------------------------
   */

  static getBoxSellExpiredFallback ({ doVisit }) {
    return {
      title: 'Options sold',
      action: 'See more',
      activity: null,
      onActionClick: () => {
        doVisit(pages.invest.builder())
      }
    }
  }

  static getBoxSellExpired ({ position, tokens, setOpen, doVisit, dynamics }) {
    /**
     * Dynamics only available for dashboard options
     */
    const address = _.get(position, 'option.address')
    const strike = h(_.get(position, 'option.strikePrice'))
    const withdrawn = _.get(position, 'underlyingWithdrawn').plus(
      _.get(position, 'strikeWithdrawn')
    )

    const withdrawable =
      dynamics && _.has(dynamics, 'userOptionWithdrawAmounts.0.humanized')
        ? _.get(dynamics, 'userOptionWithdrawAmounts.0.humanized').plus(
          _.get(dynamics, 'userOptionWithdrawAmounts.1.humanized')
        )
        : new BigNumber(0)

    const valueForBlindWithdrawn = _.get(
      position,
      'initialOptionsProvided'
    ).isZero()
      ? 'No written options'
      : 'Visit "Withdraw" to check'

    return {
      title: 'Options sold',
      action: 'Sell page',
      support: 'Withdraw',
      activity: [
        {
          label: 'Resold options',
          value: toQuantity(
            toNumeralPrice(_.get(position, 'optionsResold'), false),
            'option'
          )
        },
        {
          label: 'Written options',
          value: toQuantity(
            toNumeralPrice(_.get(position, 'optionsSold'), false),
            'option'
          )
        }
      ],
      total: [
        _.get(position, 'optionsSold').isZero() &&
        _.get(position, 'underlyingWithdrawn').isZero() &&
        _.get(position, 'strikeWithdrawn').isZero() &&
        withdrawable.isZero()
          ? {
            label: 'Withdrawn',
            value: valueForBlindWithdrawn,
            isSimulated: true
          }
          : {
            label: 'Withdrawn',
            value: withdrawn.isZero()
              ? 'You have funds here!'
              : `${toNumeralPrice(
                    _.get(position, 'underlyingWithdrawn'),
                    false
                  )} ${_.get(tokens, '0.alias')} & ${toNumeralPrice(
                    _.get(position, 'strikeWithdrawn'),
                    false
                  )} ${_.get(tokens, '1.alias')} `,
            isWarned: withdrawn.isZero()
          },
        {
          label: 'Total premium received',
          value: `+${toNumeralPrice(_.get(position, 'premiumReceived'))}`
        }
      ].filter(i => i !== null),
      onActionClick: () => {
        doVisit(pages.transactionInvest.builder(address))
      },
      onSupportClick: () => {
        doVisit(pages.transactionInvest.builder(address))
      },
      onBreakdownClick: () => {
        setOpen(
          true,
          PositionHelper.getBreakdownSellExpired({
            position,
            strike,
            tokens
          })
        )
      }
    }
  }

  static getBreakdownSellExpired ({ position, strike, tokens }) {
    const locked = _.get(position, 'optionsSold').times(strike)

    return {
      title: 'Options sold',
      sections: [
        {
          title: 'Withdrawn (funds)',
          description: (
            <>
              When you offer to write options, you take on the risk of getting
              exercised. This means the buyers can exercise their options,
              swapping the underlying asset (<b>{_.get(tokens, '0.alias')}</b>)
              with your collateral (<b>{_.get(tokens, '1.alias')}</b>) at a
              fixed strike price (<b>{toNumeralPrice(strike)}</b>). <br />
              <br />
              From the same tab, you'll also be able to withdraw collateral that
              was locked when providing liquidity.
            </>
          )
        },
        {
          title: 'Resold options',
          description: (
            <>You can buy options and resell them later for a profit.</>
          )
        },
        {
          title: 'Written options',
          description: (
            <>
              To become an option writer, you need to lock collateral and mint
              option tokens. These will be sold to the AMM for a premium. Over
              the lifetime of this options, your volume of written options was{' '}
              <b>
                {toQuantity(
                  toNumeralPrice(_.get(position, 'optionsSold'), false),
                  'option'
                )}
              </b>{' '}
              would lock up to{' '}
              <b>
                {toNumeralPrice(locked, false)} ${_.get(tokens, '2.alias')}
              </b>
              . If you engage in a sell - buy cycle, this <b>volume</b> will
              keep increasing, even though your portfolio situation may be
              different.
            </>
          )
        },
        {
          title: 'Premium received',
          description: (
            <>
              For each sell (either as a writer or as a reseller), you will
              receive a premium.
            </>
          )
        }
      ]
    }
  }

  /**
   * ---------------------------------------------
   * Liquidity Box - Ended, after expiration
   * ---------------------------------------------
   */

  static getBoxLiquidityExpiredFallback ({ doVisit }) {
    return {
      title: 'Liquidity provided',
      action: 'See more',
      activity: null,
      onActionClick: () => {
        doVisit(pages.pool.builder())
      }
    }
  }

  static getBoxLiquidityExpired ({ position, tokens, setOpen, doVisit }) {
    const address = _.get(position, 'option.address')

    return {
      title: 'Liquidity provided',
      action: 'Remove',
      support: 'Withdraw',
      activity: [
        {
          label: 'Your deposits',
          value: `${toNumeralPrice(
            _.get(position, 'initialOptionsProvided'),
            false
          )} op. & ${toNumeralPrice(
            _.get(position, 'initialTokensProvided'),
            false
          )} ${_.get(tokens, '2.alias')}`
        },
        {
          label: 'Your removals',
          value: `${toNumeralPrice(
            _.get(position, 'finalOptionsRemoved'),
            false
          )} op. & ${toNumeralPrice(
            _.get(position, 'finalTokensRemoved'),
            false
          )} ${_.get(tokens, '2.alias')}`
        }
      ],
      total: [
        {
          label: 'Locked assets',
          value: `${toNumeralPrice(
            _.get(position, 'remainingOptionsProvided'),
            false
          )} op. & ${toNumeralPrice(
            _.get(position, 'remainingTokensProvided'),
            false
          )} ${_.get(tokens, '2.alias')}`
        },
        {
          label: 'Manually unminted',
          value: `${toNumeralPrice(
            _.get(position, 'optionsUnminted').times(
              _.get(position, 'option.strikePrice.humanized')
            ),
            false
          )} ${_.get(tokens, '2.alias')}`
        }
      ],
      onActionClick: () => {
        doVisit(pages.transactionPool.builder(address))
      },
      onSupportClick: () => {
        doVisit(pages.transactionInvest.builder(address))
      },
      onBreakdownClick: () => {
        setOpen(true, PositionHelper.getBreakdownLiquidityExpired())
      }
    }
  }

  static getBreakdownLiquidityExpired () {
    return {
      title: 'Liquidity provided',
      sections: [
        {
          title: 'Your deposits (volume)',
          description: (
            <>
              Every time you deposit into the pools, this volume will increase.
              If after the initial deposits, you notice a difference between
              that amount and the actual pool position, you may have experienced
              impermanent loss or gain.
            </>
          )
        },
        {
          title: 'Your removals (volume)',
          description: (
            <>
              Every time you remove liquidity, you will get both option tokens
              and stablecoins. If you remove before expiration, it is your duty
              to <b>unmint</b> option tokens to unlock collateral from them.
              <br />
              <ul>
                <li>
                  {' '}
                  If the window hasn't started yet, you can resell any option
                  surplus or{' '}
                  <b style={{ textDecoration: 'underline' }}>
                    repurchase missing options to fully exit a position
                  </b>
                  .
                </li>
                <li>
                  If you find yourself during the exercise window with any
                  surplus that you might have received from the AMM, you can
                  exercise it{' '}
                  <i>(not recommended if the option is out-of-the-money)</i>.
                </li>
                <li>
                  If you remove after expiration, the stablecoin side will be
                  made available through the <b>remove liquidity</b>{' '}
                  transaction, while the collateral locked on the option tokens
                  side will be available in the <b>withdraw</b> tab (sell page).
                </li>
              </ul>
            </>
          )
        },
        {
          title: 'Locked assets',
          description: (
            <>
              These values showcase the amounts of assets still locked in the
              pools. You need to manually remove liquidity to get the stablecoin
              side back from the pool. On the option side,{' '}
              <b>
                the system automatically unlocks the collateral used to mint
                options.
              </b>{' '}
              To get that back, visit the Withdraw page in the Sell tab. <br />{' '}
              <br />
              Note: The option tokens you get back from removing liquidity will
              lose their value post-expiration.
            </>
          )
        },
        {
          title: 'Manually unminted (volume)',
          description: (
            <>
              To exit a position (before expiry) you usually have to unmint the
              unused options and unlock collateral from them. This volume
              represents the amount of collateral manually unlocked from
              options.
            </>
          )
        }
      ]
    }
  }

  /**
   * ---------------------------------------------
   * ---------------------------------------------
   *
   *  Generic Breakdowns
   *
   * ---------------------------------------------
   * ---------------------------------------------
   */

  static getBreakdownPNL ({ pnl }) {
    return {
      title: 'Full Breakdown: Your trading P&L',
      sections: [
        {
          title: 'How do we calculate your P&L?',
          description: (
            <>
              Your trading P&amp;L was calculated based on the premiums. You
              earn premium when you write/resell options and you have to pay
              premium when you buy options.
            </>
          )
        },
        {
          title: 'What is your trading P&L?',
          description: (
            <>
              Based only on the active options tracked by the app, your trading
              P&amp;L was calculated at <b>{toNumeralPrice(pnl)}</b>.
            </>
          )
        }
      ]
    }
  }

  static getBreakdownPoolPositionsValue ({ poolPositionsValue }) {
    return {
      title: 'Full Breakdown: Your pool positions value',
      sections: [
        {
          title: 'What is your liquidity?',
          description: (
            <>
              As a liquidity provider, you will deposit stablecoins into the
              AMM. The protocol converts these into balanced amounts of options
              and stables, used for trading. Your liquidity shown here is the
              sum of assets you currently have in the pools. If the value is
              different than the amount you've deposited initially, it means you
              may have suffered from impermanent loss/gain.
            </>
          )
        },
        {
          title: 'How much liquidity do you have in pools?',
          description: (
            <>
              Based only on the active pool positions tracked by the app, your
              total amount was calculated at{' '}
              <b>{toNumeralPrice(poolPositionsValue)}</b>.
            </>
          )
        },
        {
          title: 'How do you calculate the $ value of a pool position?',
          description: (
            <>
              To calculate it, we sum the <code>balances of tokenB</code>{' '}
              (stablecoins) with the <code>value</code> of the balance of tokenA
              in the pool. For the latter, we look at how many options you can
              unmint and convert those based on the option strike price. If
              there's any <code>surplus</code> left, we'll check for how much we
              could resell that (slippage and fees not taken into account).
              <br />
              <br />
              Reminder: when you add liquidity, the protocol will split your{' '}
              <b>tokenB</b> into a combination of options and stablecoins for
              the AMM to trade.
            </>
          )
        }
      ]
    }
  }
}
