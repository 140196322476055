import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { Button, ExternalLink } from '@pods-finance/components'
import { ethers } from 'ethers'
import Helmet from '../../components/specific/Helmet'
import { Structure, Section, ToolbarBase } from '../../components/common'
import {
  useAccount,
  useWalletModal,
  useWeb3Signer,
  useToken,
  useAddresses,
  useWalletChainRequest
} from '../../hooks'
import { networks, tokens, etherscaned } from '../../constants'
import { formatChainForMetamask } from '@pods-finance/utils'

import { ReactComponent as LogoPolygon } from '../../assets/images/polygon_logo.svg'

import IconMain from '@material-ui/icons/PublicRounded'

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: auto;
  min-width: 1000px;
  grid-gap: 30px;
  padding: 0 ${props => props.theme.sizes.layoutEdge};
  ${props => props.theme.medias.large} {
    min-width: 600px;
    grid-template-columns: repeat(2, 1fr);
  }
  ${props => props.theme.medias.medium} {
    width: 100%;
    min-width: auto;
    grid-template-columns: repeat(1, 1fr);
  }
`

const Card = styled.div`
  grid-column: span 1;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.border};
  padding: 30px 20px 20px;
  text-align: center;
  min-height: 220px;
  height: 100%;

  .wrong-network {
    color: white;
    background-color: ${props => props.theme.colors.red};
    border-radius: 8px;
    padding: 16px;
    line-height: 1.5rem;
    font-size: 10pt;
    font-weight: 600;
  }
`
const Please = styled.p`
  color: ${props => props.theme.colors.dark};
`

const MintGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${props => props.elements || 6}, 1fr);
  grid-gap: 5px;
  margin-bottom: auto;
  div {
    grid-column: span 1;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 40px;
      height: 40px;
      border: 1px solid transparent;
      border-radius: 100%;
    }
  }
`

const EthLink = styled.p`
  font-size: 9pt;
  font-weight: 600;
  color: ${props => props.theme.colors.middle};
  margin-top: 20px;
  & > a {
    text-decoration: underline;
  }
`
const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ChainLogo = styled.div`
  height: 40px;
  width: auto;
  margin: 0 auto;
  margin-bottom: auto;
  & > svg {
    width: 180px;
    height: 100%;
    ${props =>
      props.theme.isDark &&
      css`
        g.polygonlogo > path:not(.polygonicon) {
          fill: ${props => props.theme.colors.whiteAbsolute};
        }
      `}
  }
`

const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    position: absolute;
    font-size: 14pt;
  }
`

const Info = styled.p`
  font-size: 9pt;
  font-weight: 600;
  color: ${props =>
    props.theme.isDark
      ? props.theme.colors.whiteAbsolute
      : props.theme.colors.grayBlueDark};
  margin-top: 20px;
`

const faucetABI = [
  {
    inputs: [],
    name: 'getFaucet',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }
]

const pointToFaucet = (networkId = networks.kovan) =>
  `${_.get(networks, `_data[${networkId}].faucet`)}`

const pointToNetworkName = (networkId = networks.kovan) =>
  `${_.get(networks, `_data[${networkId}].name`)}`

function Utils () {
  const { connect } = useWalletModal()
  const { isConnected, networkId } = useAccount()
  const signer = useWeb3Signer()
  const [isLoading, setIsLoading] = useState(false)
  const request = useWalletChainRequest()
  const [txMsg, setTxMsg] = useState('')
  const addresses = useAddresses()
  const faucetAddress = useMemo(() => _.get(addresses, 'faucet'), [addresses])
  const explorerTx = useMemo(() => etherscaned(txMsg, networkId), [
    txMsg,
    networkId
  ])
  const faucetLink = useMemo(() => pointToFaucet(networkId), [networkId])
  const networkName = useMemo(() => pointToNetworkName(networkId), [networkId])

  const isFaucetAvailable = useMemo(
    () => [networks.kovan].includes(networkId),
    [networkId]
  )

  const { value: list } = useToken([
    tokens.keys.WBTC,
    tokens.keys.LINK,
    tokens.keys.ADAI,
    tokens.keys.AUSDC,
    tokens.keys.DAI,
    tokens.keys.USDC
  ])

  const formattedList = useMemo(() => {
    if (_.isNil(list) && !list.length) return []
    return list.filter(item => !_.isNil(item))
  }, [list])

  const onSubmit = async event => {
    event.preventDefault()

    if (!isConnected) {
      connect()
    } else {
      try {
        if (_.isNil(signer)) return
        const faucet = new ethers.Contract(faucetAddress, faucetABI, signer)
        setIsLoading(true)
        const gasLimit = (await faucet.estimateGas.getFaucet())
          .mul(120)
          .div(100)
          .toString()
        const transaction = await faucet.getFaucet({ gasLimit })
        const receipt = await transaction.wait()
        setTxMsg(receipt.transactionHash)
      } catch (e) {
        console.error('Faucet', e)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const faucetMsg = () => {
    return `${networkName} ETH`
  }

  return (
    <Structure>
      <Helmet.Utils />
      <ToolbarBase />
      <Section
        title='Utilities'
        description="Get your toolkit to play with Pods on testnet. We're currently supporting Kovan."
      >
        <Content>
          {!isConnected && (
            <Card>
              <Please>
                Please <b onClick={() => connect()}>connect your wallet</b>{' '}
                first.
              </Please>
            </Card>
          )}
          {isConnected && (
            <Card>
              <CardWrapper>
                <MintGrid elements={formattedList.length}>
                  {formattedList.map(token => (
                    <div key={token.symbol}>
                      <img src={token.icon} alt={token.symbol} />
                    </div>
                  ))}
                </MintGrid>

                {!isFaucetAvailable ? (
                  <p className='wrong-network'>
                    Wrong Network! Please switch to Kovan.
                  </p>
                ) : (
                  <>
                    {txMsg === '' ? (
                      <Button
                        title='Get funds to your wallet'
                        appearance={a => a.gradient}
                        accent={a => a.primary}
                        type={t => t.button}
                        onClick={onSubmit}
                        isLoading={isLoading}
                        isFullWidth
                      />
                    ) : (
                      <Button
                        title='View transaction on Explorer'
                        appearance={a => a.outline}
                        accent={a => a.dark}
                        type={t => t.external}
                        to={explorerTx}
                        isFullWidth
                      />
                    )}
                  </>
                )}

                {isFaucetAvailable && (
                  <EthLink>
                    For {faucetMsg(networkId)}, please use{' '}
                    <ExternalLink href={faucetLink}>the faucet</ExternalLink>
                  </EthLink>
                )}
              </CardWrapper>
            </Card>
          )}
          <Card>
            <CardWrapper>
              <ChainLogo>
                <LogoPolygon />
              </ChainLogo>
              <Button
                title='Add Matic to Metamask'
                appearance={a => a.outline}
                accent={(a, d) => (d ? a.whiteAbsolute : a.grayBlueDark)}
                type={t => t.button}
                onClick={() => {
                  const c = networks._data[networks.matic]
                  request(formatChainForMetamask(c))
                }}
                isDisabled={!isConnected}
                isFullWidth
                childrenLeft={
                  <Left>
                    <IconMain />
                  </Left>
                }
              />
              <Info>Add support for Matic (Polygon's Mainnet)</Info>
            </CardWrapper>
          </Card>
        </Content>
      </Section>
    </Structure>
  )
}

export default Utils
