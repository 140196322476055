import _ from 'lodash'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import globals from '@pods-finance/globals'
import { ExternalLink } from '@pods-finance/components'

import { ReactComponent as AssetTwitter } from '../../../../assets/social/twitter_white.svg'
import { ReactComponent as AssetDiscord } from '../../../../assets/social/discord_white.svg'
import { ReactComponent as AssetGitbook } from '../../../../assets/social/gitbook_white.svg'
import { ReactComponent as AssetMedium } from '../../../../assets/social/medium_white.svg'
import { ReactComponent as AssetGithub } from '../../../../assets/social/github_white.svg'
import { pages, networks } from '../../../../constants'

import { useNetworkId } from '../../../../hooks'

const WrapperPartial = styled.div`
  width: 100%;
`

const Box = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: calc(${props => props.theme.sizes.edge} * 1 / 2);
  width: 100%;
  border-radius: ${props => props.theme.sizes.edge};
  padding: calc(${props => props.theme.sizes.edge} * 1 / 2);
  background-color: ${props => props.theme.colors.platform};
  border: 1px solid ${props => props.theme.colors.border};
`

const ItemPartial = styled(ExternalLink)`
  grid-column: span 1;
  grid-row: span 1;
`

const Square = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  cursor: pointer;
`

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.white};
  border-radius: 10px;
  z-index: 100;
  box-shadow: none;
  transition: box-shadow 150ms;
  & > svg {
    height: 20px;
    width: 20px;
    object-fit: contain;
    fill: ${props => props.theme.colors.content};
    transition: fill 150ms;
  }

  ${props =>
    props.hasAccent === true &&
    css`
      & > svg {
        fill: ${props => props.theme.colors.dark};
      }
    `}
`

const Item = styled(ItemPartial)`
  &:hover,
  &:active {
    ${Inner} {
      box-shadow: ${props => props.theme.styles.boxShadowHover};
      transition: box-shadow 250ms;
      & > svg {
        fill: ${props => props.theme.colors.dark};
        transition: fill 250ms;
      }
    }
  }
`

const Title = styled.p`
  font-size: 11pt;
  font-weight: 600;
  color: ${props => props.theme.colors.contentMedium};
  margin: 0;
  display: none;
`

const Wrapper = styled(WrapperPartial)`
  ${props => props.theme.medias.medium} {
    margin: 0;
    border-top: 1px solid ${props => props.theme.colors.border};
    padding-top: ${props => props.theme.sizes.edge};
    padding-bottom: ${props => props.theme.sizes.edge};

    & > ${Box} {
      grid-template-columns: 1fr;
      grid-gap: calc(${props => props.theme.sizes.edge} * 2);
      border: none;
      background-color: transparent;

      & > ${Item} {
        width: 100%;
        padding: 0 ${props => props.theme.sizes.edge};
        position: relative;
        & > ${Square} {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: auto;
          padding: 0;

          & > ${Inner} {
            position: relative;
            width: auto;
            margin-right: ${props => props.theme.sizes.edge};
            & > svg {
              height: 20px;
              width: 20px;
            }
          }
          & > ${Title} {
            display: flex;
            flex: 1;
          }
        }

        &:hover,
        &:active {
          & > ${Square} > ${Title} {
            color: ${props => props.theme.colors.dark};
          }
        }
      }
    }
  }

  & > ${Box} {
    &[data-faucet-supported='true'] {
      & > ${Item} {
        &:last-child {
          & > ${Square} > ${Inner} > svg {
            fill: ${props => props.theme.colors.dark} !important;
          }
        }
      }
    }
  }
`

const list = [
  {
    title: 'Discord',
    Icon: AssetDiscord,
    link: globals.links.discord.primary.link
  },
  {
    title: 'Twitter',
    Icon: AssetTwitter,
    link: globals.links.twitter.primary.link
  },
  {
    title: 'Github',
    Icon: AssetGithub,
    link: globals.links.github.primary.link
  },
  {
    title: 'Docs',
    Icon: AssetGitbook,
    link: globals.links.gitbook.primary.link
  },
  {
    title: 'Medium',
    Icon: AssetMedium,
    link: globals.links.medium.primary.link
  },
  {
    title: 'Utilities',
    Icon: pages.utils.Icon,
    link: pages.utils.route,
    target: '_self'
  }
]

function Social () {
  const networkId = useNetworkId()
  const isFaucetSupported = useMemo(
    () => !_.isNil(networkId) && [networks.kovan].includes(networkId),
    [networkId]
  )

  return (
    <Wrapper>
      <Box data-faucet-supported={isFaucetSupported}>
        {list.map(element => (
          <Item
            target={element.target || '_blank'}
            to={element.link}
            key={element.title}
            as={element.title === 'Utilities' ? Link : ExternalLink}
          >
            <Square>
              <Inner title={element.title} hasAccent={element.accent}>
                <element.Icon />
              </Inner>
              <Title>{element.title}</Title>
            </Square>
          </Item>
        ))}
      </Box>
    </Wrapper>
  )
}

export default Social
