import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import Loader from '../SupportLoader'

const Wrapper = styled.div`
  width: 100%;
  position: relatve;
  padding-top: ${props => props.theme.sizes.edge};
  &[data-active='true'] {
    display: block;
  }
  &[data-active='false'] {
    display: none;
  }
`

function ContentBox ({ hash, children, force, isLoading }) {
  const location = useLocation()

  const isActive = useMemo(() => {
    if (force) return true
    if (_.get(location, 'hash') === `#${hash}`) return true
    if (
      _.isArray(hash) &&
      hash.some(item => item && _.get(location, 'hash') === `#${item}`)
    ) { return true }
    return false
  }, [location, hash, force])

  return (
    <Wrapper data-active={isActive}>
      <Loader isActive={isLoading} />
      {children}
    </Wrapper>
  )
}

ContentBox.propTypes = {
  force: PropTypes.bool,
  isLoading: PropTypes.bool
}

ContentBox.defaultProps = {
  force: false,
  isLoading: true
}

export default ContentBox
