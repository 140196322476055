import { useAtomicReducer } from '@pods-finance/hooks'

export const initial = {
  data: {
    value: null,
    warning: null,
    isLoading: false
  },
  balance: {
    value: null,
    warning: null,
    isLoading: false
  },
  allowances: {
    value: null,
    warning: null,
    isLoading: false
  }
}

export const example = {
  data: {
    value: {
      /** SDK.Token */
      address: '0x',
      symbol: 'WETH',
      decimals: 18,
      networkId: 1
    },
    warning: null,
    isLoading: null
  },
  balance: {
    value: 0 /** Keep values humanized */,
    warning: null,
    isLoading: false
  },
  allowances: {
    value: {
      '0x1': 0,
      '0x2': 0
    },
    warning: null,
    isLoading: false
  }
}

export default {
  useReducer: () => useAtomicReducer(initial)
}
