import _ from 'lodash'
import { useCallback, useEffect, useState, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { usePriceContext } from '@pods-finance/contexts'
import { useOptions } from './data'
import { useWeb3Provider } from './web3'
import { macros } from '../constants'

export function useMarketPrices () {
  return usePriceContext()
}

export function useOptionBuyingPrices () {
  const provider = useWeb3Provider()

  const [state, setState] = useState({
    value: {},
    isLoading: false
  })

  const { options } = useOptions()

  const fetchPrices = useCallback(
    async (external = false) => {
      if (!external) setState(prev => ({ ...prev, isLoading: true }))
      const funcArray = options.map(async option => {
        try {
          const result = await option.pool.getBuyingPrice({
            amount: new BigNumber(1),
            provider
          })

          return { [option.address]: new BigNumber(_.get(result, 'value')) }
        } catch (error) {
          console.error(error)
        }
        return { [option.address]: macros.RESTRICTED_PREMIUM }
      })

      if (!funcArray.length && !external) {
        setState(prev => ({ ...prev, isLoading: false }))
      }

      await Promise.all(funcArray).then(result => {
        const objectified = {}
        result.forEach(pair => Object.assign(objectified, pair))
        if (!external) setState({ value: objectified, isLoading: false })
      })
    },
    [options, provider]
  )

  useEffect(() => {
    if (options && options.length && provider) {
      fetchPrices()
    }
  }, [options, provider, fetchPrices])

  return {
    list: state.value,
    fetch: fetchPrices,
    isLoading: state.isLoading
  }
}

export function useOptionSellingPrices () {
  const provider = useWeb3Provider()

  const [state, setState] = useState({
    value: {},
    isLoading: false
  })

  const { options } = useOptions()

  const fetchPrices = useCallback(
    async (external = false) => {
      if (!external) setState(prev => ({ ...prev, isLoading: true }))
      const funcArray = options.map(async option => {
        try {
          const result = await option.pool.getSellingPrice({
            amount: new BigNumber(1),
            provider
          })

          return { [option.address]: new BigNumber(_.get(result, 'value')) }
        } catch (error) {}
        return { [option.address]: macros.RESTRICTED_PREMIUM }
      })

      if (!funcArray.length && !external) {
        setState(prev => ({ ...prev, isLoading: false }))
      }

      await Promise.all(funcArray).then(result => {
        const objectified = {}
        result.forEach(pair => Object.assign(objectified, pair))
        if (!external) setState({ value: objectified, isLoading: false })
      })
    },
    [options, provider]
  )

  useEffect(() => {
    if (options && options.length && provider) {
      fetchPrices()
    }
  }, [options, provider, fetchPrices])

  return {
    list: state.value,
    fetch: fetchPrices,
    isLoading: state.isLoading
  }
}

export function useOptionABPrices () {
  const provider = useWeb3Provider()

  const [state, setState] = useState({
    value: {},
    isLoading: false
  })

  const { options } = useOptions()

  const fetchPrices = useCallback(
    async (external = false) => {
      if (!external) setState(prev => ({ ...prev, isLoading: true }))
      const funcArray = options.map(async option => {
        try {
          const result = await option.pool.getABPrice({
            provider
          })

          return { [option.address]: new BigNumber(_.get(result, 'value')) }
        } catch (error) {}
        return { [option.address]: macros.RESTRICTED_PREMIUM }
      })

      if (!funcArray.length && !external) {
        setState(prev => ({ ...prev, isLoading: false }))
      }

      await Promise.all(funcArray).then(result => {
        const objectified = {}
        result.forEach(pair => Object.assign(objectified, pair))
        if (!external) setState({ value: objectified, isLoading: false })
      })
    },
    [options, provider]
  )

  useEffect(() => {
    if (options && options.length && provider) {
      fetchPrices()
    }
  }, [options, provider, fetchPrices])

  return {
    list: state.value,
    fetch: fetchPrices,
    isLoading: state.isLoading
  }
}

function useAtomicPrice (
  checker,
  { option, amount, slippage = 1, external = false }
) {
  const provider = useWeb3Provider()

  const [state, setState] = useState({
    fees: null,
    value: null,
    isLoading: false
  })

  const checkPrice = useCallback(
    async ({
      option,
      amount = new BigNumber(1),
      slippage = 1,
      external = false
    }) => {
      if (
        !_.isNil(provider) &&
        !_.isNil(amount) &&
        amount.isFinite() &&
        !amount.isZero()
      ) {
        if (!external) setState({ value: null, isLoading: true, fees: null })
        try {
          const result = await option?.pool[checker]({ provider, amount })
          if (_.isNil(result)) {
            throw new Error('Price: failed atomic price query.')
          }
          const value = new BigNumber(_.get(result, 'value')).times(slippage)

          if (!external) {
            setState({
              ...result,
              value,
              isLoading: false
            })
          }
          return { ...result, value }
        } catch (error) {
          console.error('Price', { error })
          if (!external) setState({ value: null, isLoading: null, fees: null })
        }
      }
      return { value: null, fees: null }
    },
    [setState, checker, provider]
  )

  useEffect(() => {
    if (option) {
      checkPrice({ option, amount, slippage, external })
    }
  }, [option, amount, slippage, external, checkPrice])

  return useMemo(
    () => ({
      value: state.value,
      fees: state.fees,
      isLoading: state.isLoading,
      check: checkPrice
    }),
    [state, checkPrice]
  )
}

export function useOptionSellingPrice ({
  option,
  amount,
  slippage,
  external = false
} = {}) {
  return useAtomicPrice('getSellingPrice', {
    option,
    amount,
    slippage,
    external
  })
}

export function useOptionBuyingPrice ({
  option,
  amount,
  slippage,
  external = false
} = {}) {
  return useAtomicPrice('getBuyingPrice', {
    option,
    amount,
    slippage,
    external
  })
}

export function useOptionBuyingPriceEstimate ({
  option,
  amount,
  slippage,
  external = false
} = {}) {
  return useAtomicPrice('getBuyingEstimateForPrice', {
    option,
    amount,
    slippage,
    external
  })
}

export function useOptionABPrice ({ option, slippage, external = false } = {}) {
  return useAtomicPrice('getABPrice', {
    option,
    amount: 1,
    slippage,
    external
  })
}

export function useOptionUnitPrice ({ option, check }) {
  const [state, setState] = useState(null)
  useEffect(() => {
    ;(async () => {
      try {
        if (_.isNil(option) || _.isNil(check)) return
        const { value: result } = await check(
          option,
          new BigNumber(0.1).toNumber()
        )
        setState(result)
      } catch (e) {
        setState(null)
      }
    })()
  }, [check, option])

  return state
}

export function useImpactUpdate ({ elements, dispatch }) {
  return useCallback(
    ({ unitPrice, amount, premium }) => {
      const basis = new BigNumber(unitPrice)
        .times(new BigNumber(amount))
        .times(10)

      if (
        basis.isNaN() ||
        new BigNumber(premium).isNaN() ||
        _.isNilOrEmptyString(unitPrice) ||
        _.isNilOrEmptyString(amount) ||
        _.isNilOrEmptyString(premium)
      ) {
        dispatch([elements.impact, { value: null }])
      }

      dispatch([
        elements.impact,
        {
          value: basis.minus(new BigNumber(premium)).toNumber()
        }
      ])
    },
    [elements, dispatch]
  )
}
