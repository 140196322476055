import React from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'
import { Modal as Base } from '@pods-finance/components'

import { modals } from '../../../../constants'

const Modal = styled(Base)`
  & > div[data-component='card'] {
    max-width: 800px;
    border-radius: ${props => props.theme.sizes.edge};
  }
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  padding-bottom: 32px;
`

const Parasection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 32px;
  &:last-child {
    margin-bottom: 0;
  }
`

const Paratitle = styled.p`
  color: ${props => props.theme.colors.dark};
  font-size: 11pt;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
  margin-bottom: 12px;
  text-align: left;

  a {
    color: ${props => props.theme.colors.middle};
    font-weight: 600;
  }
`

const Paragraph = styled.p`
  color: ${props => props.theme.colors.dark};
  font-size: 11pt;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  text-align: left;

  a {
    color: ${props => props.theme.colors.middle};
    text-decoration: underline;
    font-weight: 600;
  }
  code {
    background: ${props =>
      props.theme.isDark
        ? lighten(0.05, props.theme.colors.platform)
        : props.theme.colors.platform};
  }
`

function LaggingSystem () {
  const id = modals.lag

  return (
    <Modal id={id} title='The UI may be encountering delays'>
      <Content>
        <Parasection>
          <Paratitle>
            Your activity history did not update after a transaction?
            Experiencing irregular loading times?
          </Paratitle>
          <Paragraph>
            For certain situations, we're experiencing delays from our subgraphs
            or the Matic RPCs. This translates into a gap in time between your
            transaction and the time it gets recorded by the system.
            <br />
            <br />
            In case the UI is not showing a transaction you just made or is
            stuck loading, the best solution is to reload the page after 10-15
            seconds.
          </Paragraph>
        </Parasection>
      </Content>
    </Modal>
  )
}

export default LaggingSystem
