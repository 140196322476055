import _ from 'lodash'
import React, { useMemo, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { ContentBox, ContentSection, ContentSummary } from '../../../Elements'
import {
  Label,
  Input,
  Step,
  Container as FormPartial
} from '@pods-finance/components'

import { SpecialButton, Incentive } from '../../../../../common'

import reducers from '../../../../../../reducers'
import machines from '../../../../../../machines'
import { tabs } from '../../../../../../constants'

import {
  useOptionInfo,
  useOptionDynamics,
  useOwnBalance,
  useTransactionSetup,
  useFormValidator,
  useFormAssetAllowance,
  useNetworkId
} from '../../../../../../hooks'

import logic from './logic'

const Wrapper = styled.div`
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 3 / 2);
    &:last-child {
      margin-bottom: 0;
    }
  }
`
const Form = styled(FormPartial)`
  max-width: 538px;

  div[data-step='actions'] {
    max-width: 100%;
    & > div:first-child {
      min-width: 270px;
    }
  }
`

const Info = styled.p`
  white-space: nowrap;
  min-width: 60px;
  ${props => props.theme.medias.medium} {
    white-space: normal;
    font-size: 9pt;
  }
`

const Header = styled.div`
  width: 100%;
  padding: 0 calc(${props => props.theme.sizes.edge} * 1);
`

const Title = styled.p`
  font-size: 12pt;
  font-weight: 700;
  color: ${props => props.theme.colors.middle};
  margin: 0;
`

function AddPut () {
  /**
   * --------------------------------------
   * Required data and utilities
   * --------------------------------------
   */
  const machine = machines.addPut.useMachine()
  const setup = useTransactionSetup()
  const networkId = useNetworkId()
  const { elements, state, dispatch } = reducers.addPut.useReducer()
  const dynamics = useOptionDynamics()
  const {
    option,
    helper,
    underlying,
    strike,
    durations,
    isLoading: isOptionLoading
  } = useOptionInfo()

  const {
    value: balanceStrike,
    isLoading: isBalanceStrikeLoading
  } = useOwnBalance(strike)

  const abPrice = useMemo(() => _.get(dynamics, 'abPrice.value.humanized'), [
    dynamics
  ])

  const isLoading = useMemo(
    () => isOptionLoading || isBalanceStrikeLoading || dynamics.isLoading,
    [isOptionLoading, isBalanceStrikeLoading, dynamics.isLoading]
  )

  const { isValid: isFormValid, isAllowed: isFormAllowed } = useFormValidator({
    state,
    machine
  })

  const isAvailable = useMemo(() => !_.isNil(abPrice) && !abPrice.isZero(), [
    abPrice
  ])

  /**
   * --------------------------------------
   *  Methods and state updaters
   * --------------------------------------
   */

  const doAllowanceUpdate = useCallback(
    ({ isAllowed, isLoading }) =>
      dispatch([elements.allowance, { strike: isAllowed, isLoading }]),
    [elements, dispatch]
  )

  const onChangeAmount = useCallback(
    amount =>
      logic.onChangeAmount({
        amount,
        dispatch,
        elements,
        option,
        abPrice,
        balanceStrike
      }),
    [dispatch, elements, option, abPrice, balanceStrike]
  )

  const onTransact = useCallback(() => {
    if (_.get(setup, 'guardTVL.isDisabled')) setup.guardTVL.show()
    else logic.onTransact({ machine, state, option, setup })
  }, [machine, state, option, setup])

  const {
    doApprove: doAllowanceApprove,
    doRefresh: doAllowanceRefresh
  } = useFormAssetAllowance({
    amount: _.get(state, 'strike.value'),
    token: strike,
    spender: _.get(helper, 'address'),
    onUpdate: doAllowanceUpdate
  })

  useEffect(() => {
    if (!isLoading) {
      logic.onInitialize({
        elements,
        dispatch,
        underlying,
        strike,
        balanceStrike
      })

      doAllowanceRefresh()
    }
  }, [
    elements,
    dispatch,
    isLoading,
    underlying,
    strike,
    balanceStrike,
    doAllowanceRefresh
  ])

  return (
    <ContentBox hash={tabs.pool.add} isLoading={isLoading}>
      <Wrapper>
        <Header>
          <Title>Enter a liquidity position</Title>
        </Header>
        <ContentSection
          title='Section 1: Add liquidity with one asset'
          isContained
          isDisabled={
            [machine.states.validate, machine.states.process].includes(
              machine.current.value
            ) ||
            (!isLoading && !isAvailable)
          }
        >
          <Form>
            <Step>
              <Label>Step 1. Amount to provide</Label>
              <Input.Amount
                {...state.strike}
                placeholder='How many tokens?'
                networkId={networkId}
                onChange={e => onChangeAmount(_.get(e, 'target.value'))}
              />
              <Step>
                <Info style={{ whiteSpace: 'nowrap' }}>For</Info>
                <Input.Amount
                  {...state.tokenA}
                  placeholder='0'
                  networkId={networkId}
                  value={_.get(state, 'tokenA.value')}
                  isViewOnly
                />
              </Step>
              <Step>
                <Info style={{ whiteSpace: 'nowrap' }}>And</Info>
                <Input.Amount
                  {...state.tokenB}
                  placeholder='0'
                  networkId={networkId}
                  value={_.get(state, 'tokenB.value')}
                  isViewOnly
                />
              </Step>
            </Step>
            <ContentSummary
              index={2}
              context={tabs.pool.add}
              data={{
                state,
                option,
                durations
              }}
              allow={
                <SpecialButton.AllowToken
                  amount={_.get(state, 'strike.value')}
                  title={`Allow ${_.get(strike, 'alias')}`}
                  isAllowed={_.get(state, 'allowance.strike')}
                  isLoading={_.get(state, 'allowance.isLoading')}
                  onApprove={doAllowanceApprove}
                />
              }
              transact={
                <SpecialButton.Transact
                  title='Add Liquidity'
                  isDisabled={!isFormValid}
                  isAllowed={isFormAllowed}
                  isLoading={[
                    machine.states.validate,
                    machine.states.process
                  ].includes(machine.current.value)}
                  onClick={onTransact}
                />
              }
            />
          </Form>
        </ContentSection>
        {!isLoading && !isAvailable ? (
          <ContentSection title='Note about providing liquidity'>
            <Incentive symbol='😬' isSelfPadded={false}>
              <p>
                The price for this options is too low, so the AMM will not
                receive any more new liquidity for now.
              </p>
            </Incentive>
          </ContentSection>
        ) : null}
      </Wrapper>
    </ContentBox>
  )
}

export default AddPut
