import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useToasts } from 'react-toast-notifications'
import IconAdd from '@material-ui/icons/GetAppRounded'
import IconRemove from '@material-ui/icons/PublishRounded'
import Helmet from '../../../Helmet'
import * as Tabs from '../../Tabs/Pool'

import { tabs } from '../../../../../constants'
import {
  TabBar,
  TabItem,
  SupportLoader,
  SupportMissing,
  SupportConnect
} from '../../Elements'

import { useAccount, useWalletModal, useOptionInfo } from '../../../../../hooks'

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-radius: ${props => props.theme.sizes.edge};
  border: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.colors.white};
  padding-bottom: ${props => props.theme.sizes.edge};
  padding-top: calc(${props => props.theme.sizes.edge} * 1 / 2);

  ${props => props.theme.medias.medium} {
    padding-bottom: 0;
    padding-top: 2px;
    border-radius: 0;
  }
`

const Content = styled.div`
  width: 100%;
  min-height: 540px;
`

function Pool () {
  const { addToast, removeAllToasts } = useToasts()
  const { durations, option, isLoading, isRestricted } = useOptionInfo()
  const { isExpired, isExercising } = durations || {}

  const { isConnected } = useAccount()
  const { connect } = useWalletModal()

  const isReady = useMemo(() => {
    if (isLoading) return false
    if (_.isNil(option)) return false
    if (_.isNil(durations)) return false
    const { isExpired, isExercising } = durations
    if (_.isNil(isExpired) || _.isNil(isExercising)) return false

    return true
  }, [durations, option, isLoading])

  const [locked, fallback] = useMemo(() => {
    if (isExpired) {
      return [[tabs.pool.add, tabs.pool.unmint], tabs.pool.remove]
    }
    if (isExercising) {
      return [[tabs.pool.add], tabs.pool.remove]
    }
    return [[], tabs.pool.add]
  }, [isExercising, isExpired])

  return (
    <Wrapper>
      <Helmet.TransactionPool data={{ option, durations }} />
      <TabBar
        fallback={fallback}
        supported={[tabs.pool.add, tabs.pool.remove]}
        locked={locked}
        isLoading={!isReady}
      >
        <TabItem
          Icon={IconAdd}
          title='Add Liquidity'
          hash={tabs.pool.add}
          isLocked={locked.includes(tabs.pool.add)}
          onLockedClick={() => {
            removeAllToasts()
            addToast('Providing liquidity is available only before exercise.', {
              appearance: 'warning',
              autoDismiss: true,
              autoDismissTimeout: 5000
            })
          }}
        />
        <TabItem
          Icon={IconRemove}
          title='Remove Liquidity'
          hash={tabs.pool.remove}
        />
      </TabBar>
      <Content>
        {isRestricted ? (
          <SupportMissing />
        ) : (
          <>
            {!locked.includes(tabs.pool.add) && (
              <>
                {option && option.isPut() ? <Tabs.AddPut /> : null}
                {option && option.isCall() ? <Tabs.AddCall /> : null}
              </>
            )}

            {!locked.includes(tabs.pool.remove) && <Tabs.Remove />}
            {!locked.includes(tabs.pool.unmint) && <Tabs.Unmint />}
          </>
        )}
      </Content>
      <SupportLoader isActive={!isReady && !isRestricted} />
      <SupportConnect isActive={isConnected} onClick={connect} />
    </Wrapper>
  )
}

export default Pool
