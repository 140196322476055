import _ from 'lodash'
import BigNumber from 'bignumber.js'
import { guards, toSignificantInput } from '@pods-finance/utils'
import { analytics } from '../../../../../../vendors'

function onInitialize ({ elements, dispatch, durations, underlying, strike }) {
  dispatch([], 'RESET')

  if (_.get(durations, 'isExpired')) {
    dispatch([elements.shares, { value: 100, max: 100, isDisabled: true }])
  } else {
    dispatch([elements.shares, { value: 0, max: 100 }])
  }

  dispatch([
    elements.tokenA,
    {
      token: [_.get(underlying, 'symbol'), _.get(strike, 'symbol')]
    }
  ])
  dispatch([
    elements.tokenB,
    {
      token: _.get(strike, 'symbol')
    }
  ])
}

function onChangeAmount ({
  amount: shares,
  dispatch,
  elements,
  durations,
  userPositionTokenA,
  userPositionTokenB,
  except = false
}) {
  if (_.get(durations, 'isExpired') && !except) {
    return
  }
  dispatch([
    elements.shares,
    {
      value: String(shares).trim(),
      warning: !_.isNilOrEmptyString(shares)
        ? guards.isAmountValid({
          value: shares,
          max: 100,
          min: null
        })
        : null
    }
  ])

  const tokenA =
    shares === 100
      ? userPositionTokenA
      : new BigNumber(shares || 0)
        .multipliedBy(userPositionTokenA)
        .dividedBy(100)

  const exposureA = toSignificantInput(
    tokenA.toString() || 0,
    BigNumber.ROUND_DOWN
  )

  dispatch([
    elements.tokenA,
    {
      value: shares,
      exposure: exposureA
    }
  ])

  const tokenB =
    shares === 100
      ? userPositionTokenB
      : new BigNumber(shares || 0)
        .multipliedBy(userPositionTokenB)
        .dividedBy(100)

  const exposureB = toSignificantInput(
    tokenB.toString() || 0,
    BigNumber.ROUND_DOWN
  )

  dispatch([
    elements.tokenB,
    {
      value: shares,
      exposure: exposureB
    }
  ])
}

function onTransact ({ machine, state, option, setup, signer }) {
  analytics.track(e => e.transactionRemoveLiquidityTrigger)
  machine.send(machine.events.save, {
    payload: {
      state,
      option,
      setup,
      signer
    }
  })
}

export default {
  onInitialize,
  onChangeAmount,
  onTransact
}
