import ReactGA from 'react-ga'
import { ENVIRONMENT } from '@pods-finance/globals'

export default {
  init: () => {
    if (!ENVIRONMENT.isDevelopment()) {
      ReactGA.initialize('UA-165955753-6')
    } else {
      ReactGA.initialize('test', { testMode: true })
    }
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
}
