import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Side from '../Side'

const WrapperPartial = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${props => props.theme.colors.white};
`

const Inner = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Frame = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: ${props => props.theme.sizes.sideElevation - 1};
  &::-webkit-scrollbar {
    display: none;
  }
`
const Content = styled.div`
  flex: 1;
  position: relative;
  z-index: 100;
  border: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.colors.platform};
  border-radius: calc(${props => props.theme.sizes.edge} * 2.5);
  overflow: hidden;
  min-height: calc(100vh - 2 * ${props => props.theme.sizes.edge});
  margin: ${props => props.theme.sizes.edge};
  margin-left: 0;

  ${props => props.theme.medias.medium} {
    padding-bottom: ${props => props.theme.sizes.edge};
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 200;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-radius: calc(${props => props.theme.sizes.edge} * 2.5);
  }
`

const Wrapper = styled(WrapperPartial)`
  &[data-type='transaction'] {
    ${Content} {
      max-width: ${props => props.theme.sizes.canvasMaxWidth};
      margin-left: auto;
      margin-right: auto;
      padding-bottom: ${props => props.theme.sizes.layoutEdge};
    }
  }

  ${props => props.theme.medias.medium} {
    ${Content} {
      border: none;
      width: 100%;
      margin: 0;
      border-radius: 0;
      min-height: 100vh;
    }
  }
`

function StructureTransaction ({ children }) {
  return (
    <Wrapper data-type='transaction'>
      <Inner>
        <Frame>
          <Content>{children}</Content>
        </Frame>
      </Inner>
    </Wrapper>
  )
}

function StructureBase ({ children }) {
  return (
    <Wrapper data-type='base'>
      <Inner>
        <Side />
        <Frame>
          <Content>{children}</Content>
        </Frame>
      </Inner>
    </Wrapper>
  )
}

function Structure ({ children, type }) {
  if (type === 'transaction') {
    return <StructureTransaction>{children}</StructureTransaction>
  }
  return <StructureBase>{children}</StructureBase>
}

Structure.propTypes = {
  type: PropTypes.oneOf(['base', 'transaction'])
}

Structure.defaultProps = {
  type: 'base'
}

export default Structure
