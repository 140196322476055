import _ from 'lodash'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Button, Modal as Base } from '@pods-finance/components'
import { modals, pages, etherscaned } from '../../../../constants'
import { useNetworkId } from '../../../../hooks'

import { useModal } from '@pods-finance/contexts'
import IconEtherscan from '@material-ui/icons/OpenInNewRounded'

const Modal = styled(Base)`
  & > div[data-component='card'] {
    max-width: 500px;
    border-radius: ${props => props.theme.sizes.edge};
  }
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
`

const Paragraph = styled.p`
  color: ${props => props.theme.colors.dark};
  font-size: 11pt;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  text-align: left;

  a {
    color: ${props => props.theme.colors.middle};
    font-weight: 600;
  }
`

const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${props => props.theme.sizes.edge};
  margin: 0 auto;
  margin-top: calc(${props => props.theme.sizes.edge} * 2);
  padding-top: calc(${props => props.theme.sizes.edge} * 2);
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.grayBlueLight};

  & > * {
    grid-column: span 1;
    &:first-child {
      grid-column: span 3;
    }
  }

  ${props => props.theme.medias.small} {
    grid-template-columns: 1fr;
    & > * {
      grid-column: span 1 !important;
    }
  }
`

const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    position: absolute;
    font-size: 14pt;
  }
  ${props =>
    props.push &&
    css`
      margin-right: 10px;
    `}

  ${props => props.theme.medias.small} {
    margin-right: 10px !important;
  }
`

function ActivityItem () {
  const id = modals.activityItem
  const networkId = useNetworkId()
  const { data, setOpen } = useModal(id)
  const action = useMemo(() => _.get(data, 'action'), [data])
  const history = useMemo(() => _.get(data, 'history'), [data])

  return (
    <Modal id={id} title={_.get(data, 'action.preview.title') || 'Transaction'}>
      <Content>
        <Paragraph>
          Quick access to the transaction and the option/pool linked to it. Old
          series are hidden from the main pages and will be archived.
        </Paragraph>
        {!_.isNil(action) ? (
          <Actions>
            <Button
              title='View on Explorer'
              appearance={a => a.outline}
              accent={(a, d) => (d ? a.whiteAbsolute : a.darkAbsolute)}
              type={t => t.external}
              to={etherscaned(_.get(action, 'hash'), networkId).toString()}
              childrenLeft={
                <Left>
                  <IconEtherscan />
                </Left>
              }
              isFullWidth
            />
            <Button
              title='Buy'
              appearance={a => a.outline}
              accent={(a, d) => (d ? a.whiteAbsolute : a.darkAbsolute)}
              type={t => t.button}
              onClick={() => {
                setOpen(false)
                history.push(
                  pages.transactionHedge.builder(
                    _.get(action, 'option.address')
                  )
                )
              }}
              childrenLeft={
                <Left push>
                  <pages.hedge.Icon />
                </Left>
              }
            />
            <Button
              title='Sell'
              appearance={a => a.outline}
              accent={(a, d) => (d ? a.whiteAbsolute : a.darkAbsolute)}
              type={t => t.button}
              onClick={() => {
                setOpen(false)
                history.push(
                  pages.transactionInvest.builder(
                    _.get(action, 'option.address')
                  )
                )
              }}
              childrenLeft={
                <Left push>
                  <pages.invest.Icon />
                </Left>
              }
            />
            <Button
              title='Pool'
              appearance={a => a.outline}
              accent={(a, d) => (d ? a.whiteAbsolute : a.darkAbsolute)}
              type={t => t.button}
              onClick={() => {
                setOpen(false)
                history.push(
                  pages.transactionPool.builder(_.get(action, 'option.address'))
                )
              }}
              childrenLeft={
                <Left push>
                  <pages.pool.Icon />
                </Left>
              }
            />
          </Actions>
        ) : (
          <p>...</p>
        )}
      </Content>
    </Modal>
  )
}

export default ActivityItem
