import _ from 'lodash'
import BigNumber from 'bignumber.js'
import { guards } from '../../../../../../machines'
import { toSignificantInput, isBalanceInsignificant } from '@pods-finance/utils'
import { analytics } from '../../../../../../vendors'

function onInitialize ({
  elements,
  dispatch,
  collateral,
  underlying,
  strike,
  unmintableOptions
}) {
  dispatch([], 'RESET', [elements.allowance])
  dispatch([
    elements.options,
    {
      token: [_.get(underlying, 'symbol'), _.get(strike, 'symbol')],
      max: !isBalanceInsignificant(unmintableOptions)
        ? unmintableOptions.toString()
        : null
    }
  ])
  dispatch([
    elements.collateral,
    {
      token: _.get(collateral, 'symbol')
    }
  ])
}

function onPrimaryRefocus ({ isCollateralPrimary, dispatch, elements, state }) {
  if (
    isCollateralPrimary === _.get(state, `${elements.collateral}.isPrimary`)
  ) {
    return
  }
  dispatch([
    elements.collateral,
    {
      isPrimary: isCollateralPrimary
    }
  ])
  dispatch([
    elements.options,
    {
      isPrimary: !isCollateralPrimary
    }
  ])
}

function onChangeAmount ({
  amounts,
  dispatch,
  elements,
  option,
  unmintableOptions
}) {
  if (!_.isNil(amounts.collateral)) {
    const { collateral: amount } = amounts
    dispatch([elements.collateral, { value: amount }])

    const options = option.isPut()
      ? new BigNumber(amount || 0).dividedBy(option.strikePrice.humanized)
      : new BigNumber(amount || 0)

    const reflected = toSignificantInput(
      options.toString() || 0,
      BigNumber.ROUND_DOWN
    )

    dispatch([
      elements.options,
      {
        value: reflected,
        warning: !_.isNilOrEmptyString(reflected)
          ? guards.isAmountValid({
            value: reflected,
            max: unmintableOptions
          })
          : null
      }
    ])
  } else if (!_.isNil(amounts.options)) {
    const { options: amount } = amounts

    dispatch([
      elements.options,
      {
        value: String(amount).trim(),
        warning: !_.isNilOrEmptyString(amount)
          ? guards.isAmountValid({
            value: amount,
            max: unmintableOptions
          })
          : null
      }
    ])

    const collateral = toSignificantInput(
      option.isPut()
        ? new BigNumber(amount || 0).times(option.strikePrice.humanized)
        : new BigNumber(amount || 0),
      BigNumber.ROUND_UP
    )

    dispatch([
      elements.collateral,
      {
        value: collateral
      }
    ])
  }
}

function onTransact ({ machine, state, option, setup, signer }) {
  analytics.track(e => e.transactionUnmintTrigger)
  machine.send(machine.events.save, {
    payload: {
      state,
      option,
      setup,
      signer
    }
  })
}

export default {
  onInitialize,
  onChangeAmount,
  onPrimaryRefocus,
  onTransact
}
