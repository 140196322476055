import _ from 'lodash'
import BigNumber from 'bignumber.js'
import {
  guards,
  toSignificantInput,
  isBalanceInsignificant
} from '@pods-finance/utils'
import { analytics } from '../../../../../../vendors'

function onInitialize ({ elements, dispatch, option, balanceOptions }) {
  dispatch([], 'RESET', [elements.allowance])
  dispatch([
    elements.options,
    {
      token: [
        _.get(option, 'underlying.symbol'),
        _.get(option, 'strike.symbol')
      ],
      max: !isBalanceInsignificant(balanceOptions)
        ? balanceOptions.toString()
        : null
    }
  ])
  dispatch([elements.premiumToken, { value: _.get(option, 'strike.symbol') }])
}

const onPremiumRecompute = _.debounce(
  ({ anchor, elements, dispatch, option, safeComputePremium }) => {
    ;(async () => {
      dispatch([elements.premium, { value: null, isLoading: true }])
      if (anchor === null) return

      const current = anchor // Forcing checks for requests filled out-of-order

      const computed = await safeComputePremium({
        option,
        amount: new BigNumber(anchor),
        external: true
      })

      const { fees, value: _value } = computed || {}
      const value = _value ? _value.toString() : null

      if (current !== anchor) return

      dispatch([
        elements.premium,
        {
          value: toSignificantInput(value || 0, BigNumber.ROUND_DOWN),
          isLoading: false,
          warning: !_.isNilOrEmptyString(value)
            ? guards.isPremiumValid({
              value,
              context: 'liquidity'
            })
            : null
        }
      ])
      dispatch([elements.fees, { value: fees }])
    })()
  },
  300
)

function onChangeAmount ({
  amount,
  dispatch,
  elements,
  option,
  balanceOptions,
  safeComputePremium
}) {
  dispatch([
    elements.options,
    {
      value: String(amount).trim(),
      warning: !_.isNilOrEmptyString(amount)
        ? guards.isAmountValid({
          value: amount,
          max: balanceOptions
        })
        : null
    }
  ])

  onPremiumRecompute({
    anchor: amount,
    elements,
    dispatch,
    option,
    safeComputePremium
  })
}

function onTransact ({ machine, state, option, setup, signer }) {
  analytics.track(e => e.transactionResellTrigger)
  machine.send(machine.events.save, {
    payload: {
      state,
      option,
      setup,
      signer
    }
  })
}

export default {
  onInitialize,
  onChangeAmount,
  onTransact
}
