import _ from 'lodash'
import { useMemo } from 'react'
import Theme from '@pods-finance/themes'
import { attributes } from '@pods-finance/globals'
import { useAccount, useOptionActivity } from '../../hooks'

export default function useActivityOptionTable () {
  const activity = useOptionActivity()
  const { isExpected, isConnected } = useAccount()
  const { actions, isLoading, count } = useMemo(() => activity, [activity])

  const columns = useMemo(
    () => [
      {
        title: 'Transaction',
        subtitle: 'Details',
        layout: attributes.layout.cell.TransactionHeader,
        weight: 2
      },
      {
        title: 'Amount',
        layout: attributes.layout.cell.TransactionAmount,
        weight: 2
      },
      {
        title: 'Date',
        layout: attributes.layout.cell.Timestamp
      }
    ],
    []
  )

  const instructions = useMemo(
    () => ({
      onRowClick: _.noop
    }),
    []
  )
  const rows = useMemo(
    () =>
      actions.map(action => {
        return {
          id: _.get(action, 'id'),
          cells: [
            {
              type: _.get(action, 'type'),
              title: _.get(action, 'preview.title'),
              value: _.get(action, 'preview.title'),
              classification: _.get(action, 'preview.classification'),

              tokens: [
                action.preview.underlying,
                action.preview.strike,
                action.preview.tokenB
              ],
              strikePrice: _.get(
                action,
                'option.strikePrice.humanized'
              ).toString(),
              expiration: _.get(action, 'preview.expiration')
            },
            {
              value: _.get(action, 'preview.amountTransacted'),
              subtitle: _.get(action, 'preview.amountTransactedInfo')
            },
            {
              value: _.get(action, 'timestamp'),
              split: true
            }
          ]
        }
      }),
    [actions]
  )

  const isHidden = useMemo(() => {
    if (isLoading) return true
    if (actions.length === 0) return true
    return false
  }, [actions, isLoading])

  return useMemo(
    () => ({
      data: {
        columns,
        rows,
        instructions,
        /** Cosmetics */
        isLoading: (isExpected && !isConnected) || (isLoading && !count),
        expected: 1,
        empty: 4,
        theme: Theme.attributes.table.theme.activitySlim
      },
      isHidden
    }),
    [
      columns,
      rows,
      instructions,
      isLoading,
      isExpected,
      isConnected,
      isHidden,
      count
    ]
  )
}
