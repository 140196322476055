import _ from 'lodash'
import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import numeral from 'numeral'
import ArrowDown from '@material-ui/icons/KeyboardArrowDown'
import IconForced from '@material-ui/icons/SearchRounded'
import { Link, useParams } from 'react-router-dom'

import { Step, Label } from '@pods-finance/components'
import { toNumeralPrice } from '@pods-finance/utils'
import { useToken } from '../../../../../hooks'
import { pages, tabs } from '../../../../../constants'
import { analytics } from '../../../../../vendors'

const Cell = styled.div`
  display: flex;
`

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 460px;
  & > ${Cell} {
    max-width: 264px;
    &:first-child {
      padding-right: ${props => props.theme.sizes.edge};
      min-width: 200px;
    }
    &:last-child {
      flex: 1;
    }
  }

  ${props => props.theme.medias.small} {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    & > ${Cell} {
      &:first-child {
        max-width: 100%;
        width: 100%;
        padding: 0;
      }
      &:last-child {
        margin-top: ${props => props.theme.sizes.edge};
        max-width: 100%;
        width: 100%;
      }
    }
  }
`

const Toggle = styled.div`
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin-left: 8px;
  transform: rotate(0deg);
  transition: transform 200ms;
  cursor: pointer;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.border};
  transition: background-color 150ms;

  & > svg {
    font-size: 12pt;
    color: ${props => props.theme.colors.dark};
  }

  &[data-active='true'] {
    transform: rotate(-180deg);
    transition: transform 200ms;
  }

  &[data-forced='true'] {
    cursor: default;
    transform: rotate(0deg) !important;
    transition: transform 200ms;
  }
  &:not([data-forced='true']) {
    &:hover,
    &:active {
      background-color: ${props => props.theme.colors.contentLight};
      transition: background-color 150ms;
    }
  }
`

const Explainer = styled.div`
  width: 100%;
  max-height: 0;
  transition: max-height 400ms;
  overflow: hidden;
  & > * {
    opacity: 0;

    transition: opacity 200ms;
  }

  &[data-active='true'] {
    max-height: 500px;
    transition: max-height 400ms;
    & > * {
      opacity: 1;
      transition: opacity 200ms;
    }
  }
`

const ExplainerContent = styled.div`
  margin: 0;
  padding-bottom: calc(${props => props.theme.sizes.edge} * 1.5);
  padding-top: calc(${props => props.theme.sizes.edge} * 1.5);
  margin-top: calc(${props => props.theme.sizes.edge} * -1.5);

  font-size: 11pt !important;
  font-weight: 500;
  color: ${props => props.theme.colors.contentMedium};
  line-height: 1.8;

  b {
    font-weight: 700;
  }

  div {
    display: inline-flex;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
  }
`

const InlineToken = styled.span`
  display: inline-block;
  font-weight: 700;
  &:before {
    display: inline-block;
    content: '';
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-image: url(${props => props.source || ''});
    background-repeat: no-repeat;
    background-size: 19px;
    margin-bottom: -5px;
    margin-right: 3px;
    margin-left: 1px;
  }
`

const LearnMoreAnimation = keyframes`
  0{
    opacity: 0;
    transform: translateX(-20px);
  }
  3%, 42%{
    opacity: 1;
    transform: translateX(0);
  }
  45%, 100%{
    opacity: 0;
    transform: translateX(-20px);
  }

`

const LearnMore = styled.div`
  position: relative;
  padding-left: 6px;
  animation-name: ${LearnMoreAnimation};
  animation-fill-mode: forwards;
  animation-duration: 16s;
  animation-delay: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  opacity: 0;
  transform: translateX(-20px);

  height: 24px;
  & > p {
    display: flex;
    align-items: center;
    position: absolute;
    left: -12px;
    top: 0;
    padding-right: 6px;
    padding-left: 18px;
    margin: 0;
    white-space: nowrap;
    height: 24px;
    border-radius: 2px;
    font-size: 9pt;
    font-weight: 600;
    color: ${props => props.theme.colors.dark};
    text-align: center;
    background-color: ${props => props.theme.colors.white};
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  &[data-active='true'] {
    animation-play-state: paused;
  }
`

function Token ({ t }) {
  return (
    <InlineToken source={_.get(t, 'icon')}>{_.get(t, 'alias')}</InlineToken>
  )
}

function interpret ({ id, data, context, resolve }) {
  switch (context) {
    case tabs.invest.write: {
      const underlying = resolve(_.get(data, 'option.underlying'))
      const strike = resolve(_.get(data, 'option.strike'))
      const collateral = resolve(_.get(data, 'option.collateral'))
      const anticollateral = resolve(_.get(data, 'option.anticollateral'))

      const isPut = _.attempt(() => data.option.isPut())

      const underlyingAmount = toNumeralPrice(
        _.get(data, 'state.underlying.value'),
        false
      )

      const strikeAmount = toNumeralPrice(
        _.get(data, 'state.strike.value'),
        false
      )

      const collateralAmount = toNumeralPrice(
        _.get(data, 'state.collateral.value'),
        false
      )

      const premiumAmount = _.get(data, 'state.premium.isLoading')
        ? '~'
        : toNumeralPrice(_.get(data, 'state.premium.value'))

      const strikePrice = toNumeralPrice(
        _.get(data, 'option.strikePrice.humanized')
      )

      return (
        <>
          You are creating {isPut ? 'Put' : 'Call'} options on{' '}
          <b>{_.get(underlying, 'symbol')}</b> and selling them for a premium.
          In order to do that you will lock <Token t={collateral} />{' '}
          <b>{collateralAmount}</b> as collateral. You are willing to exchange
          these into (max) <Token t={isPut ? underlying : strike} />{' '}
          <b>{isPut ? underlyingAmount : strikeAmount}</b>, in return for a
          premium of at least <b>{premiumAmount}</b>.
          <br />
          This means that you are willing to use your collateral to{' '}
          {isPut ? 'buy' : 'sell'} <Token t={anticollateral} /> at{' '}
          <b>{strikePrice}</b>. The collateral has to be locked until the
          expiration date. If you want to leave this position before the
          expiration you will have to (1){' '}
          <Link to={pages.transactionHedge.builder(id, tabs.hedge.buy)}>
            buy
          </Link>{' '}
          back the same amount of options, at market price (balancing your
          position) and (2){' '}
          <Link to={pages.transactionPool.builder(id, tabs.pool.unmint)}>
            unmint
          </Link>{' '}
          your option tokens to unlock your collateral.
        </>
      )
    }
    case tabs.invest.withdraw: {
      const collateral = resolve(_.get(data, 'option.collateral'))
      const anticollateral = resolve(_.get(data, 'option.anticollateral'))

      return (
        <>
          Withdrawing will be active after the exercise window (usually 24h
          after expiration). The amount of collateral you are able to unlock
          will vary based on how many options are exercised by the buyers (who
          will exchange your <Token t={collateral} /> for their{' '}
          <Token t={anticollateral} />
          ).
        </>
      )
    }

    case tabs.hedge.buy: {
      const isPut = _.attempt(() => data.option.isPut())

      const underlying = resolve(_.get(data, 'option.underlying'))
      const strike = resolve(_.get(data, 'option.strike'))

      const underlyingAmount = toNumeralPrice(
        _.get(data, 'state.underlying.value'),
        false
      )

      const premiumAmount = _.get(data, 'state.premium.isLoading')
        ? '~'
        : toNumeralPrice(_.get(data, 'state.premium.value'))

      const strikePrice = toNumeralPrice(
        _.get(data, 'option.strikePrice.humanized')
      )

      const exercise = _.get(data, 'durations.exerciseStartFormattedWithTime')

      return (
        <>
          You're buying a {isPut ? 'Put' : 'Call'} option for{' '}
          <Token t={underlying} /> <b>{underlyingAmount}</b>. It means that you
          can exercise your right to {isPut ? 'sell' : 'buy'} it for{' '}
          <Token t={strike} /> <b>{strikePrice}</b> per asset. You will do that
          during the exercise window ({exercise}). For this, the upfront premium
          payment is <Token t={strike} /> <b>{premiumAmount}</b>.
        </>
      )
    }

    case tabs.hedge.exercise: {
      const max = toNumeralPrice(_.get(data, 'max'), false) || '~'
      const option = _.get(data, 'option')
      const isPut = _.attempt(() => option.isPut())
      const underlying = resolve(_.get(option, 'underlying'))
      const strike = resolve(_.get(option, 'strike'))
      const strikePrice = _.get(option, 'strikePrice.humanized')

      return (
        <>
          You can exercise your right to {isPut ? 'sell' : 'buy'} a maximum
          amount of <Token t={underlying} /> <b>{max}</b> (underlying asset) for{' '}
          <Token t={strike} /> with a locked price of <Token t={strike} />{' '}
          <b>{strikePrice.toString()}</b> per asset. If you're sending utility
          tokens, the max button has a 0.01 tolerance for gas costs.
        </>
      )
    }

    case tabs.invest.resell: {
      const underlying = resolve(_.get(data, 'option.underlying'))
      const strike = resolve(_.get(data, 'option.strike'))

      const premiumAmount = _.get(data, 'state.premium.isLoading')
        ? '~'
        : toNumeralPrice(_.get(data, 'state.premium.value'))

      return (
        <>
          You are re-selling options on <Token t={underlying} /> that you've
          previously bought or minted. In return you'll receive a premium of at
          least <Token t={strike} /> <b>${premiumAmount}</b>.
        </>
      )
    }

    case tabs.pool.add: {
      const option = resolve(_.get(data, 'option'))
      const underlying = resolve(_.get(option, 'underlying'))
      const strike = resolve(_.get(option, 'strike'))
      const isPut = _.attempt(() => option.isPut())

      if (!isPut) {
        return (
          <>
            In order to become a liquidity provider and earn you have to supply
            assets to the pool. You will be able to add balanced amounts of{' '}
            <Token t={underlying} /> <b>:</b> <Token t={strike} /> options and{' '}
            <Token t={strike} />. To acquire options, you have to mint them by
            locking up collateral.
          </>
        )
      }

      return (
        <>
          In order to become a Liquidity Provider and earn you have to supply
          assets to the pool. You will be able to add <Token t={strike} /> and
          we'll automatically split it into <Token t={underlying} /> <b>:</b>{' '}
          <Token t={strike} /> option tokens (by locking and minting) and{' '}
          <Token t={strike} /> for the stable side of the pool. The latter
          amount will be based on the AMM pricing for options. <br /> <br />{' '}
          When it comes to removing liquidity, we'll unwrap the collateral from
          options into <Token t={strike} /> for you to{' '}
          <Link to={pages.transactionInvest.builder(id, tabs.invest.withdraw)}>
            withdraw
          </Link>{' '}
          . The funds from the stablecoin side will require{' '}
          <Link to={pages.transactionPool.builder(id, tabs.pool.remove)}>
            removing liquidity
          </Link>
          . Also, fully exiting your position means managing the excess of
          options by either reselling the surplus or repurchasing the missing
          options. This process is explained step-by-step in the remove
          liquidity page.
        </>
      )
    }

    case tabs.pool.remove: {
      const {
        userPositionTokenA,
        userPositionTokenB,
        userPositionStrike
      } = data
      const underlying = resolve(_.get(data, 'option.underlying'))
      const strike = resolve(_.get(data, 'option.strike'))
      const advanced = !!_.get(data, 'isAdvanced')

      const isWithoutPosition = () =>
        advanced
          ? (_.isNil(userPositionTokenA) && _.isNil(userPositionTokenB)) ||
            (userPositionTokenA.isZero() && userPositionTokenB.isZero())
          : _.isNil(userPositionStrike) || userPositionStrike.isZero()

      const tokenAAmount = toNumeralPrice(
        _.get(data, 'state.tokenA.exposure'),
        false
      )

      const tokenBAmount = toNumeralPrice(
        _.get(data, 'state.tokenB.exposure'),
        false
      )
      const strikeAmount = toNumeralPrice(
        _.get(data, 'state.strike.exposure'),
        false
      )

      const tokenAPercent = `${numeral(
        _.get(data, 'state.tokenA.value')
      ).format('0')}%`

      const tokenBPercent = `${numeral(
        _.get(data, 'state.tokenB.value')
      ).format('0')}%`

      if (isWithoutPosition()) {
        return (
          <>
            You don't have a position in this <Token t={underlying} /> <b>:</b>{' '}
            <Token t={strike} /> series. Removing liquidity is disabled.
          </>
        )
      }

      return (
        <>
          You are removing <b>{tokenAPercent}</b> shares of your deposit's
          initial exposure. Considering the pool’s activity, this translates
          into a position of <Token t={underlying} /> <b>:</b>{' '}
          <Token t={strike} /> <b>{tokenAAmount}</b> option tokens (
          <b>{tokenAPercent}</b>) and <Token t={strike} /> <b>{tokenBAmount}</b>{' '}
          (<b>{tokenBPercent}</b>).{' '}
          {!advanced && (
            <>
              <br />
              This is an automated action so you will receive a max amount of
              stablecoins (we will unmint the options for you) for a total of{' '}
              <Token t={strike} /> <b>{strikeAmount}</b>. If you'll have a
              surplus of options, you will also receive those for the exercise.
            </>
          )}
          <br /> <br /> Bear in mind that it could include an impermanent loss
          or impermanent gain. If you are removing a position of option tokens
          that is smaller then what you initially provided, those have been
          traded by the AMM as if you had sold these options. To offset this
          balance, you can use any stablecoins that you remove to buy back those
          options (and unmint/unlock the collateral if necessary).
          <br />
        </>
      )
    }

    case tabs.pool.mint: {
      const isPut = _.attempt(() => _.get(data, 'option').isPut())

      const underlying = resolve(_.get(data, 'option.underlying'))
      const strike = resolve(_.get(data, 'option.strike'))
      const collateral = resolve(_.get(data, 'option.collateral'))

      const optionsAmount = toNumeralPrice(
        _.get(data, 'state.options.value'),
        false
      )

      const collateralAmount = toNumeralPrice(
        isPut
          ? _.get(data, 'state.strike.value')
          : _.get(data, 'state.underlying.value'),
        false
      )

      return (
        <>
          To become a liquidity provider of options you need to mint. This
          action will lock collateral and create option tokens without selling
          them on the market (no risk of providing cover, yet). To mint, you
          will lock <Token t={collateral} /> <b>{collateralAmount}</b> and
          receive <Token t={underlying} />: <Token t={strike} />{' '}
          <b>{optionsAmount}</b> in return.
        </>
      )
    }

    case tabs.pool.unmint: {
      const underlying = resolve(_.get(data, 'option.underlying'))
      const strike = resolve(_.get(data, 'option.strike'))

      return (
        <>
          Unminting means unlocking collateral from option token that were
          minted (created) when providing liquidity to the AMM. To get your
          collateral back you need to hold the exact same amount of option
          tokens you provided as liquidity initially. You can find this
          information in the "Activity" page. <br /> <br />
          If you have less option tokens than you provided you'll need to
          repurchase the missing amount from the AMM. If you don't, you'll be
          exposed to the option serie as if you had sold options. You will
          always be able to withdraw the funds that are rightfully yours (
          <Token t={strike} /> or <Token t={underlying} />) after expiration.
        </>
      )
    }

    default:
      return null
  }
}

function ContentSummary ({
  className,
  index,
  data,
  allow,
  transact,
  context,
  isForced
}) {
  const { get: resolve } = useToken()
  const { id } = useParams()
  const interpreted = useMemo(() => interpret({ id, data, context, resolve }), [
    id,
    data,
    context,
    resolve
  ])

  const [isActive, setIsActive] = useState(isForced)
  const toggle = useCallback(() => {
    if (isForced) return
    setIsActive(prev => !prev)
    analytics.track(e => e.transactionSummary)
  }, [isForced, setIsActive])

  return (
    <Step className={className} isLast>
      <Label onClick={toggle}>
        Step {_.isString(index) ? index : `${index}.`} Summary and confirmation{' '}
        <Toggle data-active={isActive} data-forced={isForced}>
          {isForced ? <IconForced /> : <ArrowDown />}
        </Toggle>
        <LearnMore data-active={isActive}>
          <p>Learn More</p>
        </LearnMore>
      </Label>
      <Explainer data-active={isActive}>
        <ExplainerContent>{interpreted}</ExplainerContent>
      </Explainer>
      <Row data-step='actions'>
        {allow && <Cell>{allow}</Cell>}
        {transact && <Cell>{transact}</Cell>}
      </Row>
    </Step>
  )
}

ContentSummary.propTypes = {
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  allow: PropTypes.node,
  transact: PropTypes.node,
  data: PropTypes.shape({}),
  context: PropTypes.oneOf([
    ...Object.values(tabs.hedge),
    ...Object.values(tabs.invest),
    ...Object.values(tabs.pool)
  ]),
  isForced: PropTypes.bool
}

ContentSummary.defaultProps = {
  index: 3,
  allow: null,
  transact: null,
  data: {},
  isForced: false
}

export default ContentSummary
