import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Helper, Table as TablePartial } from '@pods-finance/components'
import { useActivityOptionTable } from '../../../../../hooks'
import { pages } from '../../../../../constants'

const WrapperPartial = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-radius: ${props => props.theme.sizes.edge};
  border: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.colors.white};
  padding: calc(${props => props.theme.sizes.edge} * 1.5);
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: ${props => props.theme.sizes.edge};
  ${props => props.theme.medias.small} {
    padding: 0;
  }
`
const Right = styled.div`
  display: flex;
  align-items: center;
  *[data-purpose='helper-wrapper'] {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
`

const Title = styled.p`
  margin: 0;
  font-size: 12pt;
  font-weight: 700;
  color: ${props => props.theme.colors.dark};
`

const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0;
  ${props => props.theme.medias.small} {
    display: none;
  }
`

const TableWrapper = styled.div`
  width: 100%;
`

const Table = styled(TablePartial)`
  padding: 0;
  min-width: 100%;

  div[data-component='box'] {
    padding: 0;
    border: none;
    div[data-component='header'],
    div[data-component='footer'] {
      display: none;
    }

    div[data-component='body'] {
      padding: 2px;

      div[data-component='row'] {
        min-height: 66px;
        background-color: transparent !important;
        transition: background-color 150ms;
        cursor: default;
        &:hover {
          background-color: ${props => props.theme.colors.platform} !important;
          transition: background-color 150ms;
        }
      }
      div[data-component='row-loader'] {
        min-height: 66px;
      }
    }
  }

  ${props => props.theme.medias.medium} {
    padding: 0 calc(${props => props.theme.sizes.layoutEdgeMedium});
  }
`

const Wrapper = styled(WrapperPartial)`
  ${props => props.theme.medias.small} {
    padding: calc(${props => props.theme.sizes.edge} * 3 / 2)
      calc(${props => props.theme.sizes.edge} * 1);
    border-radius: 0;
  }

  &[data-hidden='true'] {
    display: none;
  }
`

const HistoryButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  width: 46px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.border};
  transition: background-color 150ms;
  cursor: pointer;
  & > svg {
    color: ${props => props.theme.colors.dark};
    font-size: 14pt;
    transition: color 250ms;
  }

  &:hover,
  &:active {
    background-color: ${props => props.theme.colors.platformMedium};
    transition: background-color 150ms;
  }
`

function Activity () {
  const { data, isHidden } = useActivityOptionTable()
  return (
    <Wrapper data-hidden={isHidden}>
      <Header>
        <Title>Latest Option Activity</Title>
        <Right>
          <Helper
            value='View the entire activity for your account'
            force='left'
          >
            <HistoryButton to={pages.activityTransactions.builder()}>
              <pages.activityTransactions.Icon />
            </HistoryButton>
          </Helper>
        </Right>
      </Header>
      <Body>
        <TableWrapper>
          <Table id='activityOption' data={data} />
        </TableWrapper>
      </Body>
    </Wrapper>
  )
}

export default Activity
