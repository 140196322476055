import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Spinner } from '@pods-finance/components'

import IconBreakdown from '@material-ui/icons/WrapTextRounded'
import IconVisit from '@material-ui/icons/CallMadeRounded'

const WrapperPartial = styled(Link)``

const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-column: span 1;
  border-radius: calc(${props => props.theme.sizes.edge} * 1);
  border: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.sizes.edge};
  min-height: 100%;
  overflow: hidden;
  transition: border 100ms, box-shadow 100ms;

  & > * {
    z-index: 10;
    position: relative;
  }
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 20;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.01);
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms 100ms;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 10px;
  background-color: ${props => props.theme.colors.platform};
  border: 1px solid ${props => props.theme.colors.border};
  flex-shrink: 0;
  & > svg {
    font-size: 16pt;
    color: ${props => props.theme.colors.dark};
  }
`

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
`

const Title = styled.p`
  font-size: 11pt;
  font-weight: 500;
  color: ${props => props.theme.colors.dark};
  margin: 0;
  line-height: 1.5;
  min-height: calc(${props => props.theme.sizes.layoutEdge} * 2.5);
  &[data-short='true'] {
    display: none;
  }

  b {
    font-weight: 700;
  }
`

const Value = styled.div`
  ${props => props.theme.extensions.ellipsis}

  & > p {
    margin: 0;
    font-size: 16pt;
    font-weight: 700;
    color: ${props => props.theme.colors.dark};

    &[data-accent='true'] {
    }
  }
`

const Divider = styled.div`
  flex: 1;
  height: 1px;
  margin: 0 10px;
  border-bottom: 1px dashed ${props => props.theme.colors.borderMedium};
`

const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.colors.border};
  z-index: 200;
  & > svg {
    font-size: 12pt;
    color: ${props => props.theme.colors.dark};
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`

const Wrapper = styled(WrapperPartial)`
  &:not([data-visit='true']) {
    &:not([data-breakdown='true']) {
      ${Action}, ${Divider} {
        display: none;
      }
    }
  }

  &[data-accented='true'] {
    ${Value} > p {
      color: ${props => props.theme.colors.middle};
      background: ${props => props.theme.gradients.primary};
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    ${Action} {
      & > svg {
        color: ${props => props.theme.colors.middle};
        background: ${props => props.theme.gradients.primary};
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  &[data-simulation='true'] {
    ${Value} > p {
      color: ${props => props.theme.colors.content};
    }
  }

  &[data-loading='true'] {
    pointer-events: none;
    ${Overlay} {
      pointer-events: all;
      opacity: 1;
      transition: opacity 250ms 100ms;
    }
    ${Action} {
      & > svg {
        display: none;
      }
    }
  }
  &[data-loading='false'] {
    cursor: pointer;
    ${Box} {
      &:hover,
      &:active {
        box-shadow: ${props => props.theme.styles.boxShadowHover};
        transition: box-shadow 150ms;
        ${Action} {
          background-color: ${props => props.theme.colors.platform};
        }
      }
    }
  }

  ${props => props.theme.medias.medium} {
    ${Box} {
      align-items: center;
      flex-direction: row;
      padding: calc(${props => props.theme.sizes.edge} * 1);
      min-height: auto;
      border-radius: calc(${props => props.theme.sizes.edge} * 1 / 2);
    }
    ${Title} {
      display: none;
      &[data-short='true'] {
        color: ${props => props.theme.colors.dark};
        display: flex;
        font-size: 12pt;
        margin: 0;
        min-height: 0;
        font-weight: 600;
      }
    }
    ${Content} {
      padding: calc(${props => props.theme.sizes.edge} * 1 / 3) 0;
      align-items: center;
      justify-content: flex-start;
      padding-right: calc(${props => props.theme.sizes.edge} * 1);
    }
    ${Value} {
      font-size: 12pt;
      margin: 0;
    }

    ${IconWrapper} {
      height: 48px;
      width: 48px;
      margin-right: calc(${props => props.theme.sizes.edge} * 1);
    }
    ${Action}, ${Divider} {
      display: none;
    }
  }
`

function Card ({
  Icon,
  title,
  titleShort,
  value,
  children,
  isLoading,
  isAccented,
  to,
  onClick
}) {
  return (
    <Wrapper
      to={to}
      as={to === null || isLoading ? 'div' : null}
      onClick={isLoading ? _.noop : onClick || _.noop}
      data-visit={!_.isNilOrEmptyString(to)}
      data-breakdown={!_.isNil(onClick)}
      data-accented={isAccented}
      data-loading={isLoading}
    >
      <Box>
        <Overlay />
        <IconWrapper>
          <Icon />
        </IconWrapper>
        <Content>
          <Title>{title}</Title>
          <Title data-short='true'>{titleShort || title}</Title>
        </Content>
        <Row>
          <Value>
            {!_.isNilOrEmptyString(value) && <p title={value}>{value}</p>}
            {!_.isNilOrEmptyString(children) && children}
          </Value>
          <Divider />
          <Action>
            {isLoading && (
              <Spinner
                size={20}
                thickness={1.2}
                color={(c, d) => (d ? c.whiteAbsolute : c.dark)}
              />
            )}
            {_.isNilOrEmptyString(to) ? <IconBreakdown /> : <IconVisit />}
          </Action>
        </Row>
      </Box>
    </Wrapper>
  )
}

Card.propTypes = {
  Icon: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  titleShort: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  isAccented: PropTypes.bool,
  to: PropTypes.string,
  onClick: PropTypes.func
}

Card.defaultProps = {
  isLoading: true,
  isAccented: false,
  titleShort: null,
  to: null,
  onClick: null
}

export default Card
