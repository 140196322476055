import _ from 'lodash'
import React, { useMemo, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import {
  Helper,
  Label,
  Input,
  Step,
  Container as FormPartial
} from '@pods-finance/components'
import { toNumeralPrice } from '@pods-finance/utils'
import {
  ContentBox,
  ContentSection,
  ContentSummary,
  SupportExtra
} from '../../../Elements'

import { SpecialButton, Pill } from '../../../../../common'

import reducers from '../../../../../../reducers'
import machines from '../../../../../../machines'
import { tabs, tokens } from '../../../../../../constants'

import {
  useNetworkId,
  useOptionInfo,
  useOwnBalance,
  useTransactionSetup,
  useFormValidator,
  useFormAssetAllowance,
  useOptionSellingPrice
} from '../../../../../../hooks'

import logic from './logic'

const Wrapper = styled.div`
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 3 / 2);
    &:last-child {
      margin-bottom: 0;
    }
  }
`
const Form = styled(FormPartial)`
  div[data-step='actions'] {
    max-width: 100%;
    & > div:first-child {
      min-width: 240px;
    }
  }
`

const Cell = styled.div`
  display: flex;
`

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  &[data-step='2'] {
    & > ${Cell} {
      &:first-child {
        padding-right: ${props => props.theme.sizes.edge};
        width: 174px;
        p,
        input {
          text-align: left !important;
          overflow-x: hidden;
        }
      }
      &:last-child {
        flex: 1;
      }
    }
  }
  ${props => props.theme.medias.small} {
    &[data-step='2'] {
      & > ${Cell} {
        &:first-child,
        &:last-child {
          width: auto !important;
          flex: 1;
        }
      }
    }
  }
`

const Info = styled.p`
  white-space: nowrap;
  ${props => props.theme.medias.medium} {
    white-space: normal;
    font-size: 9pt;
  }
`

function Write () {
  /**
   * --------------------------------------
   * Required data and utilities
   * --------------------------------------
   */
  const machine = machines.write.useMachine()
  const setup = useTransactionSetup()
  const networkId = useNetworkId()
  const { elements, state, dispatch } = reducers.write.useReducer()
  const {
    option,
    helper,
    collateral,
    underlying,
    strike,
    durations,
    isLoading: isOptionLoading
  } = useOptionInfo()

  const {
    value: balanceCollateral,
    isLoading: isBalanceCollateralLoading
  } = useOwnBalance(collateral)

  const { check: safeComputePremium } = useOptionSellingPrice()

  const isLoading = useMemo(
    () => isOptionLoading || isBalanceCollateralLoading,
    [isOptionLoading, isBalanceCollateralLoading]
  )

  const { isValid: isFormValid, isAllowed: isFormAllowed } = useFormValidator({
    state,
    machine
  })

  /**
   * --------------------------------------
   *  Methods and state updaters
   * --------------------------------------
   */

  const doAllowanceUpdate = useCallback(
    ({ isAllowed, isLoading }) =>
      dispatch([elements.allowance, { collateral: isAllowed, isLoading }]),
    [elements, dispatch]
  )

  const onChangeAmount = useCallback(
    amount =>
      logic.onChangeAmount({
        amount,
        dispatch,
        elements,
        option,
        balanceCollateral,
        safeComputePremium
      }),
    [dispatch, elements, option, balanceCollateral, safeComputePremium]
  )

  const onTransact = useCallback(() => {
    if (_.get(setup, 'guardTVL.isDisabled')) setup.guardTVL.show()
    else logic.onTransact({ machine, state, option, setup })
  }, [machine, state, option, setup])

  const {
    doApprove: doAllowanceApprove,
    doRefresh: doAllowanceRefresh
  } = useFormAssetAllowance({
    amount: _.get(state, 'collateral.value'), // TODO fix in production
    token: collateral,
    spender: _.get(helper, 'address'),
    onUpdate: doAllowanceUpdate
  })

  useEffect(() => {
    if (!isLoading) {
      logic.onInitialize({
        elements,
        dispatch,
        option,
        underlying,
        strike,
        collateral,
        balanceCollateral
      })

      doAllowanceRefresh()
    }
  }, [
    elements,
    dispatch,
    isLoading,
    option,
    underlying,
    strike,
    collateral,
    balanceCollateral,
    doAllowanceRefresh
  ])

  const isOverpayingInFees = useMemo(() => {
    try {
      const _fees = _.get(state, 'fees.value')
      const _premium = _.get(state, 'premium.value')
      if (_.isNilOrEmptyString(_fees) || _.isNaN(_fees)) return false
      if (_.isNilOrEmptyString(_premium) || _.isNaN(_premium)) return false

      const fees = new BigNumber(_fees)
      const premium = new BigNumber(_premium)

      if (fees.isZero() || premium.isZero()) return false

      /** anything over (fees = 20%, premium = 80%) should be considered overpaying */
      if (fees.times(4).isGreaterThanOrEqualTo(premium)) return true
    } catch (e) {}
    return false
  }, [state])

  return (
    <ContentBox hash={tabs.invest.write} isLoading={isLoading}>
      <Wrapper>
        <ContentSection
          title='Sell options by locking collateral'
          isContained
          isDisabled={[
            machine.states.validate,
            machine.states.process
          ].includes(machine.current.value)}
        >
          <Form>
            <Step>
              <Label>Step 1. Collateral to lock</Label>
              <Input.Amount
                {...state.collateral}
                placeholder='Enter amount'
                networkId={networkId}
                onChange={e => onChangeAmount(_.get(e, 'target.value'))}
              />
              {!isLoading && option && option.isPut() ? (
                <Step>
                  <Info>Willing to buy</Info>
                  <Input.Amount
                    {...state.underlying}
                    placeholder='0'
                    networkId={networkId}
                    onChange={() => {}}
                    isViewOnly
                  />
                </Step>
              ) : null}
              {!isLoading && option && option.isCall() ? (
                <Step>
                  <Info>Willing to sell for</Info>
                  <Input.Amount
                    {...state.strike}
                    placeholder='0'
                    networkId={networkId}
                    onChange={() => {}}
                    isViewOnly
                  />
                </Step>
              ) : null}
            </Step>
            <Step>
              <Label>Step 2. Expected premium</Label>
              <Row data-step='2'>
                <Cell>
                  <Input.Amount
                    {...state.premium}
                    placeholder='0'
                    networkId={networkId}
                    isViewOnly
                  />
                </Cell>
                <Cell>
                  <Input.Token
                    {...state.premiumToken}
                    source={Object.values(tokens.keys)}
                    placeholder='Premium token'
                    networkId={networkId}
                    onChange={() => {}}
                    isViewOnly
                  />
                </Cell>
              </Row>
              <SupportExtra>
                <Helper value='The fees will be paid to the liquidity providers, based on the size and price impact of your trade. This amount was already deducted from the next premium value (shown in step 2).'>
                  <Pill
                    label='Included LP fees: '
                    content={
                      !_.isNil(_.get(state, 'fees.value')) &&
                      !_.isNaN(_.get(state, 'fees.value'))
                        ? toNumeralPrice(_.get(state, 'fees.value'))
                        : null
                    }
                  />
                </Helper>
                {isOverpayingInFees && (
                  <Helper value='You may lower the required fees by spliting your trade into multiple smaller ones.'>
                    <Pill
                      isWarning
                      label='Warning: over 50% in fees'
                      content=''
                    />
                  </Helper>
                )}
              </SupportExtra>
            </Step>

            <ContentSummary
              index={3}
              context={tabs.invest.write}
              data={{
                state,
                option,
                durations
              }}
              allow={
                <SpecialButton.AllowToken
                  amount={_.get(state, 'collateral.value')}
                  title={`Allow ${_.get(collateral, 'alias')}`}
                  isAllowed={_.get(state, 'allowance.collateral')}
                  isLoading={_.get(state, 'allowance.isLoading')}
                  onApprove={doAllowanceApprove}
                />
              }
              transact={
                <SpecialButton.Transact
                  title='Write Options'
                  isDisabled={!isFormValid}
                  isAllowed={isFormAllowed}
                  isLoading={[
                    machine.states.validate,
                    machine.states.process
                  ].includes(machine.current.value)}
                  onClick={onTransact}
                />
              }
            />
          </Form>
        </ContentSection>
      </Wrapper>
    </ContentBox>
  )
}

export default Write
