import React from 'react'
import styled from 'styled-components'
import { Link, useParams } from 'react-router-dom'
import { Emoji } from '@pods-finance/components'
import IconArrow from '@material-ui/icons/ArrowForwardRounded'
import { pages, tabs } from '../../../../../constants'
import { analytics } from '../../../../../vendors'

const WrapperPartial = styled(Link)`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  border-radius: ${props => props.theme.sizes.edge};
  border: 1px solid
    ${props =>
      props.theme.isDark
        ? props.theme.colors.border
        : props.theme.colors.transparent};
  background: ${props => props.theme.gradients.darkReverse};
  padding: calc(${props => props.theme.sizes.edge} * 1.5) 0;
  cursor: pointer;
  overflow: hidden;

  p {
    margin: 0 0 -1px 0;
    font-size: 10pt;
    font-weight: 500;
    color: ${props => props.theme.colors.whiteAbsolute};
    line-height: 1.5;
  }
`

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  height: 100%;
`

const Left = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 100%;

  font-size: 17pt;
`

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 60px;
  font-size: 20pt;
`

const Icon = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 60px;

  & > svg {
    z-index: 1;
    font-size: 14pt;
    transform: translate(6px, 6px);
    transition: transform 200ms;
    color: ${props => props.theme.colors.whiteAbsolute};
  }

  &:after {
    content: '';
    position: absolute;
    width: 80px;
    height: 80px;
    z-index: 0;
    background-color: rgba(255, 255, 255, 0.06);
    border-radius: 50%;
    margin-right: -25px;
    margin-bottom: -35px;
    transition: background-color 200ms;
  }
`

const Wrapper = styled(WrapperPartial)`
  &:hover,
  &:active {
    ${Icon} {
      & > svg {
        transform: translate(8px, 6px);
        transition: transform 200ms;
      }
      &:after {
        background-color: rgba(255, 255, 255, 0.1);
        transition: background-color 200ms;
      }
    }
  }

  ${props => props.theme.medias.small} {
    padding: calc(${props => props.theme.sizes.edge} * 3 / 2) 0;
    border-radius: 0;
  }
`

function Earn () {
  const { id } = useParams()

  return (
    <Wrapper
      to={pages.transactionPool.builder(id, tabs.pool.add)}
      onClick={() => {
        analytics.track(e => e.transactionEarn)
      }}
    >
      <Left>
        <Emoji symbol='💎' label='Diamond' />
      </Left>
      <Center>
        <p>
          Earn with your stablecoins! Provide liquidity to the AMM and capture
          trading fees.
        </p>
      </Center>
      <Right>
        <Icon>
          <IconArrow />
        </Icon>
      </Right>
    </Wrapper>
  )
}

export default Earn
