import _ from 'lodash'
import BigNumber from 'bignumber.js'
import {
  guards,
  toSignificantInput,
  isBalanceInsignificant
} from '@pods-finance/utils'
import { analytics } from '../../../../../../vendors'

function onInitialize ({
  elements,
  dispatch,
  underlying,
  strike,
  balanceStrike
}) {
  dispatch([], 'RESET', [elements.allowance])
  dispatch([
    elements.strike,
    {
      token: _.get(strike, 'symbol'),
      max: !isBalanceInsignificant(balanceStrike)
        ? balanceStrike.toString()
        : null
    }
  ])
  dispatch([
    elements.tokenA,
    {
      token: [_.get(underlying, 'symbol'), _.get(strike, 'symbol')]
    }
  ])

  dispatch([
    elements.tokenB,
    {
      token: _.get(strike, 'symbol')
    }
  ])
}

function onChangeAmount ({
  amount,
  dispatch,
  elements,
  option,
  abPrice,
  balanceStrike
}) {
  if (_.isNilOrEmptyString(abPrice)) return

  dispatch([
    elements.strike,
    {
      value: String(amount).trim(),
      warning: !_.isNilOrEmptyString(amount)
        ? guards.isAmountValid({
          value: amount,
          max: balanceStrike
        })
        : null
    }
  ])

  const tokenA = new BigNumber(amount || 0).dividedBy(
    abPrice.plus(option.strikePrice.humanized)
  )
  const tokenB = tokenA ? tokenA.times(abPrice) : new BigNumber(0)

  const reflectedA = toSignificantInput(
    tokenA.toString() || 0,
    BigNumber.ROUND_UP
  )

  const reflectedB = toSignificantInput(
    tokenB.toString() || 0,
    BigNumber.ROUND_UP
  )

  dispatch([
    elements.tokenA,
    {
      value: reflectedA
    }
  ])
  dispatch([
    elements.tokenB,
    {
      value: reflectedB
    }
  ])
}

function onTransact ({ machine, state, option, setup, signer }) {
  analytics.track(e => e.transactionAddLiquidityTrigger)
  machine.send(machine.events.save, {
    payload: {
      state,
      option,
      setup,
      signer
    }
  })
}

export default {
  onInitialize,
  onChangeAmount,
  onTransact
}
