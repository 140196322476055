import _ from 'lodash'
import React, { useMemo } from 'react'
import BigNumber from 'bignumber.js'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { Network } from '@pods-finance/components'
import { networks } from '@pods-finance/globals'
import { Section } from '../../../common'
import { addresses } from '../../../../constants'
import Series from './Series'

const Wrapper = styled.div``

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 ${props => props.theme.sizes.layoutEdge};
  ${props => props.theme.medias.medium} {
    padding: 0 ${props => props.theme.sizes.layoutEdgeMedium};
  }
`

const List = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 6px;
  & > * {
    margin-right: 4px;
    &:last-child {
      margin: 0;
    }
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: calc(${props => props.theme.sizes.edge} * 1 / 2);
  width: 100%;
  ${props => props.theme.medias.large} {
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
  }
  ${props => props.theme.medias.medium} {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: calc(${props => props.theme.sizes.edge} * 1 / 2);
  }
`

export default function Ecosystem () {
  const list = useMemo(() => {
    return networks._supported
      .filter(id => id !== networks.kovan)
      .map(networkId => {
        const base = _.get(addresses, networkId)

        const convert = (raw, classification) => {
          return raw
            .filter(
              item => !_.isString(item) && _.isArray(item) && item.length === 4
            )
            .map(item => ({
              id: _.get(item, 0),
              tokens: _.toString(_.get(item, 1)).split(':'),
              strikePrice: _.get(item, 2),
              expiration: _.get(item, 3),
              networkId,
              classification
            }))
        }

        return convert(_.get(base, 'puts'), 'Put').concat(
          convert(_.get(base, 'calls'), 'Call')
        )
      })
      .reduce((prev, curr) => [...prev, ...curr], [])
      .filter(item => {
        try {
          const date = new BigNumber(item.expiration)
            .multipliedBy(1000)
            .toNumber()
          return !dayjs().isAfter(dayjs(date))
        } catch (e) {}
        return false
      })
  }, [])

  return (
    <Wrapper>
      <Section
        title='Ecosystem'
        description={
          <>
            <p>Trade options on all our supported networks </p>
            <List>
              <Network id={networks.mainnet} />
              <Network id={networks.matic} />
              <Network id={networks.arbitrum} />
            </List>
          </>
        }
      >
        <Content>
          <Grid>
            {list.map(item => (
              <Series key={_.get(item, 'id')} {...item} />
            ))}
          </Grid>
        </Content>
      </Section>
    </Wrapper>
  )
}
