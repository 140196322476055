import _ from 'lodash'
import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import ReactGA from 'react-ga'
import { useLocation } from 'react-router-dom'
import { useModal } from '@pods-finance/contexts'
import * as Modals from '../../components/specific/Modals'
import { modals, prefs } from '../../constants'
import { usePreferences } from '../../hooks'

const Wrapper = styled.div``

function Watcher () {
  const location = useLocation()
  const { value: preferences } = usePreferences()
  const { isOpen, setOpen } = useModal(modals.termsOfService)

  const isAnalyticsEnabled = useMemo(
    () =>
      !_.isNil(preferences) &&
      [true, 'true'].includes(_.get(preferences, prefs.isAnalyticsEnabled)),
    [preferences]
  )

  const isTermsOfServiceAccepted = useMemo(
    () =>
      !_.isNil(preferences) &&
      [true, 'true'].includes(
        _.get(preferences, prefs.isTermsOfServiceAccepted)
      ),
    [preferences]
  )

  /**
   *
   * Track page views with Google Analytics (if allowed)
   *
   */

  useEffect(() => {
    if (isAnalyticsEnabled && !_.isNil(location)) {
      ReactGA.pageview(_.get(location, 'pathname') + _.get(location, 'search'))
    }
  }, [isAnalyticsEnabled, location])

  /**
   *
   * Offer terms modal if terms are not yet allowed
   *
   */

  useEffect(() => {
    if (!isTermsOfServiceAccepted && !isOpen) {
      setOpen(true)
    }
  }, [isTermsOfServiceAccepted, isOpen, setOpen])

  return (
    <Wrapper>
      <Modals.ActivityItem />
      <Modals.TermsOfService />
      <Modals.RiskAcknowledgement />
      <Modals.FullBreakdown />
      <Modals.LaggingSystem />
      <Modals.TransactionState />
      <Modals.GuardTVL />
    </Wrapper>
  )
}

export default Watcher
