import _ from 'lodash'
import React, { useMemo } from 'react'
import BigNumber from 'bignumber.js'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { useToken } from '../../../../../hooks'
import { toNumeralPrice } from '@pods-finance/utils'
import { networks } from '@pods-finance/globals'
dayjs.extend(advancedFormat)

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-column: span 1;
  border-radius: calc(${props => props.theme.sizes.edge} * 1);
  border: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.sizes.edge};
  min-height: 100%;
  overflow: hidden;
  transition: border 100ms, box-shadow 100ms;

  & > * {
    z-index: 10;
    position: relative;
  }
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1);
`

const Icons = styled.div`
  display: flex;

  & > div {
    border: 1px solid ${props => props.theme.colors.border};
    background: ${props => props.theme.colors.white};
    height: 36px;
    width: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: -14px;
    &:first-child {
      margin-left: 0;
      z-index: 10;
    }
    &:nth-child(2) {
      z-index: 9;
    }

    & > img {
      height: 32px;
      width: 32px;
      background-color: ${props => props.theme.colors.platform};
      border-radius: 50%;
      flex-shrink: 0;
      &[src=''],
      &:not([src]) {
        opacity: 0;
      }
    }
  }
`

const Title = styled.div`
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1);
  & > p {
    font-size: 14pt;
    font-weight: 700;
    color: ${props => props.theme.colors.dark};
    margin: 0;
  }
`

const Description = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  p {
    margin: 0;
    font-size: 10pt;
    font-weight: 600;
    color: ${props => props.theme.colors.contentMedium};
    line-height: 1.5;
    b {
      font-weight: 600;
      color: ${props => props.theme.colors.dark};
    }
  }
`

export default function Series ({
  tokens: _tokens,
  expiration,
  strikePrice,
  networkId,
  classification
}) {
  const { value: tokens } = useToken(_tokens)
  const [date, isExpired] = useMemo(() => {
    try {
      const date = new BigNumber(expiration).multipliedBy(1000).toNumber()
      return [dayjs(date).format('MMM. Do, YYYY'), dayjs().isAfter(dayjs(date))]
    } catch (e) {
      return ['the day', false]
    }
  }, [expiration])

  return (
    <Wrapper data-expired={isExpired}>
      <Header>
        <Icons>
          {tokens.map(token => (
            <div key={_.get(token, 'symbol')}>
              <img alt={_.get(token, 'alias')} src={_.get(token, 'icon')} />
            </div>
          ))}
        </Icons>
      </Header>
      <Title>
        <p>
          {tokens.map(token => _.get(token, 'alias')).join(':')}{' '}
          {classification}
        </p>
      </Title>
      <Description>
        <p>
          Live on <b>{_.get(networks, `_data[${networkId}].name`)}</b> and
          expiring on {date} at {toNumeralPrice(strikePrice)}
        </p>
      </Description>
    </Wrapper>
  )
}

Series.propTypes = {
  tokens: PropTypes.arrayOf(PropTypes.string),
  expiration: PropTypes.string,
  strikePrice: PropTypes.string,
  networkId: PropTypes.number,
  classification: PropTypes.string
}

Series.defaultProps = {
  tokens: [],
  expiration: '0',
  strikePrice: '0',
  networks: 1,
  classification: 'Call'
}
