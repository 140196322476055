import _ from 'lodash'
import BigNumber from 'bignumber.js'
import { macros } from '@pods-finance/globals'

import Option from './Option'

const h = source => {
  const value = _.get(source, 'humanized')
  if (_.isNil(value)) return new BigNumber(0)
  return value
}

/**
 *
 * Should be extended for any additional functionality (e.g. PositionHelper for the info boxes in the frontend app)
 *
 * @typedef IValue
 * @property {BigNumber} raw
 * @property {BigNumber} humanized
 */

export default class Position {
  /**
   * Fixed Data
   */

  _element = {}
  /** @type Option */
  _option = null
  _isValid = false

  /** @type BigNumber */
  _premiumPaid
  /** @type BigNumber */
  _premiumReceived
  /** @type BigNumber */
  _optionsSold
  /** @type BigNumber */
  _optionsBought
  /** @type BigNumber */
  _optionsResold
  /** @type BigNumber */
  _optionsMinted
  /** @type BigNumber */
  _optionsUnminted
  /** @type BigNumber */
  _optionsExercised
  /** @type BigNumber */
  _optionsSent
  /** @type BigNumber */
  _optionsReceived
  /** @type BigNumber */
  _underlyingWithdrawn
  /** @type BigNumber */
  _strikeWithdrawn
  /** @type BigNumber */
  _initialOptionsProvided
  /** @type BigNumber */
  _initialTokensProvided
  /** @type BigNumber */
  _remainingOptionsProvided
  /** @type BigNumber */
  _remainingTokensProvided
  /** @type BigNumber */
  _finalOptionsRemoved
  /** @type BigNumber */
  _finalTokensRemoved

  /** @type BigNumber */
  _shortage
  /** @type BigNumber */
  _surplus
  /** @type BigNumber */
  _rebalancePrice

  get underlyingWithdrawn () {
    return this._underlyingWithdrawn
  }

  get strikeWithdrawn () {
    return this._strikeWithdrawn
  }

  get initialOptionsProvided () {
    return this._initialOptionsProvided
  }

  get initialTokensProvided () {
    return this._initialTokensProvided
  }

  get remainingOptionsProvided () {
    return this._remainingOptionsProvided
  }

  get remainingTokensProvided () {
    return this._remainingTokensProvided
  }

  get finalOptionsRemoved () {
    return this._finalOptionsRemoved
  }

  get finalTokensRemoved () {
    return this._finalTokensRemoved
  }

  get optionsResold () {
    return this._optionsResold
  }

  get optionsMinted () {
    return this._optionsMinted
  }

  get optionsUnminted () {
    return this._optionsUnminted
  }

  get optionsExercised () {
    return this._optionsExercised
  }

  get optionsSent () {
    return this._optionsSent
  }

  get optionsReceived () {
    return this._optionsReceived
  }

  get optionsBought () {
    return this._optionsBought
  }

  get optionsSold () {
    return this._optionsSold
  }

  get premiumReceived () {
    return this._premiumReceived
  }

  get premiumPaid () {
    return this._premiumPaid
  }

  get element () {
    return this._element
  }

  get option () {
    return this._option
  }

  get isValid () {
    return this._isValid
  }

  get id () {
    return _.get(this._element, 'id')
  }

  get networkId () {
    return _.get(this._element, 'networkId')
  }

  get shortage () {
    return this._shortage
  }

  get surplus () {
    return this._surplus
  }

  get rebalancePrice () {
    return this._rebalancePrice
  }

  isShortage () {
    return !_.isNilOrEmptyString(this._shortage) && !this._shortage.isZero()
  }

  isSurplus () {
    return !_.isNilOrEmptyString(this._surplus) && !this._surplus.isZero()
  }

  constructor ({ value }) {
    this._element = value

    if (_.isNil(this._element)) {
      this._isValid = false
      return
    }
    try {
      this._isValid = true
      this._option = new Option({
        value: value.option,
        warning: null,
        isLoading: false
      })

      this._premiumPaid = h(this._element.getPremiumPaidValue())
      this._premiumReceived = h(this._element.getPremiumReceivedValue())

      this._optionsBought = h(this._element.getOptionsBoughtValue())
      this._optionsSold = h(this._element.getOptionsSoldValue())
      this._optionsResold = h(this._element.getOptionsResoldValue())

      this._optionsMinted = h(this._element.getOptionsMintedValue())
      this._optionsUnminted = h(this._element.getOptionsUnmintedValue())
      this._optionsExercised = h(this._element.getOptionsExercisedValue())

      this._optionsSent = h(this._element.getOptionsSentValue())
      this._optionsReceived = h(this._element.getOptionsReceivedValue())

      this._underlyingWithdrawn = h(this._element.getUnderlyingWithdrawnValue())
      this._strikeWithdrawn = h(this._element.getStrikeWithdrawnValue())

      this._initialOptionsProvided = h(
        this._element.getInitialOptionsProvidedValue()
      )
      this._initialTokensProvided = h(
        this._element.getInitialTokensProvidedValue()
      )

      this._remainingOptionsProvided = h(
        this._element.getRemainingOptionsProvidedValue()
      )
      this._remainingTokensProvided = h(
        this._element.getRemainingTokensProvidedValue()
      )

      this._finalOptionsRemoved = h(this._element.getFinalOptionsRemovedValue())
      this._finalTokensRemoved = h(this._element.getFinalTokensRemovedValue())

      const rebalance = _.get(this._element, 'rebalancePrice')

      this._shortage = h(_.get(rebalance, 'context.shortage'))
      this._surplus = h(_.get(rebalance, 'context.surplus'))
      this._rebalancePrice = h(_.get(rebalance, 'value'))
    } catch (error) {
      console.error('Position', error)
    }
  }

  fromSDK () {
    return this._element
  }

  getValues ({ dynamics, market }) {
    const strike = _.get(this, 'option.strikePrice.humanized')
    const pnl = _.get(this, 'premiumReceived').minus(_.get(this, 'premiumPaid'))
    const flags = {}

    const withdrawn = _.get(this, 'underlyingWithdrawn').plus(
      _.get(this, 'strikeWithdrawn')
    )
    if (withdrawn.isZero() && !this.optionsSold.isZero()) {
      flags.withdrawRequired = true
    }

    if (dynamics && _.has(dynamics, 'userPositions')) {
      const poolPositions = _.get(dynamics, 'userPositions')
      const balance = _.get(dynamics, 'userOptionBalance.humanized')
      const minted = _.get(dynamics, 'userOptionMintedAmount.humanized')
      const price = _.get(dynamics, 'sellingPrice.value.humanized')
      const durations = _.attempt(() => this.option.getDurations())

      let poolPositionsValue = new BigNumber(0)

      if (
        !_.isNil(_.get(poolPositions, '1.humanized')) &&
        !_.isNil(_.get(poolPositions, '0.humanized')) &&
        !_.get(poolPositions, '0.humanized').isLessThanOrEqualTo(
          macros.MINIMUM_BALANCE_AMOUNT
        ) &&
        !_.get(poolPositions, '1.humanized').isLessThanOrEqualTo(
          macros.MINIMUM_BALANCE_AMOUNT
        )
      ) {
        const unmintable = BigNumber.min(
          minted,
          _.get(poolPositions, '0.humanized')
        )

        if (
          _.isNil(this.rebalancePrice) ||
          (this.rebalancePrice.isZero() &&
            !(this.isShortage() || this.isSurplus()))
        ) {
          flags.rebalanceUnavailable = true
        }

        if (this.option.isPut()) {
          if (_.get(durations, 'isExpired')) {
            poolPositionsValue = _.get(poolPositions, '1.humanized')
          } else {
            if (this.isSurplus()) {
              poolPositionsValue = _.get(poolPositions, '1.humanized').plus(
                unmintable.times(strike)
              )

              poolPositionsValue = poolPositionsValue.plus(this.rebalancePrice)
            } else if (this.isShortage() || flags.rebalanceUnavailable) {
              poolPositionsValue = _.get(poolPositions, '1.humanized').plus(
                unmintable.times(strike)
              )

              poolPositionsValue = poolPositionsValue.minus(this.rebalancePrice)

              poolPositionsValue = poolPositionsValue.plus(
                this.shortage.times(strike)
              )
            }
          }
        } else if (this.option.isCall()) {
          if (_.get(durations, 'isExpired')) {
            poolPositionsValue = _.get(poolPositions, '1.humanized')
          } else {
            if (this.isSurplus()) {
              poolPositionsValue = _.get(poolPositions, '1.humanized').plus(
                unmintable.times(market)
              )

              poolPositionsValue = poolPositionsValue.plus(this.rebalancePrice)
            } else if (this.isShortage() || flags.rebalanceUnavailable) {
              poolPositionsValue = _.get(poolPositions, '1.humanized').plus(
                unmintable.times(market)
              )

              poolPositionsValue = poolPositionsValue.minus(this.rebalancePrice)
              poolPositionsValue = poolPositionsValue.plus(
                this.shortage.times(market)
              )
            }
          }
        }

        if (
          poolPositionsValue.isLessThanOrEqualTo(
            new BigNumber(macros.MINIMUM_BALANCE_AMOUNT)
          )
        ) {
          poolPositionsValue = new BigNumber(0)
        }
      }

      const exits = price && !price.isZero() ? price.times(balance) : null

      return {
        poolPositions,
        poolPositionsValue,
        pnl,
        exits,
        flags
      }
    } else {
      const durations = _.attempt(() => this.option.getDurations())
      let poolPositionsValue = new BigNumber(0)

      if (_.get(durations, 'isExpired')) {
        poolPositionsValue = this.remainingTokensProvided
      }

      return {
        pnl,
        poolPositionsValue,
        flags
      }
    }
  }

  getSimulatedExercisePNL ({ strike, market, balance }) {
    let result
    if (market && !market.isZero() && strike && !strike.isZero()) {
      if (strike.isLessThanOrEqualTo(market)) result = new BigNumber(-1)
      else if (
        balance &&
        !balance.isZero() &&
        !balance.isLessThan(macros.MINIMUM_BALANCE_AMOUNT)
      ) {
        result = balance.times(strike.minus(market))
      }
    }
    return result
  }
}
