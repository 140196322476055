import { useEffect, useCallback } from 'react'
import _ from 'lodash'
import { useLocation, useHistory, matchPath } from 'react-router-dom'
import dayjs from 'dayjs'
import queryString from 'query-string'
import BigNumber from 'bignumber.js'
import reducers from '../reducers'
import { pages, SUPPORTED_UNDERLYING, SUPPORTED_COLLATERAL } from '../constants'

function defaults ({ pathname }) {
  const page = Object.values(pages).find(p =>
    matchPath(pathname, { path: p.route, exact: true })
  )

  const placeholder = 'Search active options ...'
  const context = {
    source: [
      {
        id: 'sell',
        route: pages.invest.route,
        title: 'Sell Option(s)',
        prefix: 'Sell Options of'
      },
      {
        id: 'buy',
        route: pages.hedge.route,
        title: 'Buy Option(s)',
        prefix: 'Buy Options of'
      },
      {
        id: 'pool',
        route: pages.pool.route,
        title: 'Manage liquidity',
        prefix: 'Manage'
      }
    ],
    isViewOnly: 'white'
  }

  switch (page.title) {
    case pages.invest.title: {
      context.value = context.source.find(s => s.id === 'sell')
      break
    }
    case pages.hedge.title: {
      context.value = context.source.find(s => s.id === 'buy')
      break
    }
    case pages.pool.title: {
      context.value = context.source.find(s => s.id === 'pool')
      break
    }
    case pages.dashboard.title:
    case pages.activityTransactions.title:
    default: {
      context.isViewOnly = false
      break
    }
  }

  return {
    page,
    placeholder,
    isPlaceholderLocked: false,
    context
  }
}

function initialize ({ elements, dispatch, pathname }) {
  const {
    placeholder,
    isPlaceholderLocked,

    context
  } = defaults({ pathname })

  dispatch([], 'RESET', [elements.allowance])

  dispatch(
    [
      [
        elements.placeholder,
        {
          value: placeholder,
          isLocked: isPlaceholderLocked
        }
      ],
      [elements.context, context],
      [
        elements.underlying,
        {
          source: SUPPORTED_UNDERLYING
        }
      ],

      [
        elements.collateral,
        {
          source: SUPPORTED_COLLATERAL
        }
      ]
    ],
    'MULTI_UPDATE'
  )
}

function refresh ({ reducer, location, history }) {
  const { elements, dispatch } = reducer
  const { pathname } = location

  initialize({
    elements,
    dispatch,
    pathname
  })

  if (history) {
    history.replace({
      pathname: location.pathname,
      search: ''
    })
  }
}

function modelQueryFromState ({ reducer, location, history }) {
  const { state } = reducer
  const page = Object.values(pages).find(p =>
    matchPath(location.pathname, { path: p.route, exact: true })
  )

  const isDynamic = [
    pages.hedge.title,
    pages.invest.title,
    pages.pool.title
  ].includes(page.title)

  const params = {}

  const underlying = _.get(state, 'underlying.value')
  const collateral = _.get(state, 'collateral.value')

  params.t = !_.isNilOrEmptyString(_.get(state, 'context.value.id'))
    ? _.get(state, 'context.value.id')
    : undefined

  params.u = underlying && underlying.length ? underlying.join(',') : undefined
  params.c = collateral && collateral.length ? collateral.join(',') : undefined

  params.s = !_.isNilOrEmptyString(_.get(state, 'strike.value'))
    ? _.get(state, 'strike.value')
    : undefined
  params.e = !_.isNilOrEmptyString(_.get(state, 'expiration.milliseconds'))
    ? dayjs(_.get(state, 'expiration.milliseconds')).format('YYYY-MM-DD')
    : undefined

  try {
    const search =
      _.isNil(params.u) &&
      _.isNil(params.c) &&
      _.isNil(params.s) &&
      _.isNil(params.e)
        ? ''
        : queryString.stringify(params)

    if (_.isNil(params.t)) return { search, travel: null }

    const travel = () => {
      if (isDynamic) {
        history.replace({
          pathname: _.get(state, 'context.value.route') || page.route,
          search
        })
      } else {
        history.push({
          pathname: _.get(state, 'context.value.route') || page.route,
          search
        })
      }
    }

    return { search, travel }
  } catch (e) {
    console.error('Search', e)
  }
}

function modelQueryToState ({ reducer, location, history }) {
  const { elements, dispatch } = reducer
  const { pathname, search } = location

  refresh({ reducer, location })

  const page = Object.values(pages).find(p =>
    matchPath(pathname, { path: p.route, exact: true })
  )
  const params = queryString.parse(search)
  const isDynamic = [
    pages.hedge.title,
    pages.invest.title,
    pages.pool.title
  ].includes(page.title)

  if (!isDynamic) {
    history.replace({
      pathname: location.pathname,
      search: null
    })
    return
  }

  const interpretedTokens = (source, supported) => {
    try {
      if (_.isNilOrEmptyString(source)) return null
      const sanitized = _.toString(source).replace(' ', '')
      const list =
        sanitized.indexOf(',') === -1 ? [sanitized] : sanitized.split(',')
      return list
        .map(element => _.toString(element).toUpperCase())
        .filter(queryToken => supported.includes(queryToken))
    } catch (e) {
      console.error('Interpret tokens', e)
    }

    return null
  }
  const interpretedDate = source => {
    try {
      if (_.isNilOrEmptyString(source)) return {}

      const formatted = dayjs(source, 'YYYY-MM-DD').format('YYYY-MM-DD')
      if (formatted !== source) return {}

      const milliseconds = dayjs(source, 'YYYY-MM-DD').valueOf()
      const value = source
      return {
        value,
        milliseconds
      }
    } catch (e) {
      console.error('Interpret date', e)
    }
    return {}
  }
  const interpretedContext = () => {
    const defaulted = defaults(location).context
    return defaulted.value
  }

  const interpretStrike = source => {
    if (_.isNilOrEmptyString(source)) return null
    return _.toString(source)
  }

  dispatch(
    [
      [
        elements.underlying,
        {
          value: interpretedTokens(_.get(params, 'u'), SUPPORTED_UNDERLYING)
        }
      ],
      [
        elements.collateral,
        {
          value: interpretedTokens(_.get(params, 'c'), SUPPORTED_COLLATERAL)
        }
      ],
      [
        elements.strike,
        {
          value: interpretStrike(_.get(params, 's'))
        }
      ],
      [
        elements.expiration,
        {
          ...interpretedDate(_.get(params, 'e'))
        }
      ],
      [
        elements.context,
        {
          value: interpretedContext(_.get(params, 't'))
        }
      ]
    ],
    'MULTI_DEEP_UPDATE'
  )
}

export function useSearchQuery () {
  const location = useLocation()
  const history = useHistory()

  return {
    refresh: reducer => refresh({ reducer, location, history }),
    modelFrom: reducer => modelQueryFromState({ reducer, location, history }),
    modelTo: reducer => modelQueryToState({ reducer, location, history })
  }
}

export function useSearchFilter () {
  const location = useLocation()
  const search = reducers.search.useReducer()
  const { modelTo } = useSearchQuery()

  useEffect(() => {
    modelTo(search)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const filter = useCallback(
    options => {
      let list = options
      const { state } = search

      if (state.underlying.value && state.underlying.value.length) {
        list = list.filter(option =>
          state.underlying.value.includes(
            _.get(option, 'underlying.symbol').toUpperCase()
          )
        )
      }

      if (state.collateral.value && state.collateral.value.length) {
        list = list.filter(option =>
          state.collateral.value.includes(
            _.get(option, 'strike.symbol').toUpperCase()
          )
        )
      }

      if (
        state.strike &&
        !_.isNilOrEmptyString(state.strike.value) &&
        new BigNumber(state.strike.value).isFinite()
      ) {
        list = list.filter(option =>
          new BigNumber(state.strike.value).isEqualTo(
            new BigNumber(_.get(option, 'strikePrice.humanized.humanized'))
          )
        )
      }

      if (state.expiration && _.isFinite(state.expiration.milliseconds)) {
        list = list.filter(
          option =>
            parseInt(state.expiration.milliseconds) / 1000 >=
              parseInt(_.get(option, 'exerciseStart')) - 24 * 60 * 60 &&
            parseInt(state.expiration.milliseconds) / 1000 <=
              parseInt(_.get(option, 'exerciseStart')) + 24 * 60 * 60
        )
      }

      return list
    },
    [search]
  )

  return filter
}
