import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { Emoji, TokenMultiDisplay } from '@pods-finance/components'
import { macros } from '@pods-finance/globals'
import { toNumeralPrice, toQuantity } from '@pods-finance/utils'
import {
  useOwnBalance,
  useMarketPrices,
  useOptionDynamics,
  useOptionInfo
} from '../../../../../hooks'

import Item from './Item'
import { pages } from '../../../../../constants'

import IconWallet from '@material-ui/icons/AccountBalanceWalletOutlined'
import IconFees from '@material-ui/icons/ReceiptRounded'

const WrapperPartial = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-radius: ${props => props.theme.sizes.edge};
  border: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.colors.white};
  padding: calc(${props => props.theme.sizes.edge} * 1.5) 0;
  padding-bottom: 0;
`

const Header = styled.div`
  width: 100%;
  padding: calc(${props => props.theme.sizes.edge} * 1.5)
    calc(${props => props.theme.sizes.edge} * 1);
  padding-top: 0;
  padding-bottom: ${props => props.theme.sizes.edge};
`

const Title = styled.p`
  margin: 0;
  font-size: 16pt;
  font-weight: 700;
  color: ${props => props.theme.colors.dark};
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1.5);
`

const Body = styled.div`
  width: 100%;
  padding: 0 calc(${props => props.theme.sizes.edge} * 1);

  &[data-loading='true'] {
    div[data-component='content'] {
      position: relative;
      & > * {
        opacity: 0;
      }
      &:before {
        content: '';
        width: 100px;
        height: 17px;
        position: absolute;
        right: 0;
        z-index: 0;
        border-radius: 4px;
        background-image: linear-gradient(
          -90deg,
          ${props => props.theme.colors.platform} 50%,
          ${props => props.theme.colors.white}
        );
      }
    }
  }
`

const Accented = styled.p`
  & > b {
    color: ${props => props.theme.colors.middle};
    font-weight: 700;
  }
`
const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1px;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.border};
  background: ${props => props.theme.colors.border};
  border-radius: 2px;
  & > * {
    background: ${props => props.theme.colors.white};
  }
`

const Missing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 430px;
  font-size: 40pt;
`

const Wrapper = styled(WrapperPartial)`
  ${props => props.theme.medias.small} {
    padding: calc(${props => props.theme.sizes.edge} * 1) 0;
    border-radius: 0;
  }
`

function InfoMissing () {
  return (
    <Wrapper>
      <Header>
        <Title>Your Position</Title>
      </Header>
      <Body>
        <Missing>
          <Emoji symbol='🛸' label='UFO' />
        </Missing>
      </Body>
    </Wrapper>
  )
}

function PoolInfo () {
  const {
    option,
    description,
    underlying,
    strike,
    tokenA,
    durations,
    isLoading: isLoadingOption,
    isRestricted
  } = useOptionInfo()

  const dynamics = useOptionDynamics()

  const isDynamicsLoading = useMemo(() => _.get(dynamics, 'isLoading'), [
    dynamics
  ])
  const price = useMemo(
    () => _.get(dynamics, 'abPrice.value.humanized') || null,
    [dynamics]
  )
  // const IV = useMemo(() => _.get(dynamics, 'IV.humanized') || null, [dynamics])
  const adjustedIV = useMemo(
    () => _.get(dynamics, 'adjustedIV.humanized') || null,
    [dynamics]
  )

  const isPriceRestricted = useMemo(() => {
    if (_.isNilOrEmptyString(price)) return true
    if (new BigNumber(price).isZero()) return true
    return false
  }, [price])

  const [userPositionTokenA, userPositionTokenB] = useMemo(
    () => [
      _.get(dynamics, 'userPositions.0.humanized'),
      _.get(dynamics, 'userPositions.1.humanized')
    ],
    [dynamics]
  )

  const [liquidityTokenA, liquidityTokenB] = useMemo(
    () => [
      _.get(dynamics, 'totalBalances.0.humanized'),
      _.get(dynamics, 'totalBalances.1.humanized')
    ],
    [dynamics]
  )

  const {
    value: balanceStrike,
    isLoading: isBalanceStrikeLoading
  } = useOwnBalance(strike)
  const {
    value: balanceOptions,
    isLoading: isBalanceOptionsLoading
  } = useOwnBalance(tokenA)

  const isLoading = useMemo(
    () =>
      isLoadingOption ||
      isBalanceOptionsLoading ||
      isBalanceStrikeLoading ||
      isDynamicsLoading,
    [
      isLoadingOption,
      isBalanceOptionsLoading,
      isBalanceStrikeLoading,
      isDynamicsLoading
    ]
  )

  // const { value: priceProperties } = useOptionPoolPriceProperties(option)

  const { spot } = useMarketPrices()
  const market = useMemo(() => _.get(spot, _.get(underlying, 'symbol')), [
    spot,
    underlying
  ])

  const available = useMemo(() => {
    const o = toQuantity(toNumeralPrice(balanceOptions, false), 'option')
    const s = `${toNumeralPrice(balanceStrike)} ${_.get(strike, 'alias')}`
    return `${o} and ${s}`
  }, [strike, balanceStrike, balanceOptions])

  const provided = useMemo(() => {
    const o = toQuantity(toNumeralPrice(userPositionTokenA, false), 'option')
    const s = `${toNumeralPrice(userPositionTokenB)} ${_.get(strike, 'alias')}`
    return `${o} and ${s}`
  }, [strike, userPositionTokenA, userPositionTokenB])

  const pooled = useMemo(() => {
    const o = toQuantity(toNumeralPrice(liquidityTokenA, false), 'option')
    const s = `${toNumeralPrice(liquidityTokenB)} ${_.get(strike, 'alias')}`
    return `${o} and ${s}`
  }, [strike, liquidityTokenA, liquidityTokenB])

  const fees = useMemo(() => {
    const feeA =
      _.get(dynamics, 'userFeeWithdrawAmounts.0.humanized') || new BigNumber(0)
    const feeB =
      _.get(dynamics, 'userFeeWithdrawAmounts.1.humanized') || new BigNumber(0)
    const total = feeA.plus(feeB)

    if (total.isLessThan(macros.MINIMUM_BALANCE_AMOUNT)) {
      if (
        !_.isNil(userPositionTokenA) &&
        !_.isNil(userPositionTokenB) &&
        userPositionTokenA.isZero() &&
        userPositionTokenB.isZero()
      ) {
        return 'Provide liquidity to earn fees.'
      }
      return 'Fees will accrue in time after trades.'
    }

    return toNumeralPrice(total)
  }, [dynamics, userPositionTokenA, userPositionTokenB])

  const poolSize = useMemo(
    () =>
      _.attempt(() => {
        if (
          _.isNil(option) ||
          _.isNil(liquidityTokenA) ||
          _.isNil(liquidityTokenB)
        ) {
          return '0'
        }

        if (option.isPut()) {
          return liquidityTokenB
            .plus(
              liquidityTokenA.multipliedBy(
                _.get(option, 'strikePrice.humanized')
              )
            )
            .toString()
        } else if (option.isCall()) {
          return liquidityTokenB
            .plus(liquidityTokenA.multipliedBy(new BigNumber(market)))
            .toString()
        }
        return '0'
      }),
    [liquidityTokenA, liquidityTokenB, option, market]
  )

  if (isRestricted) return <InfoMissing />

  return (
    <Wrapper>
      <Section>
        <Header>
          <Title
            data-option={_.get(option, 'address') || ''}
            data-pool={_.get(option, 'pool.address') || ''}
          >
            Position
          </Title>
        </Header>
        <Body data-loading={isLoading}>
          <Table>
            <Item
              columns={2}
              Icon={pages.pool.Icon}
              title='Pool liquidity'
              value={pooled}
              help={`Total locked liquidity: ${toNumeralPrice(poolSize)}`}
            />
            <Item
              columns={2}
              Icon={pages.pool.Icon}
              title='Your pool position'
              help='The amount of options and tokens you own in the pool. These are earning fees for you. Due to trading, these amounts will differ from what you initially provided.'
              hasAccent
            >
              <Accented>{provided}</Accented>
            </Item>
            <Item
              columns={2}
              Icon={IconFees}
              title='Your fees (included in position)'
              help="The amount of fees you earned and haven't withdrawn from the pool."
              hasAccent
            >
              <Accented>{fees}</Accented>
            </Item>
            <Item
              columns={2}
              Icon={IconWallet}
              title='In your wallet'
              value={available}
              help='Idle funds you have in your wallet that are eligible for the pool.'
            />
          </Table>
        </Body>
      </Section>
      <Section>
        <Header>
          <Title>Pool info</Title>
        </Header>
        <Body data-loading={isLoading}>
          <Table>
            <Item
              title='Asset pair'
              help='A pair of underlying asset and strike assets.'
              hasDim
            >
              <TokenMultiDisplay
                tokens={[underlying, strike]}
                isSelfPadded={false}
                isMini
                isTarget
              />
            </Item>
            <Item
              title='Option type'
              value={description.name}
              help={description.info}
              hasDim
            />
            <Item
              title='Exercise window'
              help='After expiry, during this window, you will be able to exercise your options.'
              value={_.get(durations, 'windowFormatted')}
              hasDim
            />
            <Item
              title='Expiration date'
              help={`At this moment, the exercise window will close for buyers. Exercise start: ${_.get(
                durations,
                'exerciseStartFormattedWithTime'
              )}.`}
              value={_.get(durations, 'expirationFormattedWithTime')}
              hasDim
            />
            <Item
              title='Strike price'
              value={toNumeralPrice(_.get(option, 'strikePrice.humanized'))}
              help='Price agreed upon for the underlying asset.'
              hasDim
            />
            <Item
              title={`Market price (${_.get(underlying, 'alias') || '~'})`}
              help='The current spot price of the asset.'
              value={toNumeralPrice(market)}
              hasDim
            />
            <Item
              columns={1}
              title='Option price'
              help={
                isPriceRestricted
                  ? 'Due to price being close to 0, some actions will be unavailable (Buying, Selling, Providing, Minting and Unminting)'
                  : 'Selling price (premium) for 1 option.'
              }
              hasDim
            >
              {_.get(durations, 'isExpired') ? (
                <p>
                  <i>Series ended</i>
                </p>
              ) : isPriceRestricted ? (
                <p>
                  <i>Price too close to $0</i>
                </p>
              ) : (
                <p>{toNumeralPrice(price)} / option</p>
              )}
            </Item>
            <Item
              title='Implied volatility'
              help='The current implied volatility.'
              value={`${
                adjustedIV
                  ? new BigNumber(adjustedIV).times(10 ** 2).toFixed(3)
                  : '~'
              }%`}
              hasDim
            />
          </Table>
        </Body>
      </Section>
    </Wrapper>
  )
}

export default PoolInfo
