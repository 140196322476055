import words from './words'

import {
  MAX_UINT,
  ENVIRONMENT,
  routes,
  macros,
  attributes,
  tokens,
  networks,
  subgraphed,
  etherscaned
} from '@pods-finance/globals'

export * from './guarded'
export * from './addresses'
export { default as events } from './events'
export {
  attributes,
  attributes as types,
  macros,
  tokens,
  networks,
  subgraphed,
  etherscaned,
  words,
  MAX_UINT,
  ENVIRONMENT
}

export const { pages, tabs } = routes.frontend

export const modals = {
  transaction: 'transaction',
  termsOfService: 'termsOfService',
  activityItem: 'activityItem',
  restrictedService: 'restrictedService',
  fullBreakdown: 'fullBreakdown',
  risk: 'risk',
  lag: 'lag',
  guardTVL: 'guardTVL'
}

export const prefs = {
  isTermsOfServiceAccepted: 'isTermsOfServiceAccepted',
  isAnalyticsEnabled: 'isAnalyticsEnabled',
  isDarkTheme: 'isDarkTheme',
  isAdvancedModeEnabled: 'isAdvancedModeEnabled',
  isClassificationCall: 'isClassificationCall'
}

export const features = {
  calls: [true, 'true'].includes(process.env.REACT_APP_FEATURE_CALLS_ON),
  ipfs: [true, 'true'].includes(process.env.REACT_APP_FEATURE_ROUTE_IPFS)
}

export const SUPPORTED_UNDERLYING = ['WBTC', 'ETH', 'SUSHI', 'MATIC', 'LINK']
export const SUPPORTED_COLLATERAL = ['ADAI', 'AUSDC', 'DAI', 'USDC']
