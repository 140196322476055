import _ from 'lodash'
import React, { useMemo } from 'react'
import numeral from 'numeral'
import IconMarket from '@material-ui/icons/TimelineRounded'
import { useHistory } from 'react-router-dom'
import { attributes } from '@pods-finance/globals'

import { pages } from '../../constants'

import { useOptions, useOptionsDynamics } from '../data'
import { useMarketPrices } from '../price'
import {
  sortOptionsTableByExpiration,
  sortOptionsTableByState,
  toNumeralPrice
} from '@pods-finance/utils'
import BigNumber from 'bignumber.js'

export default function useInvestTable ({ filter }) {
  const history = useHistory()
  const { spot } = useMarketPrices()
  const { options, isLoading: isLoadingOptions, count } = useOptions(true)
  const dynamics = useOptionsDynamics()

  const filtered = useMemo(() => filter(options), [options, filter])

  const columns = useMemo(
    () => [
      {
        title: 'Underlying',
        layout: attributes.layout.cell.PodAsset,
        subtitle: 'Asset'
      },
      {
        title: 'Strike',
        layout: attributes.layout.cell.PodAsset,
        subtitle: 'Asset'
      },
      {
        title: 'Strike Price',
        layout: attributes.layout.cell.Price,
        subtitle: (
          <>
            Market Price <IconMarket />
          </>
        )
      },
      {
        title: 'Exercise Day',
        layout: attributes.layout.cell.Timestamp
      },
      {
        title: 'Premium',
        layout: attributes.layout.cell.Reward
      },
      {
        title: 'Type',
        layout: attributes.layout.cell.Text
      },
      {
        title: '',
        layout: attributes.layout.cell.Actions
      }
    ],
    []
  )

  const instructions = useMemo(
    () => ({
      onRowClick: params => {
        if (_.get(params, 'id')) {
          history.push(pages.transactionInvest.builder(_.get(params, 'id')))
        }
      }
    }),
    [history]
  )

  const rows = useMemo(
    () =>
      filtered
        .map(option => {
          const durations = option.getDurations()
          const market = _.get(spot, option.underlying.symbol.toUpperCase())
          const price = _.get(
            dynamics,
            `${option.address}.sellingPrice.value.humanized`
          )

          const bundle = _.get(
            dynamics,
            `${option.address}.userOptionWithdrawAmounts`
          )
          const withdrawable =
            bundle && bundle.length
              ? _.get(bundle, '0.humanized').plus(_.get(bundle, '1.humanized'))
              : new BigNumber(0)

          return {
            id: option.address,
            cells: [
              {
                value: option.underlying,
                type: attributes.action.invest
              },
              {
                value: option.strike,
                type: attributes.action.invest
              },
              {
                value: numeral(option.strikePrice.humanized).format(
                  '$0.[0000]'
                ),
                market: numeral(market).format('$0.[00]')
              },
              {
                value: durations.exerciseStart,
                split: true
              },
              {
                value: price && toNumeralPrice(price.toString()),
                durations,
                isLoading: dynamics.isLoading
              },

              {
                value: option.isPut() ? 'Put' : 'Call'
              },
              {
                durations,
                value: option.address,
                amount: withdrawable,
                type: attributes.action.invest,
                source: pages.invest.builder()
              }
            ]
          }
        })
        .sort(sortOptionsTableByExpiration)
        .sort(sortOptionsTableByState),

    [filtered, spot, dynamics]
  )

  return {
    data: {
      columns,
      rows,
      instructions,
      /** Cosmetics */
      isLoading: isLoadingOptions,
      expected: count
    },
    info: {
      total: _.get(options, 'length'),
      filtered: _.get(filtered, 'length')
    }
  }
}
