import _ from 'lodash'
import { useMemo } from 'react'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { networks, subgraphed } from '@pods-finance/globals'

function apollofy (key) {
  return new ApolloClient({
    link: new HttpLink({
      uri: subgraphed(key)
    }),
    cache: new InMemoryCache(),
    shouldBatch: true
  })
}

export const client = networks._supported.reduce((result, key) => {
  result[key] = apollofy(key)
  return result
}, {})

export function getClient (networkId) {
  return _.get(client, networkId)
}

export function useClient (networkId) {
  const item = useMemo(
    () => ({
      engine: getClient(networkId),
      networkId
    }),
    [networkId]
  )

  return item
}
