import _ from 'lodash'
import React, { useMemo, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import {
  Label,
  Input,
  Step,
  Container as FormPartial
} from '@pods-finance/components'

import { ContentBox, ContentSection, ContentSummary } from '../../../Elements'

import { SpecialButton, Incentive } from '../../../../../common'

import reducers from '../../../../../../reducers'
import machines from '../../../../../../machines'
import { tabs } from '../../../../../../constants'

import {
  useOptionInfo,
  useOptionDynamics,
  useOwnBalance,
  useTransactionSetup,
  useFormValidator,
  useFormPairAllowance,
  useNetworkId
} from '../../../../../../hooks'

import logic from './logic'

const Wrapper = styled.div`
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 3 / 2);
    &:last-child {
      margin-bottom: 0;
    }
  }
`
const Form = styled(FormPartial)`
  max-width: 538px;

  div[data-step='actions'] {
    max-width: 100%;
    & > div:first-child {
      min-width: 270px;
    }
  }
`

const Info = styled.p`
  white-space: nowrap;
  ${props => props.theme.medias.medium} {
    white-space: normal;
    font-size: 9pt;
  }
`

function AddCall () {
  /**
   * --------------------------------------
   * Required data and utilities
   * --------------------------------------
   */
  const machine = machines.addCall.useMachine()
  const setup = useTransactionSetup()
  const networkId = useNetworkId()
  const { elements, state, dispatch } = reducers.addCall.useReducer()
  const dynamics = useOptionDynamics()
  const {
    option,
    helper,
    underlying,
    collateral,
    strike,
    durations,
    isLoading: isOptionLoading
  } = useOptionInfo()

  const {
    value: balanceStrike,
    isLoading: isBalanceStrikeLoading
  } = useOwnBalance(strike)

  const {
    value: balanceCollateral,
    isLoading: isBalanceCollateralLoading
  } = useOwnBalance(collateral)

  const abPrice = useMemo(() => _.get(dynamics, 'abPrice.value.humanized'), [
    dynamics
  ])

  const isLoading = useMemo(
    () =>
      isOptionLoading ||
      isBalanceStrikeLoading ||
      isBalanceCollateralLoading ||
      dynamics.isLoading,
    [
      isOptionLoading,
      isBalanceStrikeLoading,
      isBalanceCollateralLoading,
      dynamics.isLoading
    ]
  )

  const { isValid: isFormValid, isAllowed: isFormAllowed } = useFormValidator({
    state,
    machine
  })

  const isAvailable = useMemo(() => !_.isNil(abPrice) && !abPrice.isZero(), [
    abPrice
  ])

  /**
   * --------------------------------------
   *  Methods and state updaters
   * --------------------------------------
   */

  const doAllowanceUpdate = useCallback(
    ({ tokenA: collateral, tokenB: strike, isLoading }) => {
      if (_.isNil(strike)) {
        dispatch([
          elements.allowance,
          { collateral: !!collateral, isLoadingCollateral: isLoading }
        ])
      } else {
        dispatch([
          elements.allowance,
          { strike: !!strike, isLoadingStrike: isLoading }
        ])
      }
    },
    [elements, dispatch]
  )

  const onChangeAmount = useCallback(
    amount =>
      logic.onChangeAmount({
        amount,
        dispatch,
        elements,
        option,
        abPrice,
        balanceStrike,
        balanceCollateral
      }),
    [dispatch, elements, option, abPrice, balanceStrike, balanceCollateral]
  )

  const onTransact = useCallback(() => {
    if (_.get(setup, 'guardTVL.isDisabled')) setup.guardTVL.show()
    else logic.onTransact({ machine, state, option, setup })
  }, [machine, state, option, setup])

  const {
    doApproveTokenA: doAllowanceApproveCollateral,
    doApproveTokenB: doAllowanceApproveStrike,
    doRefreshTokenA: doAllowanceRefreshCollateral,
    doRefreshTokenB: doAllowanceRefreshStrike
  } = useFormPairAllowance({
    tokenA: collateral,
    tokenB: strike,

    amountTokenA: _.get(state, 'collateral.value'),
    amountTokenB: _.get(state, 'strike.value'),

    spender: _.get(helper, 'address'),
    onUpdate: doAllowanceUpdate
  })

  useEffect(() => {
    if (!isLoading) {
      logic.onInitialize({
        elements,
        dispatch,
        collateral,
        strike,
        balanceStrike,
        balanceCollateral
      })

      doAllowanceRefreshCollateral()
      doAllowanceRefreshStrike()
    }
  }, [
    elements,
    dispatch,
    isLoading,
    collateral,
    strike,
    balanceStrike,
    balanceCollateral,
    doAllowanceRefreshCollateral,
    doAllowanceRefreshStrike
  ])

  return (
    <ContentBox hash={[tabs.pool.add]} isLoading={isLoading}>
      <Wrapper>
        <ContentSection
          title='Add liquidity with collateral and stables'
          isContained
          isDisabled={
            [machine.states.validate, machine.states.process].includes(
              machine.current.value
            ) ||
            (!isLoading && !isAvailable)
          }
        >
          <Form>
            <Step>
              <Label>Step 1. Collateral to provide</Label>
              <Input.Amount
                {...state.collateral}
                placeholder='How much collateral?'
                networkId={networkId}
                onChange={e => onChangeAmount(_.get(e, 'target.value'))}
              />
              <Step>
                <Info>Used to mint</Info>
                <Input.Amount
                  {...state.collateral}
                  token={[underlying, strike]}
                  placeholder='0'
                  networkId={networkId}
                  onChange={() => {}}
                  isPrimary={false}
                  max={null}
                  isViewOnly
                />
              </Step>
            </Step>
            <Step>
              <Label>Step 2. Stables to provide (auto)</Label>
              <Input.Amount
                {...state.strike}
                placeholder='How many stables (auto)?'
                networkId={networkId}
                isViewOnly
              />
            </Step>
            <ContentSummary
              index={3}
              context={tabs.pool.add}
              data={{
                state,
                option,
                durations
              }}
              allow={
                <SpecialButton.AllowPair
                  amountTokenA={_.get(state, 'collateral.value')}
                  amountTokenB={_.get(state, 'strike.value')}
                  titleTokenA={`Allow ${_.get(collateral, 'alias')}`}
                  titleTokenB={`Allow ${_.get(strike, 'alias')}`}
                  isAllowedTokenA={_.get(state, 'allowance.collateral')}
                  isAllowedTokenB={_.get(state, 'allowance.strike')}
                  isLoadingTokenA={_.get(
                    state,
                    'allowance.isLoadingCollateral'
                  )}
                  isLoadingTokenB={_.get(state, 'allowance.isLoadingStrike')}
                  onApproveTokenA={doAllowanceApproveCollateral}
                  onApproveTokenB={doAllowanceApproveStrike}
                />
              }
              transact={
                <SpecialButton.Transact
                  title='Add Liquidity'
                  isDisabled={!isFormValid}
                  isAllowed={isFormAllowed}
                  isLoading={[
                    machine.states.validate,
                    machine.states.process
                  ].includes(machine.current.value)}
                  onClick={onTransact}
                />
              }
            />
          </Form>
        </ContentSection>
        {!isLoading && !isAvailable ? (
          <ContentSection title='Note about providing liquidity'>
            <Incentive symbol='😬' isSelfPadded={false}>
              <p>
                The price for this options is too low, so the AMM will not
                receive any more new liquidity for now.
              </p>
            </Incentive>
          </ContentSection>
        ) : null}
      </Wrapper>
    </ContentBox>
  )
}

export default AddCall
