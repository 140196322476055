import _ from 'lodash'
import React, { useMemo } from 'react'
import numeral from 'numeral'
import BigNumber from 'bignumber.js'
import { useHistory } from 'react-router-dom'
import Theme from '@pods-finance/themes'
import { attributes } from '@pods-finance/globals'
import {
  sortOptionsTableByExpiration,
  sortOptionsTableByState,
  toQuantity,
  toNumeralPrice
} from '@pods-finance/utils'

import IconMarket from '@material-ui/icons/TimelineRounded'
import { pages } from '../../constants'
import { useOptions, useOptionsDynamics } from '../data'
import { useMarketPrices } from '../price'
import { useAccount } from '../web3'

export default function usePoolsTable ({ filter }) {
  const history = useHistory()
  const { isConnected } = useAccount()
  const { spot } = useMarketPrices()
  const { options, isLoading: isLoadingOptions, count } = useOptions(true)
  const dynamics = useOptionsDynamics()

  const filtered = useMemo(() => filter(options), [options, filter])

  const columns = useMemo(
    () => [
      {
        title: 'Option Token',
        layout: attributes.layout.cell.PodPair,
        subtitle: 'Asset #1',
        weight: 2
      },
      {
        title: 'Stable Token',
        layout: attributes.layout.cell.PodAsset,
        subtitle: 'Asset #2',
        weight: 2
      },
      {
        title: 'Strike',
        layout: attributes.layout.cell.Price,
        subtitle: (
          <>
            Market Price <IconMarket />
          </>
        ),
        weight: 2
      },
      {
        title: 'Exercise Day',
        layout: attributes.layout.cell.Timestamp,
        weight: 2
      },
      {
        title: 'Wallet Balance',
        layout: attributes.layout.cell.Text,
        weight: 2
      },
      {
        title: 'Your Liquidity',
        layout: attributes.layout.cell.Text,
        weight: 2
      },
      {
        title: 'Pool Liquidity',
        layout: attributes.layout.cell.Text,
        weight: 2
      },
      {
        title: '',
        layout: attributes.layout.cell.Actions,
        weight: 2
      }
    ],
    []
  )

  const instructions = useMemo(
    () => ({
      onRowClick: params => {
        if (_.get(params, 'id')) {
          history.push(pages.transactionPool.builder(_.get(params, 'id')))
        }
      }
    }),
    [history]
  )

  const rows = useMemo(
    () =>
      filtered
        .map(option => {
          const pool = option.pool
          const durations = option.getDurations()
          const market = _.get(spot, option.underlying.symbol.toUpperCase())

          const liquidity = _.get(dynamics, `${option.address}.totalBalances`)
          const positions = _.get(dynamics, `${option.address}.userPositions`)
          const balance = _.get(dynamics, `${option.address}.userOptionBalance`)

          const strikePrice = _.get(option, 'strikePrice.humanized')

          const liquiditySize = _.attempt(() => {
            if (option.isPut()) {
              return _.get(liquidity, '1.humanized')
                .plus(_.get(liquidity, '0.humanized').times(strikePrice))
                .toNumber()
            } else if (option.isCall()) {
              return _.get(liquidity, '1.humanized')
                .plus(
                  _.get(liquidity, '0.humanized').times(new BigNumber(market))
                )
                .toNumber()
            }
          })

          return {
            id: option.address,
            cells: [
              {
                value: [option.underlying, option.strike],
                type: attributes.action.pool
              },
              {
                value: pool.tokenB,
                type: attributes.action.pool
              },
              {
                value: numeral(option.strikePrice.humanized).format(
                  '$0.[0000]'
                ),
                market: numeral(market).format('$0.[00]')
              },
              {
                value: durations.exerciseStart,
                split: true
              },
              {
                value: toQuantity(
                  toNumeralPrice(_.get(balance, 'humanized'), false),
                  'option'
                ),
                isLoading: dynamics.isLoading,
                isUnknown: !isConnected
              },
              {
                value: [
                  toQuantity(
                    toNumeralPrice(_.get(positions, '0.humanized'), false),
                    'option'
                  ),
                  `${toNumeralPrice(_.get(positions, '1.humanized'), false)} ${
                    pool.tokenB.alias
                  }`
                ],
                isLoading: dynamics.isLoading,
                isUnknown: !isConnected
              },
              {
                helper: [
                  toQuantity(
                    toNumeralPrice(_.get(liquidity, '0.humanized'), false),
                    'option'
                  ),
                  `${toNumeralPrice(_.get(liquidity, '1.humanized'), false)} ${
                    pool.tokenB.alias
                  }`
                ].join(', '),
                isLoading: dynamics.isLoading,
                value: dynamics.isLoadingGeneralDynamics
                  ? 'Loading'
                  : toNumeralPrice(liquiditySize)
              },
              {
                durations,
                value: option.address,
                type: attributes.action.pool,
                source: pages.pool.builder()
              }
            ]
          }
        })
        .sort(sortOptionsTableByExpiration)
        .sort(sortOptionsTableByState),
    [filtered, spot, dynamics, isConnected]
  )

  return useMemo(
    () => ({
      data: {
        columns,
        rows,
        instructions,
        /** Cosmetics */
        isLoading: isLoadingOptions,
        expected: count,
        theme: Theme.attributes.table.theme.slim
      },
      info: {
        total: _.get(options, 'length'),
        filtered: isLoadingOptions
          ? _.get(options, 'length')
          : _.get(filtered, 'length')
      }
    }),
    [columns, rows, instructions, isLoadingOptions, count, options, filtered]
  )
}
