import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useLocation, useHistory } from 'react-router-dom'
import { macros, tabs, types } from '../../../constants'

import {
  useOwnBalance,
  useToken,
  useOptionDynamics,
  useOptionInfo
} from '../../../hooks'
import { toNumeralPrice } from '@pods-finance/utils'

import DecoratorPrimary from '../../../assets/decorators/pods_tablets_primary.png'
import DecoratorDark from '../../../assets/decorators/pods_tablets_dark.png'

const WrapperPartial = styled.div``

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: calc(${props => props.theme.sizes.edge} * 1 / 2);
  border: 1px solid ${props => props.theme.colors.border};
  overflow: hidden;
  min-height: 67px;
`

const Asset = styled.div`
  margin-left: -32px;
  max-height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  & > img {
    height: 120px;
    width: 120px;
    object-fit: contain;
  }
`

const Content = styled.div`
  padding: calc(${props => props.theme.sizes.edge} * 1)
    calc(${props => props.theme.sizes.edge} * 1.5);
  min-height: calc(17px + ${props => props.theme.sizes.edge} * 1);
  flex: 1;
  line-height: 1.5;
  p {
    font-size: ${props => props.theme.sizes.text};
    font-weight: 500;
    margin: 0;
    color: ${props => props.theme.colors.dark};
    line-height: 1.5;
  }

  b {
    font-weight: 700;
    margin: 0;
    color: ${props => props.theme.colors.dark};
  }
`

const Information = styled.p`
  padding: calc(${props => props.theme.sizes.edge} * 0.5) 0;
`

const SuggestionContent = styled.p`
  font-size: ${props => props.theme.sizes.text};
  color: ${props => props.theme.colors.dark};
  margin: 0;
  line-height: 1.5;
  font-weight: 500;
  & > b {
    font-weight: 700;
  }
  & > a,
  & > span {
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
  }
`

const Loader = styled.div`
  height: calc(17px + ${props => props.theme.sizes.edge} * 1);

  width: 100%;
  border-radius: 4px;
  background-image: linear-gradient(
    -90deg,
    ${props => props.theme.colors.platform} 50%,
    ${props => props.theme.colors.white}
  );
`

const Wrapper = styled(WrapperPartial)`
  ${props => props.theme.medias.medium} {
    width: 100%;

    ${Asset} {
      margin-left: -28px;
      & > img {
        height: 80px;
        width: 80px;
      }
    }
  }
`

function SuggestionInvest ({ durations }) {
  const location = useLocation()
  const history = useHistory()
  return (
    <Wrapper>
      <SuggestionContent>
        On this pair, you have no active options.{' '}
        {_.get(durations, 'isTrading') && (
          <>
            You should check the{' '}
            <span
              onClick={() => {
                history.replace({
                  pathname: location.pathname,
                  hash: tabs.invest.write
                })
              }}
            >
              Write
            </span>{' '}
            tab.
          </>
        )}
      </SuggestionContent>
    </Wrapper>
  )
}

function SuggestionHedge ({ durations }) {
  const location = useLocation()
  const history = useHistory()
  return (
    <Wrapper>
      <SuggestionContent>
        On this pair, you have no available options.{' '}
        {_.get(durations, 'isTrading') && (
          <>
            You should acquire some in the{' '}
            <span
              onClick={() =>
                history.replace({
                  pathname: location.pathname,
                  hash: tabs.hedge.buy
                })}
            >
              Buy
            </span>{' '}
            tab.
          </>
        )}
      </SuggestionContent>
    </Wrapper>
  )
}

function ExplainerInvest () {
  const { get: resolve } = useToken()
  const { option, durations, isLoading, isReady } = useOptionInfo()
  const dynamics = useOptionDynamics()

  const underlying = useMemo(() => resolve(_.get(option, 'underlying')), [
    option,
    resolve
  ])

  const strike = useMemo(() => resolve(_.get(option, 'strike')), [
    option,
    resolve
  ])

  const [amountUnderlying, amountStrike] = useMemo(
    () => [
      _.get(dynamics, 'userOptionWithdrawAmounts.0.humanized'),
      _.get(dynamics, 'userOptionWithdrawAmounts.1.humanized')
    ],
    [dynamics]
  )

  if (
    !isLoading &&
    (!isReady ||
      (amountUnderlying.isZero() && amountStrike.isZero()) ||
      (amountUnderlying.isLessThan(macros.MINIMUM_BALANCE_AMOUNT) &&
        amountStrike.isLessThan(macros.MINIMUM_BALANCE_AMOUNT)))
  ) {
    return <SuggestionInvest durations={durations} />
  }

  return (
    <Wrapper>
      <Box>
        <Asset>
          <img src={DecoratorPrimary} alt='' />
        </Asset>
        <Content>
          {isLoading ? (
            <Loader />
          ) : (
            <Information>
              You can currently withdraw from your{' '}
              <b>
                {_.get(underlying, 'alias')}:{_.get(strike, 'alias')}
              </b>{' '}
              position:{' '}
              <b>
                {toNumeralPrice(amountStrike)} {_.get(strike, 'alias')}
              </b>{' '}
              and{' '}
              <b>
                {toNumeralPrice(amountUnderlying, false)}{' '}
                {_.get(underlying, 'alias')}
              </b>
              .
            </Information>
          )}
        </Content>
      </Box>
    </Wrapper>
  )
}

function ExplainerHedge () {
  const { get: resolve } = useToken()
  const { option, durations, isLoading, isReady } = useOptionInfo()
  const {
    value: balanceOptions,
    isLoading: isBalanceOptionsLoading
  } = useOwnBalance({
    address: _.get(option, 'address'),
    decimals: _.get(option, 'decimals')
  })

  const underlying = useMemo(() => resolve(_.get(option, 'underlying')), [
    option,
    resolve
  ])

  const strike = useMemo(() => resolve(_.get(option, 'strike')), [
    option,
    resolve
  ])

  if (
    !isLoading &&
    (!isReady ||
      balanceOptions.isZero() ||
      balanceOptions.isLessThan(macros.MINIMUM_BALANCE_AMOUNT))
  ) {
    return <SuggestionHedge durations={durations} />
  }

  return (
    <Wrapper>
      <Box>
        <Asset>
          <img src={DecoratorDark} alt='' />
        </Asset>
        <Content>
          {isLoading || isBalanceOptionsLoading ? (
            <Loader />
          ) : (
            <Information>
              {option && option.isPut()
                ? 'Acquired hedge (the right to sell) for'
                : 'Acquired the right to buy'}{' '}
              <b>
                {_.get(underlying, 'alias')}{' '}
                {toNumeralPrice(balanceOptions, false)}
              </b>{' '}
              with options of{' '}
              <b>
                {_.get(underlying, 'alias')}:{_.get(strike, 'alias')}
              </b>
              .
            </Information>
          )}
        </Content>
      </Box>
    </Wrapper>
  )
}

function Explainer ({ action, ...props }) {
  return action === types.action.invest ? (
    <ExplainerInvest {...props} />
  ) : (
    <ExplainerHedge {...props} />
  )
}

Explainer.propTypes = {
  action: PropTypes.oneOf([types.action.hedge, types.action.invest]),
  data: PropTypes.oneOfType([
    PropTypes.shape({
      locked: PropTypes.number,
      collateral: PropTypes.string,
      balance: PropTypes.number,
      premium: PropTypes.number
    }),
    PropTypes.shape({
      underlying: PropTypes.string,
      balance: PropTypes.number,
      premium: PropTypes.number
    })
  ]).isRequired
}

Explainer.defaultProps = {
  action: types.action.invest,
  data: {
    locked: 0,
    balance: 0,
    premium: 0
  }
}

export default Explainer
