import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import SectionBase from '../../../common/Section'
import Reminder, { ReminderEmpty } from './Reminder'

import { usePositionsValues, usePositionsReminders } from '../../../../hooks'

const WrapperPartial = styled.div`
  padding: 0;
  margin-top: calc(${props => props.theme.sizes.edge} * 1);
  background: ${props =>
    props.theme.isDark
      ? props.theme.colors.platform
      : props.theme.colors.darkAbsolute};
`
const Card = styled.div`
  width: 100%;
  background: ${props =>
    props.theme.isDark
      ? props.theme.colors.platform
      : props.theme.colors.darkAbsolute};
  border-radius: 0;
  padding: calc(${props => props.theme.sizes.edge} * 3) 0;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
`

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 ${props => props.theme.sizes.layoutEdge};
  min-height: 200px;
  & > div {
    width: 100%;
  }
`

const Section = styled(SectionBase)`
  position: relative;
  z-index: 200;
  margin: 0;
  *[data-component='header'] > {
    *[data-component='title'] > p,
    *[data-component='description'] {
      color: ${props => props.theme.colors.whiteAbsolute};
    }
  }
`

const Wrapper = styled(WrapperPartial)`
  ${props => props.theme.medias.medium} {
    padding: 0 calc(${props => props.theme.sizes.layoutEdgeMedium});
    padding-top: calc(${props => props.theme.sizes.edge} * 2);
    margin-bottom: 0;
    ${Card} {
      padding: calc(${props => props.theme.sizes.edge} * 0);
      border-radius: calc(${props => props.theme.sizes.edge} * 1 / 2);
    }
    ${Content} {
      padding: 0;
    }
    ${Section} {
      padding: 0;
      *[data-component='header'] {
        padding: 0;
      }
    }
  }

  &[data-hidden='true'] {
    ${Card} {
      display: none;
    }
  }
`

function Reminders () {
  const { positions: list, isLoading } = usePositionsValues()
  const _items = usePositionsReminders()

  const items = useMemo(() => {
    if (isLoading) return []
    else {
      return _items.map(item => ({
        pair: _.get(item, 'pair'),
        size: _.get(item, 'size'),
        type: _.get(item, 'type'),
        date: _.get(item, 'date'),
        start: _.get(item, 'start'),
        Elements: () => (
          <>
            {_.get(item, 'type') === 'exercise' && (
              <p>
                For <b>{_.get(item, 'pair')}</b> come back to exercise your
                options.
              </p>
            )}
            {_.get(item, 'type') === 'invest' && (
              <p>
                For <b>{_.get(item, 'pair')}</b>, come back after expiration to
                withdraw unused collateral and exercised assets.
              </p>
            )}
            {_.get(item, 'type') === 'pool' && (
              <p>
                For <b>{_.get(item, 'pair')}</b>, come back to remove liquidity,
                claim fees or exercise any option surplus.
              </p>
            )}
          </>
        )
      }))
    }
  }, [_items, isLoading])

  return (
    <Wrapper>
      <Card>
        <Section
          title='Your reminders'
          description='Remember to return to the app to exercise or withdraw funds. Click on the cards to set a calendar reminder for each event.'
        >
          <Content>
            {!isLoading && !list.length ? (
              <ReminderEmpty />
            ) : (
              <ResponsiveMasonry
                columnsCountBreakPoints={{
                  992: 4,
                  768: 2,
                  576: 1
                }}
              >
                <Masonry gutter='8px' columnsCount={4}>
                  {items.map(item => (
                    <Reminder {...item} key={_.get(item, 'date')}>
                      <item.Elements />
                    </Reminder>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            )}
          </Content>
        </Section>
      </Card>
    </Wrapper>
  )
}

export default Reminders
