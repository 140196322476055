import _ from 'lodash'
import React, { useMemo, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { ContentBox, ContentSection, ContentSummary } from '../../../Elements'
import {
  Label,
  Input,
  Step,
  Container as FormPartial
} from '@pods-finance/components'

import { Incentive, SpecialButton } from '../../../../../common'

import reducers from '../../../../../../reducers'
import machines from '../../../../../../machines'
import { tabs } from '../../../../../../constants'

import {
  useOptionInfo,
  useOptionDynamics,
  useOwnBalance,
  useTransactionSetup,
  useFormValidator,
  useNetworkId
} from '../../../../../../hooks'

import logic from './logic'
import BigNumber from 'bignumber.js'

const Wrapper = styled.div`
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 3 / 2);
    &:last-child {
      margin-bottom: 0;
    }
  }
`
const Form = styled(FormPartial)`
  max-width: 538px;
  div[data-step='actions'] {
    max-width: 100%;
    & > div:first-child {
      min-width: 226px;
    }
  }
`

const Info = styled.p``

function Unmint () {
  /**
   * --------------------------------------
   * Required data and utilities
   * --------------------------------------
   */
  const machine = machines.unmint.useMachine()
  const setup = useTransactionSetup()
  const networkId = useNetworkId()
  const dynamics = useOptionDynamics()
  const { elements, state, dispatch } = reducers.unmint.useReducer()
  const {
    option,
    tokenA,
    collateral,
    underlying,
    strike,
    durations,
    isLoading: isOptionLoading
  } = useOptionInfo()

  const {
    value: balanceOptions,
    isLoading: isBalanceOptionsLoading
  } = useOwnBalance(tokenA)

  const mintedOptions = useMemo(
    () => _.get(dynamics, 'userOptionMintedAmount.humanized'),
    [dynamics]
  )

  const isLoading = useMemo(
    () => isOptionLoading || isBalanceOptionsLoading || dynamics.isLoading,
    [isOptionLoading, isBalanceOptionsLoading, dynamics.isLoading]
  )

  const unmintableOptions = useMemo(() => {
    return BigNumber.min(balanceOptions, mintedOptions)
  }, [balanceOptions, mintedOptions])

  const { isValid: isFormValid, isAllowed: isFormAllowed } = useFormValidator({
    state,
    machine
  })

  /**
   * --------------------------------------
   *  Methods and state updaters
   * --------------------------------------
   */

  const onPrimaryRefocus = useCallback(
    isCollateralPrimary =>
      logic.onPrimaryRefocus({
        isCollateralPrimary,
        dispatch,
        elements,
        state
      }),
    [dispatch, elements, state]
  )

  const onChangeAmount = useCallback(
    (options = null, collateral = null) =>
      logic.onChangeAmount({
        amounts: {
          options,
          collateral
        },
        dispatch,
        elements,
        option,
        unmintableOptions
      }),
    [dispatch, elements, option, unmintableOptions]
  )

  const onTransact = useCallback(
    () => logic.onTransact({ machine, state, option, setup }),
    [machine, state, option, setup]
  )

  useEffect(() => {
    if (!isLoading) {
      logic.onInitialize({
        elements,
        dispatch,
        collateral,
        underlying,
        strike,
        unmintableOptions
      })
    }
  }, [
    elements,
    dispatch,
    isLoading,
    collateral,
    underlying,
    strike,
    unmintableOptions
  ])

  return (
    <>
      <ContentBox
        hash={[tabs.pool.remove, tabs.pool.unmint]}
        isLoading={isLoading}
      >
        <Wrapper>
          <ContentSection
            title='Section 2: Manage surplus of options'
            isDisabled={false}
          >
            <Incentive symbol='⚖️' isSelfPadded={false}>
              <p>
                Received a different amount of option tokens and stablecoins
                from what you initially provided? To completely exit the
                position you have to manage this surplus/shortage to get back to
                your initial amounts. Compare the amount of option tokens and
                stablecoins you provided with the amount you removed.
              </p>
            </Incentive>
          </ContentSection>
        </Wrapper>
      </ContentBox>
      <ContentBox
        hash={[tabs.pool.remove, tabs.pool.unmint]}
        isLoading={isLoading}
      >
        <Wrapper>
          <ContentSection
            title='Section 3: Unmint option tokens to unlock collateral'
            isContained
            isDisabled={[
              machine.states.validate,
              machine.states.process
            ].includes(machine.current.value)}
          >
            <Form>
              <Step>
                <Label>Step 3.1: Options to unlock</Label>
                <Input.Amount
                  {...state.options}
                  placeholder='Enter amount'
                  networkId={networkId}
                  onChange={e => onChangeAmount(_.get(e, 'target.value'), null)}
                  onClick={() => onPrimaryRefocus(false)}
                />

                <Step>
                  <Info style={{ whiteSpace: 'nowrap' }}>Will unlock</Info>
                  <Input.Amount
                    {...state.collateral}
                    placeholder='Enter amount'
                    networkId={networkId}
                    onChange={e =>
                      onChangeAmount(null, _.get(e, 'target.value'))}
                    onClick={() => onPrimaryRefocus(true)}
                  />
                </Step>
              </Step>
              <ContentSummary
                index='3.2:'
                context={tabs.pool.unmint}
                data={{
                  state,
                  option,
                  durations
                }}
                transact={
                  <SpecialButton.Transact
                    title='Unmint Options'
                    isDisabled={!isFormValid}
                    isAllowed={isFormAllowed}
                    isLoading={[
                      machine.states.validate,
                      machine.states.process
                    ].includes(machine.current.value)}
                    onClick={onTransact}
                  />
                }
              />
            </Form>
          </ContentSection>
        </Wrapper>
      </ContentBox>
    </>
  )
}

export default Unmint
