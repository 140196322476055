import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Helper } from '@pods-finance/components'

const WrapperPartial = styled.div`
  grid-column: span ${props => props.columns || 1};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: calc(${props => props.theme.sizes.edge} * 3 / 4);
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: calc(${props => props.theme.sizes.edge} * 3 / 4);
  color: ${props => props.theme.colors.dark};
  & > svg {
    font-size: 13pt;
    margin-top: -2px;
    margin-right: 4px;
  }
`

const Title = styled.p`
  font-size: 11pt;
  font-weight: 700;
  margin: 0 !important;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  color: ${props => props.theme.colors.dark};
  width: 100%;
  p {
    font-size: 11pt;
    font-weight: 500;
    margin: 0 !important;
  }
`

const Value = styled.div`
  color: ${props => props.theme.colors.dark};
`

const Wrapper = styled(WrapperPartial)`
  &[data-accent='true'] {
    ${Header} {
      color: ${props => props.theme.colors.middle} !important;
    }
  }
  &[data-dim='true'] {
    ${Header}, ${Value}, p:not([data-parent="helper"]), span {
      color: ${props => props.theme.colors.contentMedium} !important;
    }
  }
`

function Item ({
  Icon,
  title,
  value,
  children,
  columns,
  hasAccent,
  isWrapper,
  hasDim,
  help
}) {
  return (
    <Wrapper columns={columns} data-accent={hasAccent} data-dim={hasDim}>
      <Helper value={help} isWrapper={isWrapper} force='top-left'>
        <Header>
          {!_.isNil(Icon) && <Icon />}
          <Title>{title}</Title>
        </Header>
      </Helper>
      <Content data-component='content'>
        {children || (
          <>
            <Value>
              <p>{value}</p>
            </Value>
          </>
        )}
      </Content>
    </Wrapper>
  )
}

Item.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.number,
  Icon: PropTypes.any,
  hasAccent: PropTypes.bool,
  hasDim: PropTypes.bool,
  isWrapper: PropTypes.bool,
  help: PropTypes.string
}

Item.defaultProps = {
  title: '',
  subtitle: null,
  columns: 1,
  Icon: null,
  hasAccent: false,
  hasDim: false,
  isWrapper: false,
  help: null
}

export default Item
