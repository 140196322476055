import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Cell from './Cell'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.size || 6}, auto);
  grid-gap: ${props => props.theme.sizes.edge};

  ${props => props.theme.medias.medium} {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: calc(${props => props.theme.sizes.edge} * 1);
  }
`

const Loader = styled.div`
  grid-column: span 6;
  height: 36px;
`

function Series ({ cells }) {
  return (
    <Wrapper size={_.get(cells, 'length')}>
      {cells.map(cell => (
        <Cell key={_.get(cell, 'label')} {...cell} />
      ))}
    </Wrapper>
  )
}

function SeriesLoader () {
  return (
    <Wrapper>
      <Loader />
    </Wrapper>
  )
}

Series.propTypes = {
  cells: PropTypes.arrayOf(PropTypes.shape({}))
}

Series.defaultProps = {
  cells: []
}

export { SeriesLoader }

export default Series
