import _ from 'lodash'
import React, { useMemo, useCallback, useEffect } from 'react'
import BigNumber from 'bignumber.js'
import { useRouteId } from '@pods-finance/hooks'
import { toNumeralPrice } from '@pods-finance/utils'

import { useDataStaticContext } from '../contexts/DataStatic'
import { useDataDynamicContext } from '../contexts/DataDynamic'
import { Option } from '../models'
import { useMarketPrices } from './price'
import { useAddresses, useToken, useGuardedTVL } from './utility'
import { useAccount } from './web3'

/**
 * --------------------------------------
 * Static data and write utilities
 * --------------------------------------
 */

export function useHelper () {
  const { helper } = useDataStaticContext()
  return helper
}

export function useOptions (isCurated = false) {
  const { options: source } = useAddresses()
  const { options: raw } = useDataStaticContext()

  const isExpected = useMemo(() => source.length > raw.length, [source, raw])

  const isLoading = useMemo(() => {
    return isExpected || raw.some(item => item.element.isLoading === true)
  }, [isExpected, raw])

  const count = useMemo(() => raw.length, [raw])

  const options = useMemo(
    () =>
      raw
        .filter(
          item =>
            !item.element.isLoading &&
            !_.isNil(item.element.value) &&
            _.isNilOrEmptyString(item.element.warning)
        )
        .filter(item => !isCurated || item.element.isCurated)
        .map(item => new Option(_.get(item, 'element') || {})),
    [raw, isCurated]
  )

  return { options, count, isLoading }
}

export function useOption (force = null) {
  const id = useRouteId(force)
  const { options: source } = useAddresses()
  const { options: raw } = useDataStaticContext()

  const isExpected = useMemo(() => source.length > raw.length, [source, raw])

  const isLoading = useMemo(() => {
    const item = raw.find(option => _.get(option, 'element.address') === id)

    return isExpected || !item || item.element.isLoading === true
  }, [raw, id, isExpected])

  const option = useMemo(() => {
    const item = raw.find(option => _.get(option, 'element.address') === id)

    if (
      _.isNil(item) ||
      item.element.isLoading === true ||
      !_.isNilOrEmptyString(item.element.warning)
    ) {
      return null
    }
    return new Option(_.get(item, 'element') || {})
  }, [raw, id])

  const warning = useMemo(() => {
    const item = raw.find(option => _.get(option, 'element.address') === id)
    return item ? item.element.warning : null
  }, [raw, id])

  return { option, warning, isLoading }
}

/**
 * ---------------------------------
 * This hook will try to resolve an options that isn't officially curated (not included in the initial list)
 * It is important that
 *  1. This hook is called inside a route (so it can access the route parameters - option address in URL)
 *  2. This hook is called only one time per route/page (INSTANCE) - to minimize the risk of trigerring the request twice
 *
 *
 * @param {string} force Force optiona address to be used instead of route id
 */
export function useOptionResolverINSTANCE (force = null) {
  const id = useRouteId(force)
  const { isExpected, isConnected } = useAccount()
  const { options: source } = useAddresses()
  const { isLoading } = useOptions()
  const { options: raw, trackOption: track } = useDataStaticContext()

  useEffect(() => {
    if (isLoading || _.isNilOrEmptyString(id) || (isExpected && !isConnected)) {
      return
    }
    const item = raw.find(option => _.get(option, 'element.address') === id)
    const preparing = source.find(address => address.toLowerCase() === id)
    if (_.isNil(item) && !preparing) {
      track({
        option: {
          address: id
        },
        isCurated: false
      })
    }
  }, [raw, id, track, source, isLoading, isExpected, isConnected])
}

// TOOD http://localhost:5002/buy/0xFdea2F7E2526DFAea1a349bBCf2f7b6b2eFDe939X/#buy

/**
 * --------------------------------------
 * Data (dynamics)
 * --------------------------------------
 */

export function useOptionsDynamics () {
  const { user: userSource, general: generalSource } = useDataDynamicContext()
  const { elements: generalDB } = useMemo(() => generalSource || {}, [
    generalSource
  ])
  const { elements: userDB } = useMemo(() => userSource || {}, [userSource])

  const itemizer = useCallback(
    address => {
      const general = _.get(generalDB, `${address}.element.value`)
      const user = _.get(userDB, `${address}.element.value`)

      const sellingPrice = _.get(general, 'sellingPrice')
      const buyingPrice = _.get(general, 'buyingPrice')
      const abPrice = _.get(general, 'abPrice')
      const totalBalances = _.get(general, 'totalBalances')
      const totalSupply = _.get(general, 'totalSupply')
      const IV = _.get(general, 'IV')
      const adjustedIV = _.get(general, 'adjustedIV')

      const optionUnderlyingBalance = _.get(general, 'optionUnderlyingBalance')
      const optionStrikeBalance = _.get(general, 'optionStrikeBalance')

      const userPositions = _.get(user, 'userPositions')
      const userOptionWithdrawAmounts = _.get(user, 'userOptionWithdrawAmounts')
      const userOptionMintedAmount = _.get(user, 'userOptionMintedAmount')
      const userOptionBalance = _.get(user, 'userOptionBalance')

      const userFeeWithdrawAmounts = _.get(user, 'userFeeWithdrawAmounts')

      return {
        sellingPrice,
        buyingPrice,
        abPrice,
        totalBalances,
        totalSupply,
        IV,
        adjustedIV,
        userPositions,
        userOptionWithdrawAmounts,
        userOptionMintedAmount,
        userOptionBalance,

        optionUnderlyingBalance,
        optionStrikeBalance,

        userFeeWithdrawAmounts
      }
    },
    [generalDB, userDB]
  )

  const grouped = useMemo(() => {
    const result = {
      isLoadingUserDynamics: _.get(
        Object.values(userDB || {}),
        '0.element.isLoading'
      ),
      isLoadingGeneralDynamics: _.get(
        Object.values(generalDB || {}),
        '0.element.isLoading'
      )
    }

    result.isLoading =
      result.isLoadingUserDynamics || result.isLoadingGeneralDynamics
    if (_.isNil(generalDB)) return result
    Object.keys(generalDB).forEach(address => {
      result[address] = itemizer(address)
    })
    return result
  }, [itemizer, generalDB, userDB])

  return grouped
}

export function useOptionDynamics (force = null) {
  const { option, isLoading: isOptionLoading } = useOption(force)
  const dynamics = useOptionsDynamics()

  const isLoading = useMemo(
    () =>
      (!_.isNil(option) && isOptionLoading) ||
      (!_.isNil(dynamics) && dynamics.isLoading),
    [option, isOptionLoading, dynamics]
  )

  const item = useMemo(() => {
    if (_.isNil(option) || _.isNil(dynamics) || isLoading) return null
    const address = option.address
    return _.get(dynamics, address)
  }, [option, dynamics, isLoading])

  const result = useMemo(() => ({ ...(item || {}), isLoading }), [
    item,
    isLoading
  ])

  return result
}

/**
 * --------------------------------------
 * Data aggregators and formatters
 * --------------------------------------
 */

export function useOptionTokens () {
  const { option, isLoading } = useOption()
  const { get } = useToken()

  return useMemo(() => {
    if (_.isNil(option) || isLoading || !_.isNilOrEmptyString(option.warning)) {
      return {
        underlying: null,
        strike: null,
        collateral: null,
        anticollateral: null,
        tokenA: null,
        tokenB: null
      }
    }

    return {
      underlying: get(option.underlying),
      strike: get(option.strike),
      collateral: get(option.collateral),
      anticollateral: get(option.anticollateral),
      tokenA: get(option.pool.tokenA),
      tokenB: get(option.pool.tokenB)
    }
  }, [get, option, isLoading])
}

export function useFeesVolume () {
  const { options, isLoading } = useOptions(true)

  const [fees, series] = useMemo(() => {
    const fees = []
    const series = []

    if (!isLoading && options) {
      options.forEach(option => {
        const durations = option.getDurations()
        const strike = option.strikePrice.humanized
        const volume = option.seriesFeeVolume.humanized

        fees.push(volume)

        if (volume.isGreaterThan(new BigNumber(2))) {
          if (!series.find(item => item.address === _.get(option, 'address'))) {
            series.push({
              address: option.address,
              title: `${option.underlying.alias}:${option.strike.alias}`,
              details: `${toNumeralPrice(strike)} - ${
                durations.expirationFormattedWithTime
              }`,
              value: volume,
              weight: parseInt(option.expiration, 10)
            })
          }
        }
      })
    }

    return [
      fees.reduce((prev, curr) => prev.plus(curr), new BigNumber(0)),
      series
    ]
  }, [options, isLoading])

  const breakdown = useMemo(() => {
    return [
      {
        title: 'Fee volume',
        description: (
          <>
            The historical fee volume for the options tracked on the current
            network is <b>{toNumeralPrice(fees)}</b>. It will differ from
            metrics such as <code>nextFeesA</code> or <code>nextFeesB</code>{' '}
            because these can be decreased by removing liquidity and redeeming
            fees.
          </>
        )
      },
      {
        title: 'Fee volume breakdown',
        description: (
          <>
            Fees generated by each series (that had activity and fees higher
            than $2):
            <br />
            <ul>
              {series && series.length ? (
                series
                  .sort((a, b) => b.weight - a.weight)
                  .map(serie => (
                    <li key={_.get(serie, 'address')}>
                      <p>
                        <b>{_.get(serie, 'title')}</b> -{' '}
                        {_.get(serie, 'details')}{' '}
                        <b>{toNumeralPrice(_.get(serie, 'value'))} </b>
                      </p>
                    </li>
                  ))
              ) : (
                <li>No series over $2 (either none or below)</li>
              )}
            </ul>
          </>
        )
      }
    ]
  }, [fees, series])

  return useMemo(
    () => ({
      breakdown,
      fees,
      series,
      isLoading
    }),
    [fees, series, breakdown, isLoading]
  )
}

export function useDynamicsTVL () {
  const { options, isLoading: isLoadingOptions } = useOptions(true)
  const dynamics = useOptionsDynamics()
  const guard = useGuardedTVL()
  const { spot } = useMarketPrices()

  const isLoading = useMemo(
    () => isLoadingOptions && dynamics.isLoadingGeneralDynamics,
    [dynamics, isLoadingOptions]
  )

  const [TVL, supply, stables, series] = useMemo(() => {
    if (!isLoading) {
      let TVL = new BigNumber(0)
      let supply = new BigNumber(0)
      let stables = new BigNumber(0)
      const series = []

      options.forEach(option => {
        const dynamic = _.get(dynamics, option.address)
        const market = _.get(spot, option.underlying.symbol.toUpperCase())
        const totalSupply = _.get(dynamic, 'totalSupply')

        const optionUnderlyingBalance = _.get(
          dynamic,
          'optionUnderlyingBalance'
        )
        const optionStrikeBalance = _.get(dynamic, 'optionStrikeBalance')

        const liquidity = _.get(dynamic, 'totalBalances')

        const capSupply = _.get(guard, `data.caps[${option.address}]supply`)
        const capStable = _.get(guard, `data.caps[${option.address}]stable`)

        const label = [
          _.get(option, 'underlying.alias'),
          _.get(option, 'strike.alias')
        ].join(':')

        if (!(_.isNil(liquidity) || _.isNil(totalSupply))) {
          const itemSupply = _.get(optionUnderlyingBalance, 'humanized')
            .times(new BigNumber(market))
            .plus(_.get(optionStrikeBalance, 'humanized'))

          const itemStables = _.get(liquidity, '1.humanized')
          const itemTVL = itemStables.plus(itemSupply)

          supply = supply.plus(itemSupply)
          stables = stables.plus(itemStables)
          TVL = TVL.plus(itemTVL)

          series.push([
            option.address,
            label,
            toNumeralPrice(itemSupply),
            capSupply,
            toNumeralPrice(itemStables),
            capStable,
            [
              [
                option.underlying.alias,
                _.get(optionUnderlyingBalance, 'humanized')
              ],
              [option.strike.alias, _.get(optionStrikeBalance, 'humanized')]
            ],
            itemTVL
          ])
        }
      })

      return [TVL, supply, stables, series]
    }

    return [new BigNumber(0), new BigNumber(0), new BigNumber(0), []]
  }, [guard, options, dynamics, spot, isLoading])

  const sections = useMemo(() => {
    return [
      {
        title: 'Total value locked',
        description: (
          <>
            The TVL for the current network is <b>{toNumeralPrice(TVL)}</b>.
            This amount updates based on 2 components: the option supply value
            and the stablecoin pool size.
          </>
        )
      },
      {
        title: 'Cap / Guard',
        description: (
          <>
            For each of the 2 components we've implemented a cap/guard that
            limits how much can be deposited.
          </>
        )
      },
      {
        title: 'Supply value (1/2)',
        description: (
          <>
            The option supply represents the number of options minted. Each
            option will have locked collateral inside, which we can sum up to
            calculate the total value locked while minting options (as a writer
            or an lp). The supply for the current network is{' '}
            <b>{toNumeralPrice(supply)}</b>. Breakdown:
            <ul>
              {series.map(serie => (
                <li key={_.get(serie, '0')}>
                  For {_.get(serie, '1')} the supply is{' '}
                  <b>{_.get(serie, '2')}</b> with a cap of{' '}
                  {_.get(serie, '3') || 'Infinity'}. <br />
                  Breakdown: {_.get(serie, '6.0.0')}{' '}
                  {toNumeralPrice(_.get(serie, '6.0.1'), false)} and{' '}
                  {_.get(serie, '6.1.0')}{' '}
                  {toNumeralPrice(_.get(serie, '6.1.1'))}.
                </li>
              ))}
            </ul>
          </>
        )
      },
      {
        title: 'Stable pools size (2/2)',
        description: (
          <>
            The stable side of the pool will contain locked stablecoins which
            count towards the TVL of the protocol. The value of these, for the
            current network is <b>{toNumeralPrice(stables)}</b>. Breakdown:
            <ul>
              {series.map(serie => (
                <li key={_.get(serie, '0')}>
                  For {_.get(serie, '1')} the supply is {_.get(serie, '4')} with
                  a cap of {_.get(serie, '5') || 'Infinity'}
                </li>
              ))}
            </ul>
          </>
        )
      }
    ]
  }, [TVL, supply, stables, series])

  return { value: TVL, supply, stables, sections }
}

export function useOptionInfo () {
  const { spot } = useMarketPrices()
  const { option, isLoading, warning } = useOption()
  const helper = useHelper()
  const tokens = useOptionTokens()

  const isWarned = useMemo(() => !_.isNilOrEmptyString(warning), [warning])
  const isReady = useMemo(() => !_.isNil(option) && !isLoading, [
    option,
    isLoading
  ])
  const isRestricted = useMemo(() => (isReady && _.isNil(option)) || isWarned, [
    isReady,
    option,
    isWarned
  ])

  const address = useMemo(() => isReady && option.address, [option, isReady])
  const durations = useMemo(() => (isReady ? option.getDurations() : {}), [
    option,
    isReady
  ])

  const market = useMemo(
    () => isReady && _.get(spot, option.underlying.symbol.toUpperCase()),
    [spot, option, isReady]
  )

  const description = useMemo(() => {
    if (_.isNil(option) || isLoading) return ['', '']
    if (option.isPut()) {
      return {
        name: 'Put Option',
        info:
          'Put Options are contracts giving the owner the right, but not the obligation, to sell an asset at a pre-determined strike price.'
      }
    }
    return {
      name: 'Call Option',
      info:
        'Call Options are contracts giving the owner the right, but not the obligation, to buy an asset at a pre-determined strike price.'
    }
  }, [option, isLoading])

  return useMemo(
    () => ({
      ...tokens,
      address,
      option,
      description,
      helper,
      market,
      durations,
      warning,
      isLoading,
      isWarned,
      isReady,
      isRestricted
    }),
    [
      address,
      tokens,
      option,
      description,
      helper,
      market,
      durations,
      warning,
      isLoading,
      isWarned,
      isReady,
      isRestricted
    ]
  )
}
