export default {
  connectWallet: 'Click on Connect Wallet in the Account Component',
  connectWalletChoice: 'Connected with wallet provider',
  searchOpen: 'Open Search Box',
  searchClose: 'Close Search Box',
  searchTrigger: 'Trigger Search Box',

  transactionSellTrigger:
    'Click on Sell Options button in the Invest Transaction Page',
  transactionResellTrigger:
    'Click on Resell Options button in the Invest Transaction Page',
  transactionWithdrawTrigger:
    'Click on Withdraw button in the Invest Transaction Page',
  transactionBuyTrigger:
    'Click on Buy Options button in the Hedge Transaction Page',
  transactionExerciseTrigger:
    'Click on Exercise Options button in the Hedge Transaction Page',
  transactionAddLiquidityTrigger:
    'Click on Add Liquidity button in the Pool Transaction Page',
  transactionAddLiquiditySingleTrigger:
    'Click on Add Liquidity (Single) button in the Pool Transaction Page',
  transactionRemoveLiquidityTrigger:
    'Click on Remove Liquidity button in the Transaction Pool Page',
  transactionRemoveLiquiditySingleTrigger:
    'Click on Remove Liquidity (Single) button in the Transaction Pool Page',
  transactionMintTrigger: 'Click on Mint button in the Transaction Pool Page',
  transactionUnwindTrigger:
    'Click on Unmint/Unwind button in the Transaction Pool Page',

  transactionSummary: 'Click on Learn More in the Transaction Summary',
  transactionEarn: 'Click on Earn in the Transaction Page',

  transactionAllowToken: 'Click on Allow Token button',
  transactionAllowLiquidity: 'Click on Allow Liquidity button',

  poolActionMint:
    'Click on Mint Options bottom call to action in the Transaction Pool Page',
  poolActionUnwind:
    'Click on Unmint/Unwind Options bottom call to action in the Transaction Pool Page',

  expandDashboardAsset: 'Expand Asset card inside the bottom Dashboard Section',
  accountCopyAddress: 'Click on Copy Address in the Account Component',
  accountLogOut: 'Click on Log Out in the Account Component',
  accountLearnMore: 'Click on Learn More in the Account Component'
}
