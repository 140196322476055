import _ from 'lodash'
import { useMemo, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTokenByNetworkId, useRouteId } from '@pods-finance/hooks'
import { getAddresses } from '@pods-finance/utils'
import { useModal } from '@pods-finance/contexts'
import { networks, modals, GUARDED_LAUNCH, addresses } from '../constants'
import { useNetworkId } from './web3'
import { useUI, useClassification } from './ui'

export function useGuardedTVL () {
  const networkId = useNetworkId()
  const optionId = useRouteId()
  const { setOpen } = useModal(modals.guardTVL)

  const data = useMemo(() => {
    const guards = GUARDED_LAUNCH
    if (!_.has(guards, networkId)) return null
    const base = _.get(guards, networkId)

    const safe = _.transform(base.caps, (r, v, k) => {
      r[String(k).toLowerCase()] = v
    })

    return {
      ...base,
      caps: safe
    }
  }, [networkId])

  const banner = useMemo(() => _.get(data, 'banner'), [data])

  const cap = useMemo(() => {
    if (_.isNilOrEmptyString(optionId)) return null
    if (_.isNil(data)) return null
    if (!_.isObject(data, 'caps')) return null

    if (
      !Object.keys(data.caps).some(
        key => String(key).toLowerCase() === String(optionId).toLowerCase()
      )
    ) {
      return null
    }

    return _.get(data.caps, String(optionId).toLowerCase())
  }, [optionId, data])

  const isShown = useMemo(() => {
    if (_.isNil(cap) || _.isNilOrEmptyString(_.get(cap, 'banner'))) {
      return false
    }
    return true
  }, [cap])

  const isDisabled = useMemo(() => {
    if (
      _.isNil(cap) ||
      _.isNilOrEmptyString(_.get(cap, 'disabled')) ||
      !_.get(cap, 'disabled')
    ) {
      return false
    }
    return true
  }, [cap])

  const showGuard = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  return useMemo(
    () => ({
      data,
      banner,
      cap,
      isShown,
      isDisabled,
      show: showGuard
    }),
    [banner, cap, data, showGuard, isShown, isDisabled]
  )
}

export function useRefresh () {
  const [version, setVersion] = useState(0)
  const refresh = useCallback(() => {
    setVersion(prev => prev + 1)
  }, [setVersion])
  return {
    refresh,
    version
  }
}

export function useAddresses () {
  const networkId = useNetworkId()
  const { isPut } = useClassification()

  const list = useMemo(() => {
    const base = getAddresses(networkId, addresses)
    base.puts = base.puts.map(item => {
      if (_.isArray(item)) return _.get(item, '0')
      return item
    })

    base.calls = base.calls.map(item => {
      if (_.isArray(item)) return _.get(item, '0')
      return item
    })

    base.options = isPut ? base.puts : base.calls
    base.all = _.uniq([...base.puts, ...base.calls])
    return base
  }, [networkId, isPut])
  return list
}

/**
 * If an array is received, it will try to return all the matching tokens
 * @param {SDK.Token | SDK.Token[]} source
 */
export function useToken (source) {
  const networkId = useNetworkId()
  const result = useTokenByNetworkId(source, networkId)
  return result
}

export function useBackLogic (fallback = '/') {
  const history = useHistory()
  const { entry } = useUI()

  const parent = useMemo(() => {
    if (!_.isNil(entry) && _.has(entry, 'pathname')) {
      return _.get(entry, 'pathname')
    }
    return fallback
  }, [entry, fallback])

  const goBack = useCallback(() => {
    try {
      history.push(parent)
    } catch (e) {
      console.error('Back button', e)
    }
  }, [history, parent])

  return { goBack, parent }
}

export function useSupportedNetwork () {
  const networkId = useNetworkId()
  return networks._supported.includes(networkId) || _.isNil(networkId)
}
