import _ from 'lodash'
import BigNumber from 'bignumber.js'
import { useCallback } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useAtomicMachine } from '@pods-finance/hooks'
import { useModal } from '@pods-finance/contexts'
import { toQuantity, guards } from '@pods-finance/utils'

import { modals, macros, words } from '../../constants'

const PREMIUM_SLIPPAGE = 1 + macros.DEFAULT_SLIPPAGE
const UNDERLYING_SLIPPAGE = 1 - macros.DEFAULT_SLIPPAGE

export function useMachine () {
  const { addToast, removeAllToasts } = useToasts()
  const { setOpen, updateData } = useModal(modals.transaction)

  const onPrepare = useCallback(async ({ context }) => {
    const state = _.get(context, 'payload.state')

    const form = await guards.booleanize(() =>
      guards.isFormValid({ value: state, soft: true })
    )

    if (!form) return false

    return true
  }, [])

  const onValidate = useCallback(
    async ({ context }) => {
      const state = _.get(context, 'payload.state')

      const form = await guards.interpret(
        () =>
          guards.isFormValid({
            value: state
          }),
        addToast
      )

      if (form[0] === false) throw new Error(form[1])

      const underlying = await guards.interpret(
        () =>
          guards.isAmountValid({
            value: _.get(state, 'underlying.value'),
            max: _.get(state, 'underlying.max'),
            context: 'liquidity'
          }),
        addToast
      )

      if (underlying[0] === false) throw new Error(underlying[1])

      const premium = await guards.interpret(
        () =>
          guards.isAmountValid({
            value: _.get(state, 'premium.value'),
            max: _.get(state, 'premium.max')
          }),
        addToast
      )

      if (premium[0] === false) throw new Error(premium[1])
    },
    [addToast]
  )

  const onProcess = useCallback(
    async ({ context }) => {
      const payload = _.get(context, 'payload') || {}
      const { option, setup, state } = payload

      let optionAmount = new BigNumber(_.get(state, 'underlying.value'))
      let premiumAmount = new BigNumber(_.get(state, 'premium.value'))
      const exact = _.get(state, 'exact.value')
      const classification = option.isPut() ? 'Put' : 'Call'

      const optionLabel = toQuantity(optionAmount.toString(), 'option')
      const modalLabel = `${optionLabel} of ${_.get(
        option,
        'underlying.symbol'
      )}:${_.get(option, 'strike.symbol')} ${classification}`

      try {
        setOpen(true, {
          state: 'loading',
          tx: null,
          info: `Preparing to buy ${modalLabel}. Checking allowances...`
        })

        const allowance = await setup.getTokenAllowance({
          ...setup,
          token: option.strike,
          amount: _.get(state, 'premium.value')
        })

        if (_.isNil(allowance) || _.get(allowance, 'isAllowed') === false) {
          updateData({
            state: 'warning',
            info: words.warningTransactionAllowance()
          })
          return
        }

        updateData({
          info: `Buying ${modalLabel}.`
        })

        let rejected = null
        const { helper } = setup

        if (exact === 'tokenA') {
          premiumAmount = premiumAmount.times(PREMIUM_SLIPPAGE)

          /**
           * Always send the SDK provided _element back to the SDK
           */

          await helper.doBuyExact({
            option: option.fromSDK(),
            optionAmount,
            premiumAmount,
            overrides: {
              gasLimit: true
            },
            callback: (error, transactionHash) => {
              if (error) rejected = error
              updateData({
                tx: transactionHash
              })
            }
          })
        } else {
          optionAmount = optionAmount.times(UNDERLYING_SLIPPAGE)

          /**
           * Always send the SDK provided _element back to the SDK
           */

          await helper.doBuyEstimated({
            option: option.fromSDK(),
            premiumAmount,
            optionAmount,
            overrides: {
              gasLimit: true
            },
            callback: (error, transactionHash) => {
              if (error) rejected = error
              updateData({
                tx: transactionHash
              })
            }
          })
        }

        if (!_.isNil(rejected)) throw rejected

        updateData({
          state: 'success',
          info: `Bought ${modalLabel}.`
        })

        addToast(`${optionLabel} successfully bought!`, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
      } catch (e) {
        removeAllToasts()

        const reason =
          _.get(e, 'code') === 4001
            ? words.reasonTransactionRejected()
            : words.reasonTransactionFailed()

        updateData({
          title: reason,
          state: 'error',
          info: words.errorTransactionFailed(`Attempted to buy ${modalLabel}.`)
        })

        addToast(reason, {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
        setup.update()
        throw e
      }
      setup.update()
    },
    [addToast, removeAllToasts, setOpen, updateData]
  )

  const machine = useAtomicMachine({
    id: 'buy',
    onPrepare,
    onValidate,
    onProcess
  })

  return machine
}

export default {
  useMachine
}
