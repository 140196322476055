import Amount from './Amount'
import Dater from './Dater'
import Network from './Network'
import Text from './Text'
import Token from './Token'
import TokenList from './TokenList'
import Select from './Select'
import Slider from './Slider'

export default {
  Amount,
  Dater,
  Network,
  Text,
  Token,
  TokenList,
  Select,
  Slider
}
