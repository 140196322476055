import React from 'react'
import styled from 'styled-components'
import globals from '@pods-finance/globals'
import { ExternalLink } from '@pods-finance/components'
import IconFlag from '@material-ui/icons/OutlinedFlagRounded'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-radius: ${props => props.theme.sizes.edge};
  border: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.colors.white};
  padding: calc(${props => props.theme.sizes.edge} * 1)
    calc(${props => props.theme.sizes.edge} * 1.5);

  & > svg {
    margin: 0 12px 0 0;
    font-size: 14pt;
    color: ${props => props.theme.colors.dark};
  }

  p {
    margin: 0 0 -1px 0;
    font-size: 10pt;
    font-weight: 500;
    color: ${props => props.theme.colors.dark};
    line-height: 1.5;
  }
  a {
    text-decoration: underline;
    &:hover,
    &:active {
      color: ${props => props.theme.colors.middle};
    }
  }

  ${props => props.theme.medias.small} {
    padding: calc(${props => props.theme.sizes.edge} * 1);
    border-radius: 0;
  }
`

function Learn () {
  return (
    <Wrapper>
      <IconFlag />
      <p>
        Learn about{' '}
        <ExternalLink to={globals.links.gitbook.options.link}>
          options
        </ExternalLink>
        ,{' '}
        <ExternalLink to={globals.links.ethereum.primary.link}>
          ethereum
        </ExternalLink>{' '}
        and{' '}
        <ExternalLink to={globals.links.terms.risks.link}>
          possible risks
        </ExternalLink>
        . Get aUSDC/amUSDC by depositing in{' '}
        <ExternalLink to={globals.links.aave.about.link}>Aave</ExternalLink>.
      </p>
    </Wrapper>
  )
}

export default Learn
