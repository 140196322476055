import _ from 'lodash'
import React, { useMemo, useCallback } from 'react'
import BigNumber from 'bignumber.js'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { pages, types } from '../../../constants'

import { useOwnBalance, useOptionDynamics, useOptionInfo } from '../../../hooks'
import { toNumeralPrice } from '@pods-finance/utils'

import IconCalendarBusy from '@material-ui/icons/EventBusyRounded'
import IconCalendarActive from '@material-ui/icons/EventAvailableRounded'
import IconHistory from '@material-ui/icons/HistoryRounded'
import IconWallet from '@material-ui/icons/AccountBalanceWalletRounded'

import Clock from './Clock'

const WrapperPartial = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${props => props.theme.sizes.edge};
`

const Row = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: calc(${props => props.theme.sizes.edge} * 1);
  & > svg {
    font-size: 15pt;
    color: ${props => props.theme.colors.dark};
  }
`

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;

  & > p {
    font-size: ${props => props.theme.sizes.text};
    color: ${props => props.theme.colors.dark};
    margin: 0;
    line-height: 1.5;
    font-weight: 500;

    & > b {
      font-weight: 700;
    }
    & > a {
      text-decoration: underline;
      font-weight: 700;
      cursor: pointer;
    }
  }
`

const Wrapper = styled(WrapperPartial)`
  ${props => props.theme.medias.small} {
    ${Row} {
      flex-direction: column;
      ${Icon} {
        margin: 0;
        margin-bottom: calc(${props => props.theme.sizes.edge} * 1);
        padding: calc(${props => props.theme.sizes.edge} * 1);
        justify-content: flex-start;
        border-radius: 4px;
        border: 1px solid ${props => props.theme.colors.border};
      }
      padding-bottom: calc(${props => props.theme.sizes.edge} * 1);
      border-bottom: 1px solid ${props => props.theme.colors.border};
      &:last-child {
        border: none;
      }
    }
  }
`
function Explainer ({
  type,
  option,
  balanceOptions,
  balanceAnticollateral,
  underlying,
  strike,
  anticollateral,
  collateral,
  amountStrike,
  amountUnderlying,
  expirationFormattedWithTime,
  liquidityTokenA
}) {
  if (type === 'investExpired') {
    return (
      <>
        <p>
          This series ended on {expirationFormattedWithTime}. You can withdraw{' '}
          <b>
            {_.get(underlying, 'alias')}{' '}
            {toNumeralPrice(amountUnderlying, false)}
          </b>{' '}
          and{' '}
          <b>
            {_.get(strike, 'alias')} {toNumeralPrice(amountStrike)}
          </b>
          . The remaining {_.get(collateral, 'alias')} collateral hasn’t been
          converted (exercised during the window) to{' '}
          {_.get(anticollateral, 'alias')}.
        </p>
      </>
    )
  }

  if (type === 'hedgeExpired') {
    return (
      <>
        <p>
          This series ended on {expirationFormattedWithTime}. You are no longer
          able to exercise your right to exchange{' '}
          {_.get(anticollateral, 'alias')} for {_.get(collateral, 'alias')},
          with this specific option.
        </p>
      </>
    )
  }

  if (type === 'investExercising') {
    return (
      <>
        <p>
          The exercise window for this options is open! It will only last for a
          brief period. You will be able to withdraw after this time. You can
          expect for some amount of underlying assets (if the option ends ITM
          and you get exercised) and the rest of your unused collateral.
        </p>
      </>
    )
  }

  if (type === 'hedgeExercising') {
    return (
      <>
        <p>
          The exercising window for this options is open! It will only last for
          a brief period. If you wish to exchange your assets, you will be able
          to do it only during this time.
        </p>
      </>
    )
  }

  if (type === 'hedgeExercisingWallet') {
    const exercisableOptions = option.isPut()
      ? BigNumber.min(balanceOptions, balanceAnticollateral)
      : BigNumber.min(
        balanceOptions,
        balanceAnticollateral.dividedBy(option.strikePrice.humanized)
      )

    const exercisableAnticollateral = option.isPut()
      ? exercisableOptions
      : exercisableOptions.times(option.strikePrice.humanized)

    return (
      <>
        <p>
          You own <b>{toNumeralPrice(balanceOptions, false)} options</b>{' '}
          {amountUnderlying && !amountUnderlying.isZero() && (
            <>
              ( + <b>{toNumeralPrice(liquidityTokenA, false)}</b> still locked
              as pool liquidity)
            </>
          )}{' '}
          and you may exercise{' '}
          <b>{toNumeralPrice(exercisableOptions, false)}</b> of them for a
          maximum of{' '}
          <b>
            {_.get(anticollateral, 'alias')}{' '}
            {toNumeralPrice(exercisableAnticollateral, false)}
          </b>{' '}
          based on your wallet balance (
          <b>
            {_.get(anticollateral, 'alias')}){' '}
            {toNumeralPrice(balanceAnticollateral, false)}
          </b>
          .
        </p>
      </>
    )
  }

  return <></>
}
function Countdown ({ action }) {
  const isHistoryAvailable = false

  const {
    option,
    underlying,
    strike,
    collateral,
    anticollateral,
    durations
  } = useOptionInfo()
  const dynamics = useOptionDynamics()

  const { value: balanceAnticollateral } = useOwnBalance(anticollateral)
  const { value: balanceOptions } = useOwnBalance({
    address: _.get(option, 'address'),
    decimals: _.get(option, 'decimals')
  })

  const {
    expirationFormattedWithTime,
    expirationToToday,
    isExercising,
    isExpired
  } = useMemo(() => durations || {}, [durations])

  const withdrawable = _.get(dynamics, 'userOptionWithdrawAmounts')
  const positions = _.get(dynamics, 'userPositions')

  const [amountUnderlying, amountStrike] = useMemo(
    () => [
      _.get(withdrawable, '0.humanized'),
      _.get(withdrawable, '1.humanized')
    ],
    [withdrawable]
  )

  const liquidityTokenA = useMemo(() => _.get(positions, '0.humanized'), [
    positions
  ])

  const bundle = useCallback(
    type => ({
      type,
      option,
      balanceOptions,
      balanceAnticollateral,
      underlying,
      strike,
      collateral,
      anticollateral,
      amountStrike,
      amountUnderlying,
      expirationFormattedWithTime,
      liquidityTokenA
    }),
    [
      option,
      balanceOptions,
      balanceAnticollateral,
      underlying,
      strike,
      collateral,
      anticollateral,
      amountStrike,
      amountUnderlying,
      expirationFormattedWithTime,
      liquidityTokenA
    ]
  )

  return (
    <Wrapper>
      {action === types.action.hedge && isExercising && (
        <Row>
          <Icon>
            <IconWallet />
          </Icon>
          <Content>
            <Explainer {...bundle('hedgeExercisingWallet')} />
          </Content>
        </Row>
      )}
      {isExercising && (
        <Row>
          <Icon>
            <IconCalendarActive />
          </Icon>
          <Content>
            {action === types.action.hedge ? (
              <Explainer {...bundle('hedgeExercising')} />
            ) : (
              <Explainer {...bundle('investExercising')} />
            )}
            <Clock time={expirationToToday || 0} />
          </Content>
        </Row>
      )}

      {action === types.action.hedge && isExpired && (
        <Row>
          <Icon>
            <IconCalendarBusy />
          </Icon>
          <Content>
            <Explainer {...bundle('hedgeExpired')} />
          </Content>
        </Row>
      )}

      {action === types.action.invest && isExpired && (
        <Row>
          <Icon>
            <IconCalendarBusy />
          </Icon>
          <Content>
            <Explainer {...bundle('investExpired')} />
          </Content>
        </Row>
      )}
      {isHistoryAvailable && (
        <Row>
          <Icon>
            <IconHistory />
          </Icon>
          <Content>
            To view a detailed list of your actions linked to this option, check
            the{' '}
            <Link
              to={pages.activityTransactions.builder(_.get(option, 'address'))}
            >
              transaction history (activity).
            </Link>
            .
          </Content>
        </Row>
      )}
    </Wrapper>
  )
}

Countdown.propTypes = {
  action: PropTypes.oneOf(Object.values(types.action))
}
Countdown.defaultProps = {
  action: types.action.invest
}

export default Countdown
