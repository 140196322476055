import { ENVIRONMENT, networks, tokens } from '@pods-finance/globals'

const production = {
  [networks.mainnet]: {
    puts: [
      [
        '0x049dfA51Cdc5c68fcb57dF1B43a03B4494623159',
        'ETH:USDC',
        '3200',
        '1638000000'
      ],
      [
        '0x9d7503ecbc2677984be4b7af9584385186b26c63',
        'ETH:ePyvUSDC-17DEC21',
        '3900',
        '1639727861'
      ]
    ],
    calls: []
  },
  [networks.arbitrum]: {
    puts: [
      // [
      //   '0x4624A4c10a7b944D0255D82a2E62a67d0F6060Fa',
      //   'ETH:USDC',
      //   '3500',
      //   '1640361600'
      // ],
      // [
      //   '0x416de82795c3f5355dcc5896d4a9e614db2f2572',
      //   'ETH:USDC',
      //   '3500',
      //   '1643325913'
      // ],
      // [
      //   '0x854415aae5acc248a1df9fec6d1a638da49238e7',
      //   'ETH:USDC',
      //   '2600',
      //   '1651248000'
      // ],
      [
        '0x8b8a7c0aa6d772b90ce90466f85d2b083dcf90f2',
        'ETH:USDC',
        '2400',
        '1651852800'
      ],
      [
        '0x05149959582bC9Ad56E8De5971ae50f57145FF96',
        'ETH:USDC',
        '2000',
        '1653062400'
      ],
      [
        '0xe676b5179e42885c94a4ee79c2d1f46791551771',
        'ETH:USDC',
        '1600',
        '1654272000'
      ],
      [
        '0xc933bfD700FAaEea7b8B211a19a1739fEAF0a167',
        'ETH:USDC',
        '1550',
        '1654790400'
      ],
      [
        '0x29b9A0cD75FBAB9E5223174C55C9d985cE092523',
        'ETH:USDC',
        '1500',
        '1656000000'
      ]
    ],
    calls: [
      // [
      //   '0xf8feebf23c6fde41df368ece039b4352e6191265',
      //   'ETH:USDC',
      //   '3800',
      //   '1651248000'
      // ],
      // [
      //   '0x90dfaf042af17507239a7f4497dcbf2f09cbeed4',
      //   'ETH:USDC',
      //   '3600',
      //   '1651852800'
      // ],
      [
        '0xEaB1c2c8c06933D5042532C940c4397cE062232b',
        'ETH:USDC',
        '2900',
        '1653062400'
      ],
      [
        '0x9f190af99e7d77c8cf37bff6761451b852fec110',
        'ETH:USDC',
        '2300',
        '1654272000'
      ],
      [
        '0x4A05D54D4260CE2ddFDE8e4F8d30bC1aDEBB690f',
        'ETH:USDC',
        '2300',
        '1654790400'
      ],
      [
        '0xc4a18C354F005b345f5592AB3D4968a9BE851880',
        'ETH:USDC',
        '2200',
        '1656000000'
      ]
    ]
  },
  [networks.kovan]: {
    faucet: '0xc896E591eB7c45287302ACB46f7cFFC5Aee56E2D',
    puts: [
      [
        '0xa3dd01829B1e4F8de1218d909DeE9f112bD84689',
        'ETH:USDC',
        '4000',
        '1646668800'
      ],
      [
        '0x1c69786c47dB902e6E3fac52F2AE6DcB4abda3dd',
        'ETH:aUSDC',
        '4000',
        '1646668800'
      ],
      [
        '0x40a9958f56E7a2B1aC7bfF04Aa46084EcE1F996C',
        'ETH:DAI',
        '4000',
        '1640295448'
      ],
      [
        '0x3eFee7AD7898839DC263d1a2529fb2E24D353EA0',
        'LINK:DAI',
        '17',
        '1640295984'
      ]
    ],
    calls: [
      [
        '0xb3adAC674B03CdEb879603b0A16EF3fd7740aF58',
        'LINK:USDC',
        '45',
        '1646755200'
      ],
      [
        '0x179E7d9462e2E44e1c5AeE1b71c13361C87922e3',
        'WETH:DAI',
        '5500',
        '1640295984'
      ],
      [
        '0x1a033d683DE3895bE8b96EFfe628d8a665717A7a',
        'LINK:DAI',
        '25',
        '1640298716'
      ]
    ]
  },
  [networks.matic]: {
    puts: [
      /* [
        '0xA53cD586840207890E44289EE7d31ccfbe58B42a',
        'SUSHI:aUSDC',
        '8',
        '1635523200'
      ],
      [
        '0x04aa280Bd351ffA83286c8cE1263890393f2B31A',
        'WETH:aUSDC',
        '3900',
        '1637337600'
      ],
          [
        '0x12dce930e6c2997bb7c134813c353c494a1182dc',
        'MATIC:USDC',
        '1',
        '1638000000'
      ],
      [
        '0x3BD0B1d477D88Ad1b782Bf833B1C73bd8C80B297',
        'WETH:USDC',
        '2200',
        '1648224000'
      ],
      [
        '0x1e8778A0362239BDfbF2fb3E61550c56321CCE51',
        'WETH:USDC',
        '2200',
        '1646409600'
      ],
      [
        '0x076a406833C572911645686c5837525572622edD',
        'MATIC:MAI',
        '1.5',
        '1639152000'
      ],
      [
        '0xb39C1dBa2a7a9B6aAA853Ad1A3a2CD018796B369',
        'WETH:amUSDC',
        '3300',
        '1639152000'
      ],
      [
        '0xc088baF997d84CB2952699a47e5291eF4B764704',
        'WETH:amUSDC',
        '3000',
        '1643299200'
      ],*/
      // [
      //   '0xdc383dbef2a022f7610b2c3b7521aa78c5dcdafb',
      //   'WETH:USDC',
      //   '2700',
      //   '1649433600'
      // ],
      // [
      //   '0xada29ac900b9b114eb79815aaa4105963c596ddc',
      //   'WETH:USDC',
      //   '2400',
      //   '1650643200'
      // ],
      // [
      //   '0x5af41708fa3bf09c9c81df6545b8d28f12e6157d',
      //   'WETH:USDC',
      //   '2400',
      //   '1651852800'
      // ],
      [
        '0xF3171aA142fc4611fCA9C632A9DF74C8528612a0',
        'WETH:USDC',
        '2000',
        '1653062400'
      ],
      [
        '0x94c7746471482c9edac04470298cc3fbeec49a73',
        'WETH:USDC',
        '1600',
        '1654272000'
      ],
      [
        '0xe86f3f6F1C44d755cAa376ED1B10a5fF36E457B9',
        'WETH:USDC',
        '1550',
        '1654790400'
      ],
      [
        '0x3C4687e01ca364F9ce2FEa21179574f741bf856E',
        'WETH:USDC',
        '1500',
        '1656000000'
      ]
      // [
      //   '0x65eFe49752d316c46F1eb2d26f97953E541aCC4E',
      //   'MATIC:IDLEUSDCYIELD',
      //   '1.9',
      //   '1640102400'
      // ],
      // [
      //   '0x2BFA21B52E303d1512237B40a1480548fAdbf498',
      //   'WETH:USDC',
      //   '2000',
      //   '1644595200'
      // ]
    ],
    calls: [
      // [
      //   '0xc67A67c07C8480214250BEa6CF0A76B301D41D91',
      //   'WETH:USDC',
      //   '2700',
      //   '1646409600'
      // ],
      // [
      //   '0x3fFD42AA60e798B8aAC01Cb0398ffA933081A9eE',
      //   'WETH:USDC',
      //   '5500',
      //   '1638547200'
      // ],
      // [
      //   '0xED590410D3E5342dc8350C8B2a8Ff098E6Ccb125',
      //   'WETH:USDC',
      //   '3000',
      //   '1644595200'
      // ],
      // [
      //   '0xE21d063f7562938e0151469138F3Da70672a28Ce',
      //   'WETH:USDC',
      //   '3200',
      //   '1648224000'
      // ],
      // [
      //   '0x66dbd35550c3ba91cbe073ab66a4cb885e3f5201',
      //   'WETH:USDC',
      //   '4000',
      //   '1649433600'
      // ],
      // [
      //   '0x47b6f7b51b6b8ddffa924e839cb73d3ddcad8993',
      //   'WETH:USDC',
      //   '3600',
      //   '1650729600'
      // ],
      // [
      //   '0x04493b60b736fe4113c95828f3d9b3d5d531f366',
      //   'WETH:USDC',
      //   '3600',
      //   '1651852800'
      // ],
      [
        '0x4974839A70a8533B6521F26fc3f66eC2Bb200207',
        'WETH:USDC',
        '2900',
        '1653062400'
      ],
      [
        '0x60aa3517086565e365f3382653e81cfcef866f20',
        'WETH:USDC',
        '2300',
        '1654272000'
      ],
      [
        '0x4D14623C39eF869e62DBCeCa360F2eD39AfEf75A',
        'WETH:USDC',
        '2300',
        '1654790400'
      ],
      [
        '0x7da6648aeb746676001dd2e69cafa16db9720074',
        'WETH:USDC',
        '2200',
        '1656000000'
      ]
    ]
  },
  [networks.local]: {
    puts: [],
    calls: []
  }
}

const development = {
  [networks.mainnet]: {
    puts: [
      [
        '0x049dfA51Cdc5c68fcb57dF1B43a03B4494623159',
        'ETH:USDC',
        '3200',
        '1638000000'
      ],
      [
        '0x9d7503ecbc2677984be4b7af9584385186b26c63',
        'ETH:ePyvUSDC-17DEC21',
        '3900',
        '1639727861'
      ]
    ],
    calls: []
  },
  [networks.arbitrum]: {
    puts: [
      // [
      //   '0x4624A4c10a7b944D0255D82a2E62a67d0F6060Fa',
      //   'ETH:USDC',
      //   '3500',
      //   '1640361600'
      // ],
      // [
      //   '0x416de82795c3f5355dcc5896d4a9e614db2f2572',
      //   'ETH:USDC',
      //   '3500',
      //   '1643325913'
      // ],
      // [
      //   '0x854415aae5acc248a1df9fec6d1a638da49238e7',
      //   'ETH:USDC',
      //   '2600',
      //   '1651248000'
      // ],
      // [
      //   '0x8b8a7c0aa6d772b90ce90466f85d2b083dcf90f2',
      //   'ETH:USDC',
      //   '2400',
      //   '1651852800'
      // ],
      [
        '0x05149959582bC9Ad56E8De5971ae50f57145FF96',
        'ETH:USDC',
        '2000',
        '1653062400'
      ],
      [
        '0xe676b5179e42885c94a4ee79c2d1f46791551771',
        'ETH:USDC',
        '1600',
        '1654272000'
      ],
      [
        '0xc933bfD700FAaEea7b8B211a19a1739fEAF0a167',
        'ETH:USDC',
        '1550',
        '1654790400'
      ],
      [
        '0x29b9A0cD75FBAB9E5223174C55C9d985cE092523',
        'ETH:USDC',
        '1500',
        '1656000000'
      ]
    ],
    calls: [
      // [
      //   '0xf8feebf23c6fde41df368ece039b4352e6191265',
      //   'ETH:USDC',
      //   '3800',
      //   '1651248000'
      // ],
      // [
      //   '0x90dfaf042af17507239a7f4497dcbf2f09cbeed4',
      //   'ETH:USDC',
      //   '3600',
      //   '1651852800'
      // ],
      [
        '0xEaB1c2c8c06933D5042532C940c4397cE062232b',
        'ETH:USDC',
        '2900',
        '1653062400'
      ],
      [
        '0x9f190af99e7d77c8cf37bff6761451b852fec110',
        'ETH:USDC',
        '2300',
        '1654272000'
      ],
      [
        '0x4A05D54D4260CE2ddFDE8e4F8d30bC1aDEBB690f',
        'ETH:USDC',
        '2300',
        '1654790400'
      ],
      [
        '0xc4a18C354F005b345f5592AB3D4968a9BE851880',
        'ETH:USDC',
        '2200',
        '1656000000'
      ]
    ]
  },
  [networks.kovan]: {
    faucet: '0xc896E591eB7c45287302ACB46f7cFFC5Aee56E2D',
    puts: [
      [
        '0xa3dd01829B1e4F8de1218d909DeE9f112bD84689',
        'ETH:USDC',
        '4000',
        '1646668800'
      ],
      [
        '0x1c69786c47dB902e6E3fac52F2AE6DcB4abda3dd',
        'ETH:aUSDC',
        '4000',
        '1646668800'
      ],
      [
        '0x40a9958f56E7a2B1aC7bfF04Aa46084EcE1F996C',
        'ETH:DAI',
        '4000',
        '1640295448'
      ],
      [
        '0x4Bd17a387D403385580DB0A2aE147d5813D63f50',
        'LINK:DAI',
        '17',
        '1640295984'
      ]
    ],
    calls: [
      [
        '0xb3adAC674B03CdEb879603b0A16EF3fd7740aF58',
        'LINK:USDC',
        '45',
        '1646755200'
      ],
      // [
      //   '0x179E7d9462e2E44e1c5AeE1b71c13361C87922e3',
      //   'WETH:DAI',
      //   '5500',
      //   '1640299576'
      // ],
      [
        '0x1a033d683DE3895bE8b96EFfe628d8a665717A7a',
        'LINK:DAI',
        '25',
        '1640298716'
      ]
    ]
  },
  [networks.matic]: {
    puts: [
      /* [
        '0xA53cD586840207890E44289EE7d31ccfbe58B42a',
        'SUSHI:aUSDC',
        '8',
        '1635523200'
      ],
      [
        '0x04aa280Bd351ffA83286c8cE1263890393f2B31A',
        'WETH:aUSDC',
        '3900',
        '1637337600'
      ],
          [
        '0x12dce930e6c2997bb7c134813c353c494a1182dc',
        'MATIC:USDC',
        '1',
        '1638000000'
      ],
      */
      // [
      //   '0x3BD0B1d477D88Ad1b782Bf833B1C73bd8C80B297',
      //   'WETH:USDC',
      //   '2200',
      //   '1648224000'
      // ],
      // [
      //   '0x1e8778A0362239BDfbF2fb3E61550c56321CCE51',
      //   'WETH:USDC',
      //   '2200',
      //   '1646409600'
      // ],
      // [
      //   '0x076a406833C572911645686c5837525572622edD',
      //   'MATIC:MAI',
      //   '1.5',
      //   '1639152000'
      // ],
      // [
      //   '0xb39C1dBa2a7a9B6aAA853Ad1A3a2CD018796B369',
      //   'WETH:amUSDC',
      //   '3300',
      //   '1639152000'
      // ],
      // [
      //   '0xc088baF997d84CB2952699a47e5291eF4B764704',
      //   'WETH:amUSDC',
      //   '3000',
      //   '1643299200'
      // ],
      // [
      //   '0x65eFe49752d316c46F1eb2d26f97953E541aCC4E',
      //   'MATIC:IDLEUSDCYIELD',
      //   '1.9',
      //   '1640102400'
      // ],
      // [
      //   '0x2bfa21b52e303d1512237b40a1480548fadbf498',
      //   'WETH:USDC',
      //   '2000',
      //   '1644595200'
      // ],
      // [
      //   '0xdc383dbef2a022f7610b2c3b7521aa78c5dcdafb',
      //   'WETH:USDC',
      //   '2700',
      //   '1649433600'
      // ],
      // [
      //   '0xada29ac900b9b114eb79815aaa4105963c596ddc',
      //   'WETH:USDC',
      //   '2400',
      //   '1650643200'
      // ],
      // [
      //   '0x5af41708fa3bf09c9c81df6545b8d28f12e6157d',
      //   'WETH:USDC',
      //   '2400',
      //   '1651852800'
      // ],
      [
        '0xF3171aA142fc4611fCA9C632A9DF74C8528612a0',
        'WETH:USDC',
        '2000',
        '1653062400'
      ],
      [
        '0x94c7746471482c9edac04470298cc3fbeec49a73',
        'WETH:USDC',
        '1600',
        '1654272000'
      ],
      [
        '0xe86f3f6F1C44d755cAa376ED1B10a5fF36E457B9',
        'WETH:USDC',
        '1550',
        '1654790400'
      ],
      [
        '0x3C4687e01ca364F9ce2FEa21179574f741bf856E',
        'WETH:USDC',
        '1500',
        '1656000000'
      ]
    ],
    calls: [
      // [
      //   '0xc67A67c07C8480214250BEa6CF0A76B301D41D91',
      //   'WETH:USDC',
      //   '2700',
      //   '1646409600'
      // ],
      // [
      //   '0x3fFD42AA60e798B8aAC01Cb0398ffA933081A9eE',
      //   'WETH:USDC',
      //   '5500',
      //   '1638547200'
      // ],
      // [
      //   '0xED590410D3E5342dc8350C8B2a8Ff098E6Ccb125',
      //   'WETH:USDC',
      //   '3000',
      //   '1644595200'
      // ],
      // [
      //   '0xE21d063f7562938e0151469138F3Da70672a28Ce',
      //   'WETH:USDC',
      //   '3200',
      //   '1648224000'
      // ],
      // [
      //   '0x66dbd35550c3ba91cbe073ab66a4cb885e3f5201',
      //   'WETH:USDC',
      //   '4000',
      //   '1649433600'
      // ],
      // [
      //   '0x47b6f7b51b6b8ddffa924e839cb73d3ddcad8993',
      //   'WETH:USDC',
      //   '3600',
      //   '1650729600'
      // ],
      // [
      //   '0x04493b60b736fe4113c95828f3d9b3d5d531f366',
      //   'WETH:USDC',
      //   '3600',
      //   '1651852800'
      // ],
      [
        '0x4974839A70a8533B6521F26fc3f66eC2Bb200207',
        'WETH:USDC',
        '2900',
        '1653062400'
      ],
      [
        '0x60aa3517086565e365f3382653e81cfcef866f20',
        'WETH:USDC',
        '2300',
        '1654272000'
      ],
      [
        '0x4D14623C39eF869e62DBCeCa360F2eD39AfEf75A',
        'WETH:USDC',
        '2300',
        '1654790400'
      ],
      [
        '0x7da6648aeb746676001dd2e69cafa16db9720074',
        'WETH:USDC',
        '2200',
        '1656000000'
      ]
    ]
  },
  [networks.local]: {
    puts: [],
    calls: []
  }
}

export const addresses = ENVIRONMENT.isProduction() ? production : development

export const feeds = {
  [tokens.keys.MATIC]: ['matic-network', 'matic-matic-network'],
  [tokens.keys.ETH]: ['ethereum', 'eth-ethereum'],
  [tokens.keys.SUSHI]: ['sushi', 'sushi-sushi'],
  // [tokens.keys.WBTC]: ["wrapped-bitcoin", "wbtc-wrapped-bitcoin"]
  [tokens.keys.LINK]: ['chainlink', 'link-chainlink']
}
