import Dashboard from './Dashboard'
import Hedge from './Hedge'
import Activity from './Activity'
import Invest from './Invest'
import Pool from './Pool'

export default {
  Dashboard,
  Hedge,
  Activity,
  Invest,
  Pool
}
