import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Table as TablePartial } from '@pods-finance/components'
import Helmet from '../../components/specific/Helmet'
import Restricted from '../../components/specific/Restricted'
import { Structure, Section, ToolbarBase } from '../../components/common'
import {
  useClassification,
  useInvestTable,
  useSearchFilter,
  useSupportedNetwork
} from '../../hooks'

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0;

  ${props => props.theme.styles.horizontalScrollContainer};
`

const TableWrapper = styled.div`
  width: 100%;
`

const Table = styled(TablePartial)`
  min-width: 1000px;
  padding: 0 ${props => props.theme.sizes.layoutEdge};

  div[data-component='body'] {
    min-height: 86px;
  }

  ${props => props.theme.medias.medium} {
    padding: 0 calc(${props => props.theme.sizes.layoutEdgeMedium});
  }
`

function Invest () {
  const supported = useSupportedNetwork()
  const filter = useSearchFilter()
  const classification = useClassification()
  const { data, info } = useInvestTable({ filter })

  const title = useMemo(() => {
    let result = `Sell ${classification.title.long}`
    if (info.total !== info.filtered) {
      result += ` (Filtered ${info.filtered}/${info.total})`
    }
    return result
  }, [info, classification])

  const copy = useMemo(() => {
    return window.location.href
    // make sure the link is refreshed after every update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info])

  return (
    <Structure>
      <Helmet.Invest />
      <ToolbarBase />
      {supported ? (
        <Section
          title={title}
          description='Sell European, fully collateralized options. Please note that (for certain options series) it may be possible to use aTokens to increase your earnings.'
          copy={copy}
        >
          <Content>
            <TableWrapper>
              <Table id='invest' data={data} />
            </TableWrapper>
          </Content>
        </Section>
      ) : (
        <Restricted.Invest />
      )}
    </Structure>
  )
}

export default Invest
