import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import BigNumber from 'bignumber.js'
import { Emoji, ExternalLink } from '@pods-finance/components'
import IconCalendar from '@material-ui/icons/CalendarTodayRounded'
import { ReactComponent as Decorator } from '../../../../../assets/decorators/oval_group_small.svg'
import { getGoogleCalendarEventURL } from '@pods-finance/utils'

import { pages } from '../../../../../constants'

dayjs.extend(utc)

const WrapperPartial = styled.div`
  display: block;
`

const Inner = styled(ExternalLink)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${props => props.theme.sizes.edge};
  width: 100%;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: calc(${props => props.theme.sizes.edge} * 1 / 2);
  box-shadow: ${props => props.theme.styles.boxShadowHover};
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.7);
  }
`

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 34px;
  width: 100%;
`

const Date = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${props => props.theme.sizes.edge};
  border-radius: 4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-left: calc(${props => props.theme.sizes.edge} * -1);
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
  & > p {
    margin: 0;
    color: ${props => props.theme.colors.whiteAbsolute};
    font-weight: 600;
    font-size: 10pt;
  }
`

const Divider = styled.div`
  height: 100%;
  flex: 1;
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  & > svg {
    color: ${props => props.theme.colors.whiteAbsolute};
    font-size: 10pt;
  }
  &:nth-of-type(2) {
    margin-left: 8px;
  }
`

const Title = styled.div`
  margin: ${props => props.theme.sizes.edge} 0;
  & > p {
    margin: 0;
    font-size: 20pt;
    font-weight: 700;
    color: ${props => props.theme.colors.middle};
    background: ${props => props.theme.gradients.primary};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

const Data = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`

const Elements = styled.div`
  line-height: 1.4;
  margin: 0;
  font-size: 10pt;
  font-weight: 400;
  color: ${props => props.theme.colors.whiteAbsolute};
  p {
    margin: 0;
  }
  b {
    font-weight: 600;
  }

  & > p {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Underlay = styled.div`
  position: absolute;
  z-index: 100;
  right: 48px;

  & > svg {
    height: 120px;
    width: 120px;
    opacity: 0.2;
    rect {
      stroke: ${props => props.theme.colors.white};
      stroke-width: 5;
    }
  }
`

const Wrapper = styled(WrapperPartial)`
  &[data-empty='true'] {
    display: flex;
    & > ${Inner} {
      cursor: default;
      min-height: calc(140px + ${props => props.theme.sizes.edge} * 2);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      grid-gap: 16px;
      padding: ${props => props.theme.sizes.edge};
      flex-direction: row;
      &:hover,
      &:active {
        background-color: rgba(0, 0, 0, 0.3);
      }
      p {
        text-align: center;
        margin: 0;
        color: ${props =>
          props.theme.isDark
            ? props.theme.colors.content
            : props.theme.colors.white};
        line-height: 1.5;
        font-size: 11pt;
        font-weight: 600;
      }
    }
    ${props => props.theme.animations.fadeInCss};
    animation-delay: 500ms;
  }

  &[data-expired='true'] {
    opacity: 0.4;
    & > ${Inner} {
      cursor: default;
      &:hover,
      &:active {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
  }
`

function ReminderEmpty () {
  return (
    <Wrapper data-empty='true'>
      <Inner as='div'>
        <Emoji label='Oops' symbol='✌️' />
        <p>
          Hi! You have no open positions. To fill your calendar with reminders
          buy, sell or provide liquidity for options.
        </p>
        <Underlay>
          <Decorator />
        </Underlay>
      </Inner>
    </Wrapper>
  )
}

function Reminder ({ date, start, pair, size, children, type }) {
  const isExpired = useMemo(() => {
    const s = dayjs(new BigNumber(start).multipliedBy(1000).toNumber()).utc()
    if (s.isBefore(dayjs())) return true
    return false
  }, [start])

  const eventURL = useMemo(() => {
    const s = dayjs(new BigNumber(start).multipliedBy(1000).toNumber())
      .utc()
      .format('YYYY-MM-DD-T-HH-mm-ss[Z]')
      .replace(/-/g, '')
    const e = dayjs(
      new BigNumber(start)
        .plus(new BigNumber(size))
        .multipliedBy(1000)
        .toNumber()
    )
      .utc()
      .format('YYYY-MM-DD-T-HH-mm-ss[Z]')
      .replace(/-/g, '')

    const result = getGoogleCalendarEventURL({
      description:
        "Don't forget about your options from https://app.pods.finance",
      start: s,
      end: e,
      location: 'https://app.pods.finance',
      title: `Pods | Action required for ${pair} (${type})`
    })
    return result
  }, [start, size, pair, type])

  const title = useMemo(() => {
    try {
      if (_.isNil(start)) return 'Exercise day'
      return dayjs(new BigNumber(start).multipliedBy(1000).toNumber()).format(
        'dddd, D'
      )
    } catch (e) {}
    return '-'
  }, [start])

  return (
    <Wrapper data-expired={isExpired}>
      <Inner title='Add to calendar' to={eventURL}>
        <Top>
          <Date>
            <p>{date}</p>
          </Date>
          <Divider />
          <Icon>
            {type === 'exercise' ? <pages.hedge.Icon /> : null}
            {type === 'invest' ? <pages.invest.Icon /> : null}
            {type === 'pool' ? <pages.pool.Icon /> : null}
          </Icon>
          <Icon>
            <IconCalendar />
          </Icon>
        </Top>
        <Title>
          <p>{title}</p>
        </Title>
        <Data>
          <Elements>{children}</Elements>
        </Data>
      </Inner>
    </Wrapper>
  )
}

Reminder.propTypes = {
  date: PropTypes.string.isRequired,
  day: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  elements: PropTypes.node,
  type: PropTypes.string.isRequired
}

export { ReminderEmpty }
export default Reminder
