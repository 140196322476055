import _ from 'lodash'
import { useMemo } from 'react'
import Theme from '@pods-finance/themes'
import { useHistory } from 'react-router-dom'
import { useModal } from '@pods-finance/contexts'
import { attributes } from '@pods-finance/globals'
import { modals } from '../../constants'
import { useAccount, useUserActivity } from '../../hooks'

export default function useActivityTable () {
  const activity = useUserActivity()
  const history = useHistory()
  const { setOpen } = useModal(modals.activityItem)
  const { isExpected, isConnected } = useAccount()
  const { actions, isFinished, isLoading, more, count } = useMemo(
    () => activity,
    [activity]
  )

  const footer = useMemo(
    () =>
      isFinished || (isLoading && !actions.length)
        ? null
        : isLoading
          ? 'more-loading'
          : 'more',
    [isFinished, isLoading, actions]
  )

  const columns = useMemo(
    () => [
      {
        title: 'Transaction',
        subtitle: 'Details',
        layout: attributes.layout.cell.TransactionHeader,
        weight: 4
      },
      {
        title: 'Amount',
        layout: attributes.layout.cell.TransactionAmount,
        weight: 4
      },

      {
        title: 'Date',
        layout: attributes.layout.cell.Text,
        weight: 2
      },
      {
        title: 'Type',
        layout: attributes.layout.cell.Text
      },
      {
        title: '',
        layout: attributes.layout.cell.TransactionActions
      }
    ],
    []
  )

  const instructions = useMemo(
    () => ({
      onRowClick: params => {
        const position = _.get(params, 'position')
        const action = _.get(actions, `[${position}]`)

        if (!_.isNil(action)) {
          setOpen(true, { action, history })
        }
      },
      onLoadMore: () => more()
    }),
    [setOpen, more, actions, history]
  )
  const rows = useMemo(
    () =>
      actions.map(action => {
        return {
          id: _.get(action, 'id'),
          cells: [
            {
              type: _.get(action, 'type'),
              title: _.get(action, 'preview.title'),
              value: _.get(action, 'preview.title'),
              classification: _.get(action, 'preview.classification'),

              tokens: [
                action.preview.underlying,
                action.preview.strike,
                action.preview.tokenB
              ],
              strikePrice: _.get(
                action,
                'option.strikePrice.humanized'
              ).toString(),
              expiration: _.get(action, 'preview.expiration')
            },
            {
              value: _.get(action, 'preview.amountTransacted'),
              subtitle: _.get(action, 'preview.amountTransactedInfo')
            },
            {
              value: _.get(action, 'preview.timestamp')
            },
            {
              value: _.get(action, 'option').isPut() ? 'Put' : 'Call'
            },

            {
              value: ''
            }
          ]
        }
      }),
    [actions]
  )

  return useMemo(
    () => ({
      data: {
        columns,
        rows,
        instructions,
        /** Cosmetics */
        isLoading: (isExpected && !isConnected) || (isLoading && !count),
        expected: 2,
        empty: 4,
        footer,
        theme: Theme.attributes.table.theme.activity
      }
    }),
    [
      columns,
      rows,
      instructions,
      isLoading,
      isExpected,
      isConnected,
      footer,
      count
    ]
  )
}
