import _ from 'lodash'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Emoji, TokenDisplay } from '@pods-finance/components'

import { macros, pages } from '../../../../../constants'
import { toNumeralPrice } from '@pods-finance/utils'
import { useOptionInfo, useOptionDynamics } from '../../../../../hooks'

import Item from './Item'

const WrapperPartial = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-radius: ${props => props.theme.sizes.edge};
  border: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.colors.white};
  padding: calc(${props => props.theme.sizes.edge} * 1.5);
  padding-bottom: 0;
`

const Header = styled.div`
  width: 100%;
  padding-bottom: ${props => props.theme.sizes.edge};
`

const Title = styled.p`
  margin: 0;
  font-size: 16pt;
  font-weight: 700;
  color: ${props => props.theme.colors.dark};
`

const Body = styled.div`
  width: 100%;
  & > *:last-child {
    border-bottom: none;
  }

  &[data-loading='true'] div[data-component='content'],
  div[data-component='content'][data-loading='true'] {
    position: relative;
    & > * {
      opacity: 0;
    }
    &:before {
      content: '';
      width: 140px;
      height: 100%;
      position: absolute;
      right: 0;
      z-index: 0;
      border-radius: 8px;
      background-image: ${props => props.theme.styles.backgroundGradientLoader};
    }
  }
`

const Wrapper = styled(WrapperPartial)`
  ${props => props.theme.medias.small} {
    padding: calc(${props => props.theme.sizes.edge} * 3 / 2)
      calc(${props => props.theme.sizes.edge} * 1);
    border-radius: 0;
  }
`

const Missing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 200px;
  font-size: 40pt;
`

function InfoMissing () {
  return (
    <Wrapper>
      <Header>
        <Title>Option Info</Title>
      </Header>
      <Body>
        <Missing>
          <Emoji symbol='🛸' label='UFO' />
        </Missing>
      </Body>
    </Wrapper>
  )
}

function Info ({ context }) {
  const {
    underlying,
    strike,
    option,
    market,
    durations,
    description,
    isLoading,
    isRestricted
  } = useOptionInfo()

  const dynamics = useOptionDynamics()

  const sellingPrice = useMemo(() => _.get(dynamics, 'sellingPrice'), [
    dynamics
  ])
  const buyingPrice = useMemo(() => _.get(dynamics, 'buyingPrice'), [dynamics])

  const [price, isLoadingDynamics] = useMemo(() => {
    if (_.isNil(buyingPrice) || _.isNil(sellingPrice) || dynamics.isLoading) {
      return [null, true]
    }

    const seller = context === pages.invest.title
    const source = seller ? sellingPrice : buyingPrice

    return [_.get(source, 'value.humanized'), dynamics.isLoading]
  }, [context, sellingPrice, buyingPrice, dynamics])

  const exercisePositioned = useMemo(() => {
    if (_.isNil(durations)) return [null, -1]

    if (durations.isExpired) {
      return [`ended ${_.get(durations, 'expirationToTodayFormatted')}`, 0]
    } else if (durations.isExercising) {
      return [`ends ${_.get(durations, 'expirationToTodayFormatted')}`, 1]
    }
    return [`starts ${_.get(durations, 'exerciseStartToTodayFormatted')}`, 2]
  }, [durations])

  if (isRestricted) return <InfoMissing />

  return (
    <Wrapper>
      <Header>
        <Title>Option Info</Title>
      </Header>
      <Body data-loading={isLoading}>
        <Item label='Type' title={description.name} help={description.info} />
        <Item label='Underlying asset'>
          <TokenDisplay token={underlying} isSelfPadded={false} isTarget />
        </Item>
        <Item label='Collateral asset'>
          <TokenDisplay token={strike} isSelfPadded={false} isTarget />
        </Item>
        <Item
          label='Expiration date'
          title={_.get(durations, 'expirationFormattedWithHour')}
          subtitle={_.get(durations, 'expirationFromNow')}
        />
        <Item
          label='Exercise type'
          title='European'
          subtitle='After expiration'
          help='European options are a type of instrument that allows the buyer to exercise their right only after expiration, during the exercise window.'
        />
        <Item
          label='Exercise window'
          title={_.get(durations, 'exerciseStartFormatted')}
          subtitle={`for ${_.get(durations, 'windowFormatted')}, ${
            exercisePositioned[0]
          }`}
          isHighlighted={exercisePositioned[1] === 1}
          help='During this time buyers will be able to exercise options of these series.'
        />
        <Item
          label='Strike price'
          title={toNumeralPrice(
            _.attempt(() => _.get(option, 'strikePrice.humanized').toString())
          )}
          subtitle={toNumeralPrice(market, true, true)}
          help='Strike price (top) vs. current market price for one unit of asset (bottom).'
        />
        <Item
          label='Market premium'
          title={toNumeralPrice(price, true, true)}
          isLoading={isLoadingDynamics}
        />

        <Item
          label='Default slippage'
          title={`${macros.DEFAULT_SLIPPAGE * 100}%`}
        />
      </Body>
    </Wrapper>
  )
}

Info.propTypes = {
  context: PropTypes.string
}

Info.defaultProps = {
  context: pages.hedge.title
}

export default Info
