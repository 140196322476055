import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 6px;
  width: auto;
  position: relative;
  background-color: ${props => props.theme.colors.white};
  padding: 6px 10px;
  border: 1px solid ${props => props.theme.colors.borderMedium};
  &:last-child {
    margin: 0;
  }

  p,
  span {
    text-align: left;
    margin: 0;
    font-size: 9pt;
    font-weight: 500;
    color: ${props => props.theme.colors.dark};
  }
  span {
    display: flex;
    align-items: center;
    font-size: 8pt;
    font-weight: 600;
    font-family: monospace;
    margin-left: 4px;
    &:empty {
      &:before {
        content: '';
        display: inline-flex;
        height: 6px;
        width: 6px;
        flex-shrink: 0;
        border-radius: 50%;
        background-color: ${props => props.theme.colors.borderMedium};
      }
    }
  }

  &[data-warning='true'] {
    border-color: ${props => props.theme.colors.yellow2};
    & > p,
    & > span {
      color: ${props => props.theme.colors.yellow2};
      &:before {
        display: none !important;
      }
    }
  }
`

export default function Pill ({ label, content, isWarning }) {
  return (
    <Wrapper data-component='pill' data-warning={isWarning}>
      <p>{label}</p>
      <span>{content}</span>
    </Wrapper>
  )
}

Pill.propTypes = {
  label: PropTypes.string,
  content: PropTypes.string,
  isWarning: PropTypes.bool
}

Pill.defaultProps = {
  label: '',
  content: '',
  isWarning: false
}
