import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useAccount, useGuardedTVL } from '../../../hooks'
import { Emoji } from '@pods-finance/components'

const WrapperPartial = styled.div``

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 ${props => props.theme.sizes.layoutEdge};
  ${props => props.theme.medias.medium} {
    padding: 0 ${props => props.theme.sizes.layoutEdgeMedium};
  }
`

const Box = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: calc(${props => props.theme.sizes.edge} * 1);
  background: ${props => props.theme.gradients.primary};
  border-radius: 8px;
`

const Value = styled.div`
  & > p {
    margin: 0;
    font-size: 10pt;
    font-weight: 600;
    line-height: 1.5;
    color: ${props => props.theme.colors.whiteAbsolute};
  }
`

const Wrapper = styled(WrapperPartial)`
  &:not([data-active='true']) {
    display: none;
  }
  &[data-design='2'] {
    & > ${Content} {
      padding: 0;
      & > ${Box} {
        border-radius: ${props => props.theme.sizes.edge};
      }
    }
  }
`

export default function GuardTVL ({ design }) {
  const guard = useGuardedTVL()
  const { isExpected, isConnected } = useAccount()
  const [isShown, banner] = useMemo(() => {
    if (!_.isNil(guard)) {
      if (design === 1) {
        const banner = _.get(guard, 'banner')
        return [!_.isNilOrEmptyString(banner), banner]
      } else {
        const cap = _.get(guard, 'cap')
        const banner = _.get(cap, 'banner')
        return [!_.isNilOrEmptyString(banner), banner]
      }
    }
    return [null, null]
  }, [guard, design])

  const isReady = useMemo(() => {
    if (isExpected && !isConnected) return false
    return true
  }, [isExpected, isConnected])

  return (
    <Wrapper data-active={isReady && isShown} data-design={design}>
      <Content>
        <Box>
          <Value>
            <p>
              <Emoji label='Party' symbol='🎉' /> {banner}
            </p>
          </Value>
        </Box>
      </Content>
    </Wrapper>
  )
}

GuardTVL.propTypes = {
  design: PropTypes.number
}

GuardTVL.defaultProps = {
  design: 1
}
