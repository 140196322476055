/**
 *
 * RULES
 *
 * - if the network top-level banner is empty/null, it won't show
 * - if the option low-level banner is empty/null, it won't show
 *
 */

const _bannerFilled = (item = 'Mainnet') =>
  `Thank you! We've reached our ${item} cap. No more new liquidity will be accepted for now. You can still buy/resell options.`

export const GUARDED_LAUNCH = {
  1: {
    // mainnet
    banner: _bannerFilled('Mainnet'), // if empty, won't show
    caps: {
      '0x049dfA51Cdc5c68fcb57dF1B43a03B4494623159': {
        disabled: true,
        supply: '$1.465.000',
        stable: '$170.000',
        banner: _bannerFilled('Mainnet') // if empty, won't show
      }
    }
  },
  137: {
    // matic
    banner:
      'We have a new ETH:aUSDC option available! Go check it out! The cap for the other options is already full.',
    caps: {
      '0x12dce930e6c2997bb7c134813c353c494a1182dc': {
        disabled: true,
        supply: '$500.000',
        stable: '$65.000',
        banner: _bannerFilled('MATIC:USDC') // if empty, won't show
      },
      '0xA53cD586840207890E44289EE7d31ccfbe58B42a': {
        disabled: true,
        supply: '$500.000',
        stable: '$65.000',
        banner: _bannerFilled('SUSHI:aUSDC') // if empty, won't show
      },
      '0x04aa280Bd351ffA83286c8cE1263890393f2B31A': {
        disabled: false,
        supply: '$1.500.000',
        stable: '$75.000',
        banner: null // if empty, won't show
      }
    }
  },
  42: {
    // kovan
    banner: null, // _bannerFilled('Kovan'), // if empty, won't show
    caps: {
      '0xa0eac153764e91f60e62e63bf00ae7580b889062': {
        disabled: false,
        supply: 'Infinity',
        stable: 'Infinity',
        banner: null // _bannerFilled('Matic')  // if empty, won't show
      }
    }
  }
}
