import _ from 'lodash'
import React, { useMemo, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ContentBox, ContentSection, ContentSummary } from '../../../Elements'
import {
  Label,
  Input,
  Step,
  Container as FormPartial
} from '@pods-finance/components'

import { SpecialButton, Incentive } from '../../../../../common'

import reducers from '../../../../../../reducers'
import machines from '../../../../../../machines'
import { pages, tabs } from '../../../../../../constants'

import {
  useOptionInfo,
  useOptionDynamics,
  useTransactionSetup,
  useFormValidator,
  useNetworkId
} from '../../../../../../hooks'

import logic from './logic'

const Wrapper = styled.div`
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 3 / 2);
    &:last-child {
      margin-bottom: 0;
    }
  }
`
const Form = styled(FormPartial)`
  max-width: 538px;
  div[data-step='actions'] {
    max-width: 100%;
    & > div:first-child {
      min-width: 226px;
    }
  }
`

const Info = styled.p`
  white-space: nowrap;
  min-width: 60px;
  ${props => props.theme.medias.medium} {
    white-space: normal;
    font-size: 9pt;
  }
`

const Header = styled.div`
  width: 100%;
  padding: 0 calc(${props => props.theme.sizes.edge} * 1);
`

const Title = styled.p`
  font-size: 12pt;
  font-weight: 700;
  color: ${props => props.theme.colors.middle};
  margin: 0;
`

function Remove () {
  /**
   * --------------------------------------
   * Required data and utilities
   * --------------------------------------
   */
  const machine = machines.remove.useMachine()
  const setup = useTransactionSetup()
  const networkId = useNetworkId()
  const { elements, state, dispatch } = reducers.remove.useReducer()
  const dynamics = useOptionDynamics()

  const {
    option,
    underlying,
    strike,
    durations,
    isLoading: isOptionLoading
  } = useOptionInfo()

  const [userPositionTokenA, userPositionTokenB] = useMemo(
    () => [
      _.get(dynamics, 'userPositions.0.humanized'),
      _.get(dynamics, 'userPositions.1.humanized')
    ],
    [dynamics]
  )

  const isLoading = useMemo(() => isOptionLoading || dynamics.isLoading, [
    isOptionLoading,
    dynamics.isLoading
  ])

  const { isValid: isFormValid, isAllowed: isFormAllowed } = useFormValidator({
    state,
    machine
  })

  const isWithoutPosition = useMemo(
    () =>
      (_.isNil(userPositionTokenA) && _.isNil(userPositionTokenB)) ||
      (userPositionTokenA.isZero() && userPositionTokenB.isZero()),
    [userPositionTokenA, userPositionTokenB]
  )

  /**
   * --------------------------------------
   *  Methods and state updaters
   * --------------------------------------
   */

  const onChangeAmount = useCallback(
    (shares, except = false) =>
      logic.onChangeAmount({
        amount: shares,
        durations,
        dispatch,
        elements,
        option,
        except,
        userPositionTokenA,
        userPositionTokenB
      }),
    [
      dispatch,
      elements,
      option,
      durations,
      userPositionTokenA,
      userPositionTokenB
    ]
  )

  const onTransact = useCallback(
    () => logic.onTransact({ machine, state, option, setup }),
    [machine, state, option, setup]
  )

  useEffect(() => {
    if (!isLoading) {
      logic.onInitialize({
        elements,
        dispatch,
        underlying,
        strike,
        durations
      })
    }
  }, [elements, dispatch, isLoading, underlying, strike, durations])

  useEffect(() => {
    if (!isLoading && !isWithoutPosition) {
      if (
        _.get(durations, 'isExpired') &&
        state.shares.value === 100 &&
        state.shares.isDisabled
      ) {
        onChangeAmount(100, true)
      }
    }
  }, [isLoading, isWithoutPosition, durations, state, onChangeAmount])

  return (
    <ContentBox
      hash={[tabs.pool.remove, tabs.pool.unmint]}
      isLoading={isLoading}
    >
      <Wrapper>
        <Header>
          <Title>Exit a liquidity position</Title>
        </Header>

        <ContentSection
          title='Section 1: Remove liquidity'
          isContained
          isDisabled={
            [machine.states.validate, machine.states.process].includes(
              machine.current.value
            ) || isWithoutPosition
          }
        >
          <Form>
            <Step>
              <Label>Step 1.1: Remove shares (options and tokens)</Label>
              <Input.Slider
                {...state.shares}
                min={0}
                step={1}
                placeholder='How many shares?'
                onChange={e => onChangeAmount(_.get(e, 'target.value'))}
              />
              <Step>
                <Info style={{ whiteSpace: 'nowrap' }}>For</Info>
                <Input.Amount
                  {...state.tokenA}
                  placeholder='0'
                  networkId={networkId}
                  value={_.get(state, 'tokenA.exposure')}
                  isViewOnly
                />
              </Step>
              <Step>
                <Info style={{ whiteSpace: 'nowrap' }}>And</Info>
                <Input.Amount
                  {...state.tokenB}
                  placeholder='0'
                  networkId={networkId}
                  value={_.get(state, 'tokenB.exposure')}
                  isViewOnly
                />
              </Step>
            </Step>
            <ContentSummary
              index='1.2:'
              context={tabs.pool.remove}
              data={{
                state,
                option,
                userPositionTokenA,
                userPositionTokenB,
                isAdvanced: true
              }}
              transact={
                <SpecialButton.Transact
                  title='Remove Liquidity'
                  isDisabled={!isFormValid}
                  isAllowed={isFormAllowed}
                  isLoading={[
                    machine.states.validate,
                    machine.states.process
                  ].includes(machine.current.value)}
                  onClick={onTransact}
                />
              }
            />
          </Form>
        </ContentSection>
        {_.get(durations, 'isExpired') ? (
          <ContentSection title='Note about redeeming your assets'>
            <Incentive symbol='🤖' isSelfPadded={false}>
              <p>
                After expiration, you will be removing stablecoins and option
                tokens. The assets locked behind the latter will be handled
                automatically. Some of these options may have been exercised,
                while some remain unsold. If you provided liquidity, head over
                to the{' '}
                <Link
                  to={pages.transactionInvest.builder(
                    _.get(option, 'address'),
                    tabs.invest.withdraw
                  )}
                >
                  withdraw
                </Link>{' '}
                tab to redeem your assets (underlying if any and the
                automatically unlocked collateral).
              </p>
            </Incentive>
          </ContentSection>
        ) : null}
      </Wrapper>
    </ContentBox>
  )
}

export default Remove
