import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { Section, Position } from '../../../common'
import { PositionLoader, PositionEmpty } from '../../../common/Position'
import { useClassification, usePositionsValues } from '../../../../hooks'

const Wrapper = styled.div`
  min-height: 40vh;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 ${props => props.theme.sizes.layoutEdge};
  & > * {
    margin-bottom: ${props => props.theme.sizes.edge};
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${props => props.theme.medias.medium} {
    padding: 0 ${props => props.theme.sizes.layoutEdgeMedium};
  }
`

function Positions () {
  const { positions: list, isLoading } = usePositionsValues()
  const { title } = useClassification()
  return (
    <Wrapper>
      <Section
        title={`Your Positions (${title.long})`}
        description='Find option series you’ve engaged with. For historical positions check the Activity page.'
      >
        <Content>
          {isLoading ? <PositionLoader /> : null}
          {!isLoading && !list.length ? <PositionEmpty /> : null}
          {!isLoading && list.length
            ? list.map(item => <Position key={_.get(item, 'id')} item={item} />)
            : null}
        </Content>
      </Section>
    </Wrapper>
  )
}

export default Positions
