import React from 'react'
import styled from 'styled-components'
import Section from '../../../common/Section'
import RestrictedNetwork from '../RestrictedNetwork'
import RestrictedConnection from '../RestrictedConnection'

const Content = styled.div`
  width: 100%;
`

function Activity ({ isConnected = false }) {
  return (
    <Section
      title='Transactions / Positions'
      description='The latest transactions &amp; positions linked to your wallet’s activity on Pods.'
    >
      <Content>
        {isConnected ? <RestrictedNetwork /> : <RestrictedConnection />}
      </Content>
    </Section>
  )
}

export default Activity
