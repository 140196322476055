import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useToasts } from 'react-toast-notifications'

import IconBuy from '@material-ui/icons/SecurityRounded'
import IconExercise from '@material-ui/icons/FlashOnRounded'
import IconOverview from '@material-ui/icons/UpdateRounded'
import Helmet from '../../../Helmet'
import * as Tabs from '../../Tabs/Hedge'

import { tabs } from '../../../../../constants'
import {
  TabBar,
  TabItem,
  SupportLoader,
  SupportMissing,
  SupportConnect
} from '../../Elements'

import { useAccount, useWalletModal, useOptionInfo } from '../../../../../hooks'

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-radius: ${props => props.theme.sizes.edge};
  border: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.colors.white};
  padding-bottom: ${props => props.theme.sizes.edge};
  padding-top: calc(${props => props.theme.sizes.edge} * 1 / 2);

  ${props => props.theme.medias.medium} {
    padding-bottom: 0;
    padding-top: 2px;
    border-radius: 0;
  }
`

const Content = styled.div`
  width: 100%;
  min-height: 200px;
`

function Hedge () {
  const { addToast, removeAllToasts } = useToasts()
  const { durations, option, isLoading, isRestricted } = useOptionInfo()
  const { isExpired, isExercising } = useMemo(() => durations || {}, [
    durations
  ])

  const { isConnected } = useAccount()
  const { connect } = useWalletModal()

  const isReady = useMemo(() => {
    if (isLoading) return false
    if (_.isNil(option)) return false
    if (_.isNil(durations)) return false
    const { isExpired, isExercising } = durations
    if (_.isNil(isExpired) || _.isNil(isExercising)) return false

    return true
  }, [durations, option, isLoading])

  const [locked, fallback] = useMemo(() => {
    if (isExpired) {
      return [[tabs.hedge.buy, tabs.hedge.exercise], tabs.hedge.overview]
    }
    if (isExercising) {
      return [[tabs.hedge.buy, tabs.hedge.overview], tabs.hedge.exercise]
    }
    return [[tabs.hedge.exercise, tabs.hedge.overview], tabs.hedge.buy]
  }, [isExercising, isExpired])

  return (
    <Wrapper>
      <Helmet.TransactionHedge data={{ option, durations }} />
      <TabBar
        fallback={fallback}
        supported={Object.values(tabs.hedge)}
        locked={locked}
        isLoading={!isReady}
      >
        <TabItem
          Icon={IconBuy}
          title='Buy'
          hash={tabs.hedge.buy}
          isLocked={locked.includes(tabs.hedge.buy)}
          onLockedClick={() => {
            removeAllToasts()
            addToast('Buying is available only before the exercise date.', {
              appearance: 'warning',
              autoDismiss: true,
              autoDismissTimeout: 5000
            })
          }}
        />
        <TabItem
          Icon={IconExercise}
          title='Exercise'
          subtitle='After expiration'
          hash={tabs.hedge.exercise}
          isLocked={locked.includes(tabs.hedge.exercise)}
          onLockedClick={() => {
            removeAllToasts()
            addToast(
              'Exercising is available only in the last 24h before the expiration date.',
              {
                appearance: 'warning',
                autoDismiss: true,
                autoDismissTimeout: 5000
              }
            )
          }}
        />

        {isExpired && (
          <TabItem
            Icon={IconOverview}
            title='Overview'
            hash={tabs.hedge.overview}
          />
        )}
      </TabBar>
      <Content>
        {isRestricted ? (
          <SupportMissing />
        ) : (
          <>
            {!locked.includes(tabs.hedge.buy) && <Tabs.Buy />}
            {!locked.includes(tabs.hedge.exercise) && <Tabs.Exercise />}
            {!locked.includes(tabs.hedge.overview) && <Tabs.Overview />}
          </>
        )}
      </Content>
      <SupportLoader isActive={!isReady && !isRestricted} />
      <SupportConnect isActive={isConnected} onClick={connect} />
    </Wrapper>
  )
}

export default Hedge
