import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { useModal } from '@pods-finance/contexts'
import IconHistory from '@material-ui/icons/HistoryRounded'
import { useVersion } from '../../../hooks'
import { modals } from '../../../constants'

const WrapperPartial = styled.div`
  position: relative;
`

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: auto;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: ${props => rgba(props.theme.colors.orange, 0.1)};
  border: 1px solid ${props => rgba(props.theme.colors.orange, 0.9)};
  color: ${props => rgba(props.theme.colors.orange, 0.9)};
  cursor: pointer;

  & > svg {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13pt;
  }

  &:hover,
  &:active {
    background-color: ${props => rgba(props.theme.colors.orange, 0.15)};
  }
`

const Wrapper = styled(WrapperPartial)`
  &:not([data-active='true']) {
    display: none;
  }
  ${props => props.theme.medias.medium} {
    display: none;
  }
`

function NoticeLag () {
  const { version } = useVersion()
  const { setOpen } = useModal(modals.lag)

  return (
    <Wrapper data-active={!_.isNil(version) && version !== 0}>
      <Box onClick={() => setOpen(true)}>
        <IconHistory />
      </Box>
    </Wrapper>
  )
}

export default NoticeLag
