import _ from 'lodash'
import BigNumber from 'bignumber.js'
import {
  guards,
  toSignificantInput,
  isBalanceInsignificant
} from '@pods-finance/utils'
import { analytics } from '../../../../../../vendors'

function onInitialize ({
  elements,
  dispatch,
  option,
  underlying,
  strike,
  collateral,
  balanceCollateral
}) {
  dispatch([], 'RESET', [elements.allowance])
  dispatch([elements.premiumToken, { value: _.get(strike, 'symbol') }])
  dispatch([
    elements.collateral,
    {
      token: _.get(collateral, 'symbol'),
      max: !isBalanceInsignificant(balanceCollateral)
        ? balanceCollateral.toString()
        : null
    }
  ])

  if (option.isPut()) {
    dispatch([elements.underlying, { token: _.get(underlying, 'symbol') }])
    dispatch([
      elements.strike,
      {
        token: _.get(strike, 'symbol'),
        max: !isBalanceInsignificant(balanceCollateral)
          ? balanceCollateral.toString()
          : null
      }
    ])
  } else if (option.isCall()) {
    dispatch([elements.strike, { token: _.get(strike, 'symbol') }])
    dispatch([
      elements.underlying,
      {
        token: _.get(underlying, 'symbol'),
        max: !isBalanceInsignificant(balanceCollateral)
          ? balanceCollateral.toString()
          : null
      }
    ])
  }
}

const onPremiumRecompute = _.debounce(
  ({ anchor, elements, dispatch, option, safeComputePremium }) => {
    ;(async () => {
      dispatch([elements.premium, { value: null, isLoading: true }])
      if (anchor === null) return

      const current = anchor // Forcing checks for requests filled out-of-order

      const computed = await safeComputePremium({
        option,
        amount: new BigNumber(anchor),
        external: true
      })

      const { fees, value: _value } = computed || {}
      const value = _value ? _value.toString() : null

      if (current !== anchor) return

      dispatch([
        elements.premium,
        {
          value: toSignificantInput(value || 0, BigNumber.ROUND_DOWN),
          isLoading: false,
          warning: !_.isNilOrEmptyString(value)
            ? guards.isPremiumValid({
              value,
              context: 'liquidity'
            })
            : null
        }
      ])
      dispatch([elements.fees, { value: fees }])
    })()
  },
  300
)

function onChangeAmount ({
  amount,
  dispatch,
  elements,
  option,
  balanceCollateral,
  safeComputePremium
}) {
  dispatch([
    elements.collateral,
    {
      value: String(amount).trim(),
      warning: !_.isNilOrEmptyString(amount)
        ? guards.isAmountValid({
          value: amount,
          max: balanceCollateral
        })
        : null
    }
  ])

  let options = new BigNumber(0)

  if (option.isPut()) {
    options = new BigNumber(amount || 0).dividedBy(option.strikePrice.humanized)

    const reflected = toSignificantInput(
      options.toString() || 0,
      BigNumber.ROUND_DOWN
    )

    dispatch([elements.underlying, { value: reflected }])
    dispatch([elements.strike, { value: String(amount).trim() }])
  } else if (option.isCall()) {
    options = new BigNumber(amount || 0)

    const reflected = toSignificantInput(
      options.times(option.strikePrice.humanized).toString() || 0,
      BigNumber.ROUND_DOWN
    )

    dispatch([elements.strike, { value: reflected }])
    dispatch([elements.underlying, { value: String(amount).trim() }])
  }

  onPremiumRecompute({
    anchor: options,
    elements,
    dispatch,
    option,
    safeComputePremium
  })
}

function onTransact ({ machine, state, option, setup, signer }) {
  analytics.track(e => e.transactionSellTrigger)
  machine.send(machine.events.save, {
    payload: {
      state,
      option,
      setup,
      signer
    }
  })
}

export default {
  onInitialize,
  onChangeAmount,
  onTransact
}
