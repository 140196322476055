import React from 'react'
import { Card, Title, Description, Content } from '../atoms'

import { Input } from '@pods-finance/components'
import { useNetworkId } from '../../../../../../hooks'

function Collateral ({ reducer }) {
  const networkId = useNetworkId()
  return (
    <Card>
      <Title>Collateral</Title>
      <Description>
        Select the type of collateral you wish to potentially exchange.
      </Description>
      <Content>
        <Input.TokenList
          {...reducer.state.collateral}
          maxDisplay={1}
          placeholder='e.g. AUSDC, DAI, ...'
          networkId={networkId}
          onChange={value => {
            reducer.dispatch([
              reducer.elements.collateral,
              {
                value
              }
            ])
          }}
        />
      </Content>
    </Card>
  )
}

export default Collateral
