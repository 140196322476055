import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ContentBox, ContentSection } from '../../../Elements'
import { Explainer, Countdown, Incentive } from '../../../../../common'
import { tabs, types, pages } from '../../../../../../constants'
import { useOwnBalance, useOptionInfo } from '../../../../../../hooks'

const Wrapper = styled.div`
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 3 / 2);
    &:last-child {
      margin-bottom: 0;
    }
  }
`

function Overview () {
  const { option, isLoading: isOptionLoading } = useOptionInfo()

  const { isLoading: isBalanceOptionsLoading } = useOwnBalance({
    address: _.get(option, 'address'),
    decimals: _.get(option, 'decimals')
  })

  const isLoading = useMemo(() => isOptionLoading || isBalanceOptionsLoading, [
    isOptionLoading,
    isBalanceOptionsLoading
  ])

  return (
    <>
      <ContentBox hash={tabs.hedge.overview} isLoading={isLoading}>
        <Wrapper>
          <ContentSection title='Your position'>
            <Countdown action={types.action.hedge} />
          </ContentSection>
          <ContentSection title='Status'>
            <Explainer action={types.action.hedge} />
          </ContentSection>
          <Incentive>
            <p>
              If you sold options or provided liquidity, check the Seller's{' '}
              <Link
                to={pages.transactionInvest.builder(
                  _.get(option, 'address'),
                  tabs.invest.withdraw
                )}
              >
                withdraw
              </Link>{' '}
              tab and claim your earnings.
            </p>
          </Incentive>
        </Wrapper>
      </ContentBox>
    </>
  )
}

export default Overview
