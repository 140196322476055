import React from 'react'
import {
  HashRouter,
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import Theme from '@pods-finance/themes'
import { HelmetProvider } from 'react-helmet-async'
import { ToastProvider } from 'react-toast-notifications'
import { ApolloProvider } from 'react-apollo'
import { Toast } from '@pods-finance/components'
import { ENVIRONMENT } from '@pods-finance/globals'
import {
  PriceProvider,
  ModalProvider,
  TableProvider
} from '@pods-finance/contexts'

import { pages, features, feeds } from '../constants'
import { useDarkMode } from '../hooks'
import { client } from '../apollo/client'

import Dashboard from './Dashboard'
import ActivityPositions from './Activity/Positions'
import ActivityTransactions from './Activity/Transactions'
import Hedge from './Hedge'
import Invest from './Invest'
import Utils from './Utils'
import Restricted from './Restricted'
import Pool from './Pool'
import TransactionInvest from './Transaction/TransactionInvest'
import TransactionHedge from './Transaction/TransactionHedge'
import TransactionPool from './Transaction/TransactionPool'

import Web3Provider from '../contexts/Web3'
import DataStaticProvider from '../contexts/DataStatic'
import DataDynamicProvider from '../contexts/DataDynamic'
import UIProvider from '../contexts/UI'

import Watcher from './Watcher'

function Router ({ children }) {
  if (features.ipfs) return <HashRouter>{children}</HashRouter>
  return <BrowserRouter>{children}</BrowserRouter>
}

function Wrapper ({ children }) {
  return (
    <ApolloProvider client={client}>
      <Web3Provider>
        <ModalProvider>
          <UIProvider>
            <PriceProvider feeds={feeds}>
              <DataStaticProvider>
                <DataDynamicProvider>
                  <TableProvider>{children}</TableProvider>
                </DataDynamicProvider>
              </DataStaticProvider>
            </PriceProvider>
          </UIProvider>
        </ModalProvider>
      </Web3Provider>
    </ApolloProvider>
  )
}

function Manager ({ children }) {
  const { isDark } = useDarkMode()

  return (
    <Theme.Provider isDark={isDark}>
      <Theme.PlatformGlobalStyle isDark={isDark} />
      <HelmetProvider>
        <ToastProvider
          components={{ Toast: Toast.Element, ToastContainer: Toast.Container }}
        >
          {children}
        </ToastProvider>
      </HelmetProvider>
    </Theme.Provider>
  )
}

function Routes () {
  return (
    <Switch>
      <Route path={pages.hedge.route} component={Hedge} exact />
      <Route path={pages.invest.route} component={Invest} exact />
      <Route path={pages.pool.route} component={Pool} exact />

      <Route path={pages.utils.route} component={Utils} exact />
      <Route path={pages.restricted.route} component={Restricted} exact />

      <Route
        path={pages.transactionHedge.route}
        component={TransactionHedge}
        exact
      />

      <Route
        path={pages.transactionInvest.route}
        component={TransactionInvest}
        exact
      />

      <Route
        path={pages.transactionPool.route}
        component={TransactionPool}
        exact
      />

      <Route path={pages.dashboard.route} component={Dashboard} exact />
      <Route
        path={pages.activityTransactions.route}
        component={ActivityTransactions}
        exact
      />
      <Route
        path={pages.activityPositions.route}
        component={ActivityPositions}
        exact
      />
      <Redirect to={pages.hedge.route} />
    </Switch>
  )
}

/**
 * The Watcher component will activate special page-level events
 */

function App () {
  console.log(
    `%cWelcome to the Pods dashboard! #${String(ENVIRONMENT.current).substr(
      0,
      1
    )}`,
    'color: #C41857'
  )
  return (
    <Router>
      <Wrapper>
        <Manager>
          <Watcher />
          <Routes />
        </Manager>
      </Wrapper>
    </Router>
  )
}

export default App
