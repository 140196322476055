import React from 'react'
import styled from 'styled-components'

import { Modal as Base } from '@pods-finance/components'

import { modals } from '../../../../constants'
import AssetBalloons from '../../../../assets/images/balloons.png'

const Modal = styled(Base)`
  & > div[data-component='card'] {
    max-width: 500px;
    border-radius: ${props => props.theme.sizes.edge};
  }
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: calc(${props => props.theme.sizes.edge} * 1);
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1);
  padding-top: calc(${props => props.theme.sizes.edge} * 2);
  padding-bottom: calc(${props => props.theme.sizes.edge} * 1);

  border-top: 1px solid ${props => props.theme.colors.grayBlueLight};
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1);
  &:last-child {
    margin-bottom: 0;
  }
`

const Title = styled.div`
  width: 100%;
  text-align: center;
  & > p {
    color: ${props => props.theme.colors.dark};
    font-size: 13pt;
    font-weight: 600;
    line-height: 1.5;
    margin: 0 0 10px 0;
  }
`

const Description = styled.div`
  width: 100%;
  text-align: center;
`

const Paragraph = styled.p`
  color: ${props => props.theme.colors.dark};
  font-size: 11pt;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  text-align: center;

  a {
    color: ${props => props.theme.colors.middle};
    font-weight: 600;
  }
`

const Balloons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  & > img {
    height: 280px;
    width: auto;
    object-fit: contain;
  }
`

function GuardTVL () {
  const id = modals.guardTVL

  return (
    <Modal id={id} title=''>
      <Content>
        <Balloons>
          <img src={AssetBalloons} alt='Celebrate' />
        </Balloons>
        <Info>
          <Section>
            <Title>
              <p>We've reached our cap!</p>
            </Title>
            <Description>
              <Paragraph>
                We've reached out cap for collateral locked in options and
                pools! Thank you for your participation. While no new liquidity
                will be accepted for now, you can still buy/resell options,
                manage positions or check our liquidity campaigns on other
                networks.
              </Paragraph>
            </Description>
          </Section>
        </Info>
      </Content>
    </Modal>
  )
}

export default GuardTVL
