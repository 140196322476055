import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import Theme from '@pods-finance/themes'
import Chart from './Chart'
import { useMarketPrices, useOptionInfo } from '../../../../../hooks'

const Wrapper = styled.div`
  width: 100%;

  ${props => props.theme.medias.small} {
    padding: 0 ${props => props.theme.sizes.layoutEdgeMedium};
  }
`

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${props => props.theme.sizes.edge};
`

function Charts () {
  const { historical, spot } = useMarketPrices()
  const { underlying, strike, isRestricted } = useOptionInfo()

  const uValues = useMemo(() => {
    if (_.isNil(underlying) || _.isNil(spot)) return [null, null]
    return [
      _.get(spot, _.get(underlying, 'symbol').toUpperCase()),
      _.get(historical, _.get(underlying, 'symbol').toUpperCase())
    ]
  }, [underlying, spot, historical])

  return (
    <Wrapper>
      <Grid>
        <Chart
          token={underlying}
          color={Theme.colors.green}
          spot={uValues[0]}
          dataset={uValues[1] || []}
          isRestricted={isRestricted}
        />
        <Chart
          token={strike}
          color={Theme.colors.yellow2}
          spot={1}
          dataset={[1, 1]}
          isConstant
          isRestricted={isRestricted}
        />
      </Grid>
    </Wrapper>
  )
}

export default Charts
