import React from 'react'
import styled from 'styled-components'

import { Placeholder } from '@pods-finance/components'

const Wrapper = styled.div`
  width: 100%;
  padding: 0 ${props => props.theme.sizes.layoutEdge};
`

const Box = styled.div`
  border-radius: calc(${props => props.theme.sizes.edge} * 1);
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.border};
  width: 100%;
  overflow: hidden;

  ${props => props.theme.medias.medium} {
    border-radius: calc(${props => props.theme.sizes.edge} * 1 / 2);
  }
`

const Body = styled.div`
  width: 100%;
  padding: ${props => props.theme.sizes.edge};
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 1 / 3);
    &:last-child {
      margin-bottom: 0;
    }
  }

  min-height: calc(
    (${props => props.expected || 0}) * 66px +
      (${props => props.expected || 1} - 1) * ${props => props.theme.sizes.edge} *
      1 / 3
  );
`

function RestrictedNetwork ({ className }) {
  return (
    <Wrapper className={className}>
      <Box data-component='box'>
        <Body>
          <Placeholder
            vairant={0}
            symbol='🧭'
            content="We're bringing options to this network soon. In the meantime, please try to connect to either Mainnet, Matic or Kovan (for tests)."
          />
        </Body>
      </Box>
    </Wrapper>
  )
}

export default RestrictedNetwork
